import { RSAA } from 'redux-api-middleware'
import { baseAPI } from '../../config'
import * as types from '../constants'
import queryString from 'querystring'

export function loadUsers({ query }) {
  const token = localStorage.getItem('token')

  if (query?.company) {
    const obj = JSON.parse(query.company)
    if (obj) query.company_id = obj.value
  }

  if (query?.creator) {
    const obj = JSON.parse(query.creator)
    if (obj) query.creator_id = obj.value
  }

  if (query?.substitute) {
    const obj = JSON.parse(query.substitute)
    if (obj) query.substitute_id = obj.value
  }

  if (query?.partner) {
    const obj = JSON.parse(query.partner)
    if (obj) query.partner_id = obj.value
  }
  if (!query.page) query.page = 1

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/users?${queryString.stringify(query)}`,
      method: 'GET',
      types: [
        types.LOAD_USERS_REQUEST,
        types.LOAD_USERS_SUCCESS,
        types.LOAD_USERS_ERROR,
      ],
    },
  }
}

export function loadUser({ id }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/users/${id}`,
      method: 'GET',
      types: [
        types.LOAD_USER_REQUEST,
        types.LOAD_USER_SUCCESS,
        types.LOAD_USER_ERROR,
      ],
    },
  }
}

export function updateUser({ id, user }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      endpoint: `${baseAPI}/users/${id}`,
      body: JSON.stringify(user),
      method: 'PUT',
      types: [
        types.UPDATE_USER_REQUEST,
        types.UPDATE_USER_SUCCESS,
        types.UPDATE_USER_ERROR,
      ],
    },
  }
}

export function deleteUser(user_id) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/users/${user_id}`,
      method: 'DELETE',
      types: [
        types.DELETE_USER_REQUEST,
        types.DELETE_USER_SUCCESS,
        types.DELETE_USER_ERROR,
      ],
    },
  }
}

export function createClient({ user }) {
  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
      endpoint: `${baseAPI}/signup`,
      method: 'POST',
      types: [
        types.CREATE_CLIENT_REQUEST,
        types.CREATE_CLIENT_SUCCESS,
        types.CREATE_CLIENT_ERROR,
      ],
    },
  }
}

export function createClientIDP({ code, code_verifier, redirect_uri, query }) {
  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ code, code_verifier, redirect_uri }),
      endpoint: `${baseAPI}/signup-idp?${query}`,
      method: 'POST',
      types: [
        types.CREATE_CLIENT_REQUEST,
        types.CREATE_CLIENT_SUCCESS,
        types.CREATE_CLIENT_ERROR,
      ],
    },
  }
}

export function createClientForm({ user }) {
  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
      endpoint: `${baseAPI}/signup`,
      method: 'POST',
      types: [
        types.CREATE_CLIENT_FORM_REQUEST,
        types.CREATE_CLIENT_FORM_SUCCESS,
        types.CREATE_CLIENT_FORM_ERROR,
      ],
    },
  }
}

export function loadSubstitutes({ party_id, creator_id }) {
  const headers = {
    'Content-Type': 'application/json',
  }

  const token = localStorage.getItem('token')

  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }

  return {
    [RSAA]: {
      headers,
      endpoint: `${baseAPI}/substitutes/${party_id}/${creator_id}`,
      method: 'GET',
      types: [
        types.LOAD_SUBTITUTES_REQUEST,
        types.LOAD_SUBTITUTES_SUCCESS,
        types.LOAD_SUBTITUTES_ERROR,
      ],
    },
  }
}

export function createUser({ user }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(user),
      endpoint: `${baseAPI}/users`,
      method: 'POST',
      types: [
        types.CREATE_USER_REQUEST,
        types.CREATE_USER_SUCCESS,
        types.CREATE_USER_ERROR,
      ],
    },
  }
}

export function addExcludedSubstitute({ user_id, substitute_id }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_id, substitute_id }),
      endpoint: `${baseAPI}/users/users-exclude-substitute/`,
      method: 'PUT',
      types: [
        types.ADD_SUBSTITUTE_EXCLUDED_REQUEST,
        types.ADD_SUBSTITUTE_EXCLUDED_SUCCESS,
        types.ADD_SUBSTITUTE_EXCLUDED_ERROR,
      ],
    },
  }
}

export function removeExcludedSubstitute({ user_id, substitute_id }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_id, substitute_id }),
      endpoint: `${baseAPI}/users/users-remove-excluded-substitute/`,
      method: 'PUT',
      types: [
        types.REMOVE_SUBSTITUTE_EXCLUDED_REQUEST,
        types.REMOVE_SUBSTITUTE_EXCLUDED_SUCCESS,
        types.REMOVE_SUBSTITUTE_EXCLUDED_ERROR,
      ],
    },
  }
}

export function createSubstitutePool({ user_id, pool }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_id, pool }),
      endpoint: `${baseAPI}/pool-auth-substitutes/`,
      method: 'POST',
      types: [
        types.ADD_AUTH_POOL_REQUEST,
        types.ADD_AUTH_POOL_SUCCESS,
        types.ADD_AUTH_POOL_ERROR,
      ],
    },
  }
}

export function editSubstitutePool({ user_id, pool_id, pool }) {
  const token = localStorage.getItem('token')
  return {
    [RSAA]: {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_id, pool_id, pool }),
      endpoint: `${baseAPI}/pool-auth-substitutes/`,
      method: 'PUT',
      types: [
        types.EDIT_AUTH_POOL_REQUEST,
        types.EDIT_AUTH_POOL_SUCCESS,
        types.EDIT_AUTH_POOL_ERROR,
      ],
    },
  }
}

export function setLinkedPartners({ user_id, linked_partners }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(linked_partners),
      endpoint: `${baseAPI}/linked-partners/${user_id}`,
      method: 'POST',
      types: [
        types.SET_LINKED_PARTNERS_REQUEST,
        types.SET_LINKED_PARTNERS_SUCCESS,
        types.SET_LINKED_PARTNERS_ERROR,
      ],
    },
  }
}

export function removeLinkedPartner({ user_id, partner_id }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      endpoint: `${baseAPI}/linked-partners/${user_id}/${partner_id}`,
      method: 'DELETE',
      types: [
        types.DELETE_LINKED_PARTNER_REQUEST,
        types.DELETE_LINKED_PARTNER_SUCCESS,
        types.DELETE_LINKED_PARTNER_ERROR,
      ],
    },
  }
}

export function addCustomField({ user_id, field }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(field),
      endpoint: `${baseAPI}/user-custom-fields/${user_id}`,
      method: 'POST',
      types: [
        types.ADD_CUSTOM_FIELD_REQUEST,
        types.ADD_CUSTOM_FIELD_SUCCESS,
        types.ADD_CUSTOM_FIELD_ERROR,
      ],
    },
  }
}

export function editCustomField({ user_id, field }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(field),
      endpoint: `${baseAPI}/user-custom-fields/${user_id}`,
      method: 'PUT',
      types: [
        types.UPDATE_CUSTOM_FIELD_REQUEST,
        types.UPDATE_CUSTOM_FIELD_SUCCESS,
        types.UPDATE_CUSTOM_FIELD_ERROR,
      ],
    },
  }
}

export function partnerToCompany({
  companyOrigin,
  companyDestination,
  partnerDestination,
  user_id,
}) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        companyOrigin,
        companyDestination,
        partnerDestination,
      }),
      endpoint: `${baseAPI}/users/partner-to-company/${user_id}`,
      method: 'PUT',
      types: [
        types.CHANGE_PARTNER_TO_COMPANY_REQUEST,
        types.CHANGE_PARTNER_TO_COMPANY_SUCCESS,
        types.CHANGE_PARTNER_TO_COMPANY_ERROR,
      ],
    },
  }
}
