import * as types from '../constants'
import { toast } from 'react-toastify'

const initialState = {
  docs: [],
  user: {},
  substitutes: [],
  partners: [],
}

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.LOAD_USERS_SUCCESS:
      return {
        ...state,
        ...actions.payload,
      }

    case types.LOAD_USER_SUCCESS:
      return {
        ...state,
        user: actions.payload.user,
        partners: actions.payload.partners || [],
      }

    case types.UPDATE_USER_SUCCESS:
      if (actions.payload.user.imageValid === false) {
        toast.error('La imagen no es válida, por favor adjunta una nueva')
      } else {
        toast.success('Usuario actualizado')
      }
      return {
        ...state,
        user: actions.payload.user,
        partners: actions.payload.partners || [],
      }

    case types.UPDATE_USER_ERROR:
      if (
        actions.payload.response &&
        actions.payload.response.message &&
        ((actions.payload.response.message.errmsg &&
          actions.payload.response.message.errmsg.includes('cif_1 dup key:')) ||
          (actions.payload.response.message.keyValue &&
            actions.payload.response.message.keyValue.cif))
      ) {
        toast.error('Ya hay un usuario registrado con este CIF')
      } else {
        toast.error(
          actions.payload.response?.message ||
            'Usuario no actualizado, inténtelo de nuevo'
        )
      }
      return state

    case types.LOAD_SUBTITUTES_SUCCESS:
      return {
        ...state,
        substitutes: actions.payload,
      }

    case types.DELETE_USER_SUCCESS:
      toast.success('Usuario eliminado')
      return {
        ...state,
        user: {},
      }

    case types.DELETE_USER_ERROR:
      toast.error(
        actions.payload.response.message
          ? actions.payload.response.message
          : 'No se puede eliminar el usuario'
      )
      return {
        ...state,
      }

    case types.CREATE_USER_SUCCESS:
      toast.success('Usuario creado')

      return {
        ...state,
        docs: [actions.payload.user, ...state.docs],
        partners: [actions.payload.user, ...state.partners],
      }

    case types.CREATE_USER_ERROR:
      toast.error('No se ha podido crear el usuario, inténtalo de nuevo')
      return state

    case types.CREATE_CLIENT_ERROR:
      if (
        actions.payload.response &&
        actions.payload.response.message &&
        (actions.payload.response.message.includes('email_1 dup key:') ||
          actions.payload.response.message.includes('UsernameExistsException:'))
      ) {
        toast.error('Ya hay un usuario registrado con este email')
      } else {
        toast.error(
          'No se ha podido crear el usuario, inténtalo de nuevo o contacte con el administrador'
        )
      }
      return state

    case types.ADD_SUBSTITUTE_EXCLUDED_ERROR:
      toast.error('No se ha podido excluir al sustituto, inténtalo de nuevo')
      return state

    case types.ADD_SUBSTITUTE_EXCLUDED_SUCCESS:
      toast.success('Sustituto excluido')
      return {
        ...state,
        user: {
          ...state.user,
          substitutes_excluded: [
            ...state.user.substitutes_excluded,
            actions.payload.substitute_excluded,
          ],
        },
      }

    case types.REMOVE_SUBSTITUTE_EXCLUDED_ERROR:
      toast.error('No se ha podido eliminar al sustituto, inténtalo de nuevo')
      return state

    case types.REMOVE_SUBSTITUTE_EXCLUDED_SUCCESS:
      toast.success('Sustituto desbloqueado')
      return {
        ...state,
        user: {
          ...state.user,
          substitutes_excluded: [...actions.payload.user?.substitutes_excluded],
        },
      }

    case types.SET_LINKED_PARTNERS_SUCCESS:
    case types.DELETE_LINKED_PARTNER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          linked_partners: [...actions.payload.partner.linked_partners],
        },
      }

    case types.SET_LINKED_PARTNERS_ERROR:
      toast.error('No se han podido asociar los partners seleccionados')
      return state

    case types.DELETE_LINKED_PARTNER_ERROR:
      toast.error('No se han podido desvincular el partner')
      return state

    case types.ADD_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          custom_fields: actions.payload.custom_fields,
        },
      }

    case types.UPDATE_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          custom_fields: actions.payload.custom_fields,
        },
      }

    case types.ADD_CUSTOM_FIELD_ERROR:
      if (actions.payload.status === 400)
        toast.error('Solo se permiten 5 campos personalizados activos')
      return {
        ...state,
      }

    case types.UPDATE_CUSTOM_FIELD_ERROR:
      if (actions.payload.status === 400)
        toast.error('Solo se permiten 5 campos personalizados activos')
      return {
        ...state,
      }

    case types.CHANGE_PARTNER_TO_COMPANY_SUCCESS:
      toast.success('Cambio de empresa realizado correctamente')
      return {
        ...state,
        user: actions.payload.user,
      }
    case types.CHANGE_PARTNER_TO_COMPANY_ERROR:
      toast.error('Ha ocurrido algun error y no se ha realizado el cambio')
      return {
        ...state,
      }

    case types.ADD_AUTH_POOL_SUCCESS:
    case types.EDIT_AUTH_POOL_SUCCESS:
      toast.success('Se ha realizado el cambio correctamente')

      return {
        ...state,
        user: actions.payload.user,
      }
    case types.ADD_AUTH_POOL_ERROR:
    case types.EDIT_AUTH_POOL_ERROR:
      if (actions.payload.status === 409)
        toast.error('Ya existe un pool con el mismo id/nombre')
      else toast.error('Ha ocurrido algun error y no se ha realizado el cambio')
      return {
        ...state,
      }

    default:
      return state
  }
}
