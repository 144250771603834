import * as types from '../constants'
import { toast } from 'react-toastify'

const defaultState = {
  projectShortTerm: {},
  projectsShortTerm: {},
  created: {},
  assigned: {},
  drafts: {},
}

export default function (state = defaultState, action) {
  switch (action.type) {
    case types.LOAD_PROJECTST_DRAFTS_SUCCESS:
      return {
        ...state,
        drafts: action.payload,
      }

    case types.CREATE_PROJECT_SHORT_TERM_SUCCESS:
      toast.success('Un proyecto para usted ha sido creado.')

      return {
        ...state,
        created: action.payload.projectst,
      }

    case types.CREATE_PROJECT_SHORT_TERM_ERROR:
      if (action.payload.status === 404) {
        toast.error(
          'No tenemos colaboradores disponibles para este tipo de encargo. Contacta con el administrador.'
        )
      } else {
        toast.error(
          'Ha ocurrido un error, si persiste contacte con el administrador'
        )
      }

      return state

    case types.LOAD_PROJECTS_SHORT_TERM_SUCCESS:
      return {
        ...state,
        projectsShortTerm: action.payload,
      }

    case types.LOAD_PROJECTS_SHORT_TERM_ERROR:
      toast.error(
        'Ha ocurrido un error, si persiste contacte con el administrador'
      )

      return state

    case types.LOAD_ASSIGNED_PROJECTS_SHORT_TERM_SUCCESS:
      return {
        ...state,
        assigned: action.payload,
      }

    case types.LOAD_ASSIGNED_PROJECTS_SHORT_TERM_ERROR:
      toast.error(
        'Ha ocurrido un error, si persiste contacte con el administrador'
      )

      return state

    case types.LOAD_PROJECT_SHORT_TERM_SUCCESS:
      return {
        ...state,
        projectShortTerm: action.payload.projectst,
      }
    case types.LOAD_PROJECT_SHORT_TERM_ERROR:
      toast.error(
        'Ha ocurrido un error, si persiste contacte con el administrador'
      )

      return state

    case types.UPDATE_PROJECT_SHORT_TERM_SUCCESS:
      toast.success('El proyecto ha sido actualizado.')

      return {
        ...state,
        projectShortTerm: action.payload.projectst,
      }

    case types.UPDATE_PROJECT_SHORT_TERM_ERROR:
      toast.error(
        'Ha ocurrido un error, si persiste contacte con el administrador'
      )

      return state

    case types.DELETE_PROJECT_SHORT_TERM_REQUEST:
      toast.success('El proyecto ha sido eliminado.')

      return {
        ...state,
      }

    case types.DELETE_PROJECT_SHORT_TERM_ERROR:
      toast.error(
        'Ha ocurrido un error, si persiste contacte con el administrador'
      )

      return state

    case types.PROJECT_SHORT_DOC_RECLAMATION_SUCCESS:
      toast.success('Recordatorio enviado')

      return {
        ...state,
        projectShortTerm: action.payload.projectst,
      }

    case types.PROJECT_SHORT_DOC_RECLAMATION_ERROR:
      toast.error(
        action.payload.response.message ||
          'Ha ocurrido un error enviando el recordatorio'
      )
      return { ...state }

    case types.REASSING_PROJECTST_SUCCESS:
      toast.success('Encargo reasignado')
      return {
        ...state,
        projectShortTerm: action.payload.projectst,
      }

    case types.REASSING_PROJECTST_ERROR:
      toast.error('Ha ocurrido un error al reasignar el encargo')
      return { ...state }

    case types.PROJECT_SHORT_TERM_DELETE_FILE_SUCCESS:
      toast.success('Documento eliminado correctamente')
      return {
        ...state,
        projectShortTerm: action.payload.projectst,
      }

    case types.PROJECT_SHORT_TERM_DELETE_FILE_ERROR:
      toast.error('No se ha podido eliminar el documento')
      return { ...state }

    default:
      return state
  }
}
