import React, { useState } from 'react'
import AuthLayout from '../../layouts/Auth'
import PigeonImg from '../../components/common/PigeonImg'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { toast } from 'react-toastify'
import { withRouter, Link } from 'react-router-dom'
import { baseAPI } from '../../config'
import { Button, Card, CardBody, InputGroup } from 'reactstrap'
import queryString from 'querystring'
import { PasswordEye } from '../../components/PasswordEye'

const ResetPassword = (props) => {
  const [showOriginalPassword, setShowOriginalPassword] = useState(false)
  const [showConfirmationPassword, setShowConfirmationPassword] =
    useState(false)

  const query = queryString.parse(props.location.search.replace('?', ''))

  const resetPassword = (e, errors, values) => {
    if (errors.length > 0 || values.originalPassword === '') {
      return toast.error('Introduzca una contraseña válida')
    }

    const { token } = props.match.params
    // console.log(token)
    const url = `${baseAPI}/reset/${token}`
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        password: values.originalPassword,
        token,
        verificationCode: values.verificationCode,
      }),
    }

    fetch(url, options)
      .then((res) => {
        if (res.status === 200) {
          toast.success(
            'Contraseña cambiada, recibirá un correo de confirmación'
          )
          return props.history.push('/login?security=PWDResetDone')
        }
        if (res.status === 404) return toast.warn('El token ha expirado')
        return toast.error('Algo ha salido mal, intentalo más tarde')
      })
      .catch((err) => {
        // console.log(err)
        return toast.error('Algo ha salido mal, intentalo más tarde')
      })
  }

  const handleOnHover = (password) => {
    if (password === 'originalPassword')
      setShowOriginalPassword(!showOriginalPassword)
    else setShowConfirmationPassword(!showConfirmationPassword)
  }

  return (
    <AuthLayout>
      <div className="text-center">
        <Link to="/">
          <PigeonImg />
        </Link>
      </div>

      <Card>
        <CardBody className="text-center">
          {(!query || query.security !== 'PWDNotReset') && (
            <h1 className="h2 mt-4">Vamos a solucionarlo!</h1>
          )}
          <p className="lead mb-3">Introduce tu nueva contraseña.</p>
          <p className="mb-3">
            <p className="m-0 p-0">
              <small> La contraseña debe tener al menos 8 carácteres y </small>
            </p>
            <p className="m-0 p-0">
              <small>
                {' '}
                contener al menos 1 mayúscula, 1 minúscula, 1 dígito y 1 símbolo
              </small>
            </p>
          </p>
          <div className="d-flex justify-content-around">
            <AvForm onSubmit={resetPassword}>
              <InputGroup className="text-bottom">
                <AvField
                  name="originalPassword"
                  bsSize="lg"
                  placeholder="Contraseña"
                  type={!showOriginalPassword ? 'password' : 'text'}
                  pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W|_])(?=.*[a-zA-Z]).{8,}$"
                  validate={{
                    minLength: { value: 8 },
                  }}
                  errorMessage="Formato de la contraseña incorrecto"
                  style={{
                    borderRight: 'none',
                    borderTopRightRadius: '0px',
                    borderBottomRightRadius: '0px',
                  }}
                />
                <PasswordEye
                  showPassword={showOriginalPassword}
                  handleOnHover={() => handleOnHover('originalPassword')}
                />
              </InputGroup>
              <InputGroup className="text-bottom">
                <AvField
                  name="confirmationPassword"
                  bsSize="lg"
                  placeholder="Repetir contraseña"
                  type={!showConfirmationPassword ? 'password' : 'text'}
                  validate={{ match: { value: 'originalPassword' } }}
                  errorMessage="Las contraseñas deben coincidir"
                  style={{
                    borderRight: 'none',
                    borderTopRightRadius: '0px',
                    borderBottomRightRadius: '0px',
                  }}
                />
                <PasswordEye
                  showPassword={showConfirmationPassword}
                  handleOnHover={() => handleOnHover('confirmationPassword')}
                />
              </InputGroup>
              <InputGroup>
                <AvField
                  bsSize="lg"
                  type="text"
                  name="verificationCode"
                  placeholder="Código de verificación"
                  style={{ width: '117%' }}
                />
              </InputGroup>
              <p className="mt-0">
                <small>
                  * El código de verificación se ha recibido via email
                </small>
              </p>
              <Button color="primary">Guardar contraseña</Button>
            </AvForm>
          </div>
        </CardBody>
      </Card>
    </AuthLayout>
  )
}

export default withRouter(ResetPassword)
