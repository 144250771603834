import {
  AWS_COGNITO_DOMAIN,
  AWS_COGNITO_CLIENT_ID,
  AWS_REDIRECT_URI_SIGNUP,
  AWS_REDIRECT_URI_LOGIN,
  AWS_REDIRECT_URI_LOGOUT,
} from '../config'

const sha256 = async (str) => {
  return await crypto.subtle.digest('SHA-256', new TextEncoder().encode(str))
}

const generateNonce = async () => {
  const hash = await sha256(
    crypto.getRandomValues(new Uint32Array(4)).toString()
  )
  // https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
  const hashArray = Array.from(new Uint8Array(hash))
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
}

const base64URLEncode = (string) => {
  return btoa(String.fromCharCode.apply(null, new Uint8Array(string)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '')
}

export const googleSignUp = async () => {
  const state = await generateNonce()
  const code_verifier = await generateNonce()
  sessionStorage.setItem(`code_verifier-${state}`, code_verifier)
  const codeChallenge = base64URLEncode(await sha256(code_verifier))

  const uri_awsDomain = `${AWS_COGNITO_DOMAIN}`
  const uri_idp = 'identity_provider=Google'
  const uri_state = `state=${state}`
  const uri_codeChallengeMethod = 'code_challenge_method=S256'
  const uri_codeChallenge = `code_challenge=${codeChallenge}`
  const uri_responseType = 'response_type=CODE'
  const uri_clientId = `client_id=${AWS_COGNITO_CLIENT_ID}`
  const uri_scope = 'scope=email openid profile'
  const uri_redirect = `redirect_uri=${AWS_REDIRECT_URI_SIGNUP}`

  const url = `${uri_awsDomain}/oauth2/authorize?${uri_idp}&${uri_state}&${uri_codeChallengeMethod}&${uri_codeChallenge}&${uri_responseType}&${uri_clientId}&${uri_scope}&${uri_redirect}`
  window.location.href = url
}

export const googleLogin = async () => {
  const state = await generateNonce()
  const code_verifier = await generateNonce()
  sessionStorage.setItem(`code_verifier-${state}`, code_verifier)
  const codeChallenge = base64URLEncode(await sha256(code_verifier))

  const uri_awsDomain = `${AWS_COGNITO_DOMAIN}`
  const uri_idp = 'identity_provider=Google'
  const uri_state = `state=${state}`
  const uri_codeChallengeMethod = 'code_challenge_method=S256'
  const uri_codeChallenge = `code_challenge=${codeChallenge}`
  const uri_responseType = 'response_type=CODE'
  const uri_clientId = `client_id=${AWS_COGNITO_CLIENT_ID}`
  const uri_scope = 'scope=email openid profile'
  const uri_redirect = `redirect_uri=${AWS_REDIRECT_URI_LOGIN}`

  const url = `${uri_awsDomain}/oauth2/authorize?${uri_idp}&${uri_state}&${uri_codeChallengeMethod}&${uri_codeChallenge}&${uri_responseType}&${uri_clientId}&${uri_scope}&${uri_redirect}`
  window.location.href = url
}

export const googleLogout = async () => {
  const uri_awsDomain = `${AWS_COGNITO_DOMAIN}`
  const uri_clientId = `client_id=${AWS_COGNITO_CLIENT_ID}`
  const uri_redirect = `logout_uri=${AWS_REDIRECT_URI_LOGOUT}`

  const url = `${uri_awsDomain}/logout?${uri_clientId}&${uri_redirect}`
  window.location.href = url
}
