/**
 * Return at the start of the form if there are missing inputs on
 * user form.
 * @param {any} history react-router history
 * @param {object} user object containing user form input values
 */
 export const restartFormIfMissingValues = (history, user) => {
  if (
    !user.name ||
    !user.last_name ||
    !user.phone ||
    !user.email ||
    !user.password ||
    !user.role || 
    !user.substitute_judicial_parties ||
    !user.colegiado
  ) history.push('/create-user-form/name')
}
