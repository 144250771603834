const substituteSkills = [
  {
    area: 'Administrativo',
    code: 'A1',
    id: 1,
    speciality: [
      {
        id: '1',
        code: 'A1-001',
        value: 'Contratación pública',
      },
      {
        id: '2',
        code: 'A1-002',
        value: 'Derecho ambiental',
      },
      {
        id: '3',
        code: 'A1-003',
        value: 'Derecho de la cultura',
      },
      {
        id: '4',
        code: 'A1-004',
        value: 'Derecho de la educación',
      },
      {
        id: '5',
        code: 'A1-005',
        value: 'Derecho de la energía',
      },
      {
        id: '6',
        code: 'A1-006',
        value: 'Derecho de la función pública',
      },
      {
        id: '7',
        code: 'A1-007',
        value: 'Derecho de la inmigración',
      },
      {
        id: '8',
        code: 'A1-008',
        value: 'Derecho de la organización territorial',
      },
      {
        id: '9',
        code: 'A1-009',
        value: 'Derecho de la propiedad industrial e intelectual',
      },
      {
        id: '10',
        code: 'A1-010',
        value: 'Derecho de la propiedad pública',
      },
      {
        id: '11',
        code: 'A1-011',
        value: 'Derecho de la protección de datos',
      },
      {
        id: '12',
        code: 'A1-012',
        value: 'Derecho de la regulación económica',
      },
      {
        id: '13',
        code: 'A1-013',
        value: 'Derecho de la sanidad',
      },
      {
        id: '14',
        code: 'A1-014',
        value: 'Derecho de la seguridad y defensa',
      },
      {
        id: '15',
        code: 'A1-015',
        value: 'Derecho de la transparencia',
      },
      {
        id: '16',
        code: 'A1-016',
        value: 'Derecho de la Unión Europea',
      },
      {
        id: '17',
        code: 'A1-017',
        value: 'Derecho del acceso a la información',
      },
      {
        id: '18',
        code: 'A1-018',
        value: 'Derecho del patrimonio histórico',
      },
      {
        id: '19',
        code: 'A1-019',
        value: 'Derecho del procedimiento administrativo',
      },
      {
        id: '20',
        code: 'A1-020',
        value: 'Derecho del transporte',
      },
    ],
  },
  {
    area: 'Civil',
    code: 'A2',
    id: 2,
    speciality: [
      {
        id: 1,
        code: 'A2-001',
        value: 'Accidentes de tráfico',
      },
      {
        id: 2,
        code: 'A2-002',
        value: 'Adopciones',
      },
      {
        id: 3,
        code: 'A2-003',
        value: 'Arrendamientos urbanos',
      },
      {
        id: 4,
        code: 'A2-004',
        value: 'Cláusulas abusivas',
      },
      {
        id: 5,
        code: 'A2-005',
        value: 'Contratos civiles',
      },
      {
        id: 6,
        code: 'A2-006',
        value: 'Divorcios y separaciones',
      },
      {
        id: 7,
        code: 'A2-007',
        value: 'Derecho agrario',
      },
      {
        id: 8,
        code: 'A2-008',
        value: 'Derecho al honor, intimidad y propia imagen',
      },
      {
        id: 9,
        code: 'A2-009',
        value: 'Derecho ambiental',
      },
      {
        id: 10,
        code: 'A2-010',
        value: 'Derecho de asociaciones',
      },
      {
        id: 11,
        code: 'A2-011',
        value: 'Derecho de bienes',
      },
      {
        id: 12,
        code: 'A2-012',
        value: 'Derecho de familia',
      },
      {
        id: 13,
        code: 'A2-013',
        value: 'Derecho de la competencia',
      },
      {
        id: 14,
        code: 'A2-014',
        value: 'Derecho de la navegación',
      },
      {
        id: 15,
        code: 'A2-015',
        value: 'Derecho de nuevas tecnologías',
      },
      {
        id: 16,
        code: 'A2-016',
        value: 'Derecho de obligaciones',
      },
      {
        id: 17,
        code: 'A2-017',
        value: 'Derecho de propiedad industrial',
      },
      {
        id: 18,
        code: 'A2-018',
        value: 'Derecho de propiedad intelectual',
      },
      {
        id: 19,
        code: 'A2-019',
        value: 'Derecho de seguros',
      },
      {
        id: 20,
        code: 'A2-020',
        value: 'Derecho del consumidor',
      },
      {
        id: 21,
        code: 'A2-021',
        value: 'Derecho del transporte',
      },
      {
        id: 22,
        code: 'A2-022',
        value: 'Derecho hipotecario',
      },
      {
        id: 23,
        code: 'A2-023',
        value: 'Derecho inmobiliario',
      },
      {
        id: 24,
        code: 'A2-024',
        value: 'Derecho registral',
      },
      {
        id: 25,
        code: 'A2-025',
        value: 'Derechos reales',
      },
      {
        id: 26,
        code: 'A2-026',
        value: 'Desahucios',
      },
      {
        id: 27,
        code: 'A2-027',
        value: 'Herencias y testamentos',
      },
      {
        id: 28,
        code: 'A2-028',
        value: 'Indemnizaciones por daños y perjuicios',
      },
      {
        id: 29,
        code: 'A2-029',
        value: 'Nulidad de Contratos',
      },
      {
        id: 30,
        code: 'A2-030',
        value: 'Patria potestad',
      },
      {
        id: 31,
        code: 'A2-031',
        value: 'Protección de consumidores y usuarios',
      },
      {
        id: 32,
        code: 'A2-032',
        value: 'Propiedad horizontal',
      },
      {
        id: 33,
        code: 'A2-033',
        value: 'Reclamaciones de cantidad',
      },
      {
        id: 34,
        code: 'A2-034',
        value: 'Resolución contractual',
      },
      {
        id: 35,
        code: 'A2-035',
        value: 'Responsabilidad civil',
      },
      {
        id: 36,
        code: 'A2-036',
        value: 'Tutela y curatela',
      },
    ],
  },
  {
    area: 'Laboral',
    code: 'A3',
    id: 3,
    speciality: [
      {
        id: 1,
        code: 'A3-001',
        value: 'Accidentes de trabajo',
      },
      {
        id: 2,
        code: 'A3-002',
        value: 'Contratación a tiempo parcial',
      },
      {
        id: 3,
        code: 'A3-003',
        value: 'Contratación de personas con discapacidad',
      },
      {
        id: 4,
        code: 'A3-004',
        value: 'Contratación indefinida',
      },
      {
        id: 5,
        code: 'A3-005',
        value: 'Contratación temporal',
      },
      {
        id: 6,
        code: 'A3-006',
        value: 'Derecho de la conciliación laboral y familiar',
      },
      {
        id: 7,
        code: 'A3-007',
        value: 'Derecho de la formación profesional',
      },
      {
        id: 8,
        code: 'A3-008',
        value: 'Derecho de la igualdad y no discriminación',
      },
      {
        id: 9,
        code: 'A3-009',
        value: 'Derecho de la jornada laboral y horas extras',
      },
      {
        id: 10,
        code: 'A3-0010',
        value: 'Derecho de la movilidad geográfica',
      },
      {
        id: 11,
        code: 'A3-0011',
        value: 'Derecho de la Seguridad Social',
      },
      {
        id: 12,
        code: 'A3-0012',
        value: 'Derecho de las garantías por cambio de empresa',
      },
      {
        id: 13,
        code: 'A3-0013',
        value: 'Derecho de las retribuciones y salario',
      },
      {
        id: 14,
        code: 'A3-0014',
        value: 'Derecho de la sucesión de empresa',
      },
      {
        id: 15,
        code: 'A3-0015',
        value: 'Derecho de la contratación de trabajadores extranjeros',
      },
      {
        id: 16,
        code: 'A3-0016',
        value: 'Derecho de la contratación parcial',
      },
      {
        id: 17,
        code: 'A3-0017',
        value: 'Derecho de la contratación temporal',
      },
      {
        id: 18,
        code: 'A3-0018',
        value: 'Derecho de la inspección de trabajo',
      },
      {
        id: 19,
        code: 'A3-0019',
        value: 'Despidos improcedentes',
      },
      {
        id: 20,
        code: 'A3-0020',
        value: 'Enfermedades profesionales',
      },
      {
        id: 21,
        code: 'A3-0021',
        value: 'Expedientes de regulación de empleo (ERE)',
      },
      {
        id: 22,
        code: 'A3-0022',
        value: 'Expedientes de regulación temporal de empleo (ERTE)',
      },
      {
        id: 23,
        code: 'A3-0023',
        value: 'Incapacidad permanente',
      },
      {
        id: 24,
        code: 'A3-0024',
        value: 'Incapacidad temporal',
      },
      {
        id: 25,
        code: 'A3-0025',
        value: 'Modificación sustancial de las condiciones de trabajo',
      },
      {
        id: 26,
        code: 'A3-0026',
        value: 'Negociación colectiva',
      },
      {
        id: 27,
        code: 'A3-0027',
        value: 'Nulidad de Despido',
      },
      {
        id: 28,
        code: 'A3-0028',
        value: 'Pensiones de invalidez',
      },
      {
        id: 29,
        code: 'A3-0029',
        value: 'Pensiones de jubilación',
      },
      {
        id: 30,
        code: 'A3-0030',
        value: 'Pensiones de orfandad',
      },
      {
        id: 31,
        code: 'A3-0031',
        value: 'Pensiones de viudedad',
      },
      {
        id: 32,
        code: 'A3-0032',
        value: 'Prevención de riesgos laborales',
      },
      {
        id: 33,
        code: 'A3-0033',
        value: 'Reclamaciones de salarios impagados',
      },
    ],
  },
  {
    area: 'Mercantil',
    id: 4,
    code: 'A4',
    speciality: [
      {
        id: 1,
        code: 'A4-001',
        value: 'Derecho bancario',
      },
      {
        id: 2,
        code: 'A4-002',
        value: 'Derecho bursátil',
      },
      {
        id: 3,
        code: 'A4-003',
        value: 'Derecho de la competencia',
      },
      {
        id: 4,
        code: 'A4-004',
        value: 'Derecho de la construcción',
      },
      {
        id: 5,
        code: 'A4-005',
        value: 'Derecho de la cooperativa',
      },
      {
        id: 6,
        code: 'A4-006',
        value: 'Derecho de la distribución comercial',
      },
      {
        id: 7,
        code: 'A4-007',
        value: 'Derecho de la energía',
      },
      {
        id: 8,
        code: 'A4-008',
        value: 'Derecho de la franquicia',
      },
      {
        id: 9,
        code: 'A4-009',
        value: 'Derecho de la fundación',
      },
      {
        id: 10,
        code: 'A4-010',
        value: 'Derecho de la ingeniería',
      },
      {
        id: 11,
        code: 'A4-011',
        value: 'Derecho de la logística',
      },
      {
        id: 12,
        code: 'A4-012',
        value: 'Derecho de la minería',
      },
      {
        id: 13,
        code: 'A4-013',
        value: 'Derecho de la navegación',
      },
      {
        id: 14,
        code: 'A4-014',
        value: 'Derecho de la propiedad industrial',
      },
      {
        id: 15,
        code: 'A4-015',
        value: 'Derecho de la propiedad intelectual',
      },
      {
        id: 16,
        code: 'A4-016',
        value: 'Derecho de la publicidad',
      },
      {
        id: 17,
        code: 'A4-017',
        value: 'Derecho de la sociedad anónima',
      },
      {
        id: 18,
        code: 'A4-018',
        value: 'Derecho de la sociedad limitada',
      },
      {
        id: 19,
        code: 'A4-019',
        value: 'Derecho de la competencia',
      },
      {
        id: 20,
        code: 'A4-020',
        value: 'Derecho de la contratación mercantil',
      },
      {
        id: 21,
        code: 'A4-021',
        value: 'Derecho de la energía',
      },
      {
        id: 22,
        code: 'A4-022',
        value: 'Derecho de la navegación',
      },
      {
        id: 23,
        code: 'A4-023',
        value: 'Derecho de la propiedad industrial',
      },
      {
        id: 24,
        code: 'A4-024',
        value: 'Derecho de la propiedad intelectual',
      },
      {
        id: 25,
        code: 'A4-025',
        value: 'Derecho de la regulación económica',
      },
      {
        id: 26,
        code: 'A4-026',
        value: 'Derecho de la subrogación',
      },
      {
        id: 27,
        code: 'A4-027',
        value: 'Derecho de la telecomunicaciones',
      },
      {
        id: 28,
        code: 'A4-028',
        value: 'Derecho del comercio electrónico',
      },
      {
        id: 29,
        code: 'A4-029',
        value: 'Derecho del deporte',
      },
      {
        id: 30,
        code: 'A4-030',
        value: 'Derecho del transporte',
      },
      {
        id: 31,
        code: 'A4-031',
        value: 'Derecho mercantil',
      },
      {
        id: 32,
        code: 'A4-032',
        value: 'Derecho societario',
      },
      {
        id: 33,
        code: 'A4-033',
        value: 'Incidentes concursales',
      },
      {
        id: 34,
        code: 'A4-034',
        value: 'Reclamaciones aéreas',
      },
      {
        id: 35,
        code: 'A4-035',
        value: 'Responsabilidad del administrador',
      },
      {
        id: 36,
        code: 'A4-036',
        value: 'Responsabilidad por productos defectuosos',
      },
      {
        id: 37,
        code: 'A4-037',
        value: 'Seguros y reaseguros',
      },
    ],
  },
  {
    area: 'Penal',
    code: 'A5',
    id: 5,
    speciality: [
      {
        id: 1,
        code: 'A5-001',
        value: 'Abuso de información privilegiada',
      },
      {
        id: 2,
        code: 'A5-002',
        value: 'Alcoholemia',
      },
      {
        id: 3,
        code: 'A5-003',
        value: 'Apropiación indebida',
      },
      {
        id: 4,
        code: 'A5-004',
        value: 'Asesinato',
      },
      {
        id: 5,
        code: 'A5-005',
        value: 'Blanqueo de capitales',
      },
      {
        id: 6,
        code: 'A5-006',
        value: 'Cohecho',
      },
      {
        id: 7,
        code: 'A5-007',
        value: 'Corrupción',
      },
      {
        id: 8,
        code: 'A5-008',
        value: 'Delitos contra la Administración de Justicia',
      },
      {
        id: 9,
        code: 'A5-009',
        value: 'Delitos contra la Administración Pública',
      },
      {
        id: 10,
        code: 'A5-010',
        value: 'Delitos contra la Hacienda Pública',
      },
      {
        id: 11,
        code: 'A5-011',
        value: 'Delitos contra la indemnidad sexual',
      },
      {
        id: 12,
        code: 'A5-012',
        value: 'Delitos contra la libertad',
      },
      {
        id: 13,
        code: 'A5-013',
        value: 'Delitos contra la libertad sexual',
      },
      {
        id: 14,
        code: 'A5-0014',
        value: 'Delitos contra la ordenación del territorio',
      },
      {
        id: 15,
        code: 'A5-015',
        value: 'Delitos contra la propiedad intelectual e industrial',
      },
      {
        id: 16,
        code: 'A5-016',
        value: 'Delitos contra la seguridad en el trabajo',
      },
      {
        id: 17,
        code: 'A5-017',
        value: 'Delitos contra la seguridad vial',
      },
      {
        id: 18,
        code: 'A5-018',
        value: 'Delitos contra la salud pública',
      },
      {
        id: 19,
        code: 'A5-019',
        value: 'Delitos de abuso de información privilegiada',
      },
      {
        id: 20,
        code: 'A5-020',
        value: 'Delitos de apropiación indebida',
      },
      {
        id: 21,
        code: 'A5-021',
        value: 'Delitos de asesinato',
      },
      {
        id: 22,
        code: 'A5-022',
        value: 'Delitos de blanqueo de capitales',
      },
      {
        id: 23,
        code: 'A5-023',
        value: 'Delitos de cohecho',
      },
      {
        id: 24,
        code: 'A5-024',
        value: 'Delitos de corrupción',
      },
      {
        id: 25,
        code: 'A5-025',
        value: 'Delitos de estafa',
      },
      {
        id: 26,
        code: 'A5-026',
        value: 'Delitos de extorsión',
      },
      {
        id: 27,
        code: 'A5-027',
        value: 'Delitos de falsedad documental',
      },
      {
        id: 28,
        code: 'A5-028',
        value: 'Delitos de homicidio',
      },
      {
        id: 29,
        code: 'A5-029',
        value: 'Delitos de incendio',
      },
      {
        id: 30,
        code: 'A5-030',
        value: 'Delitos de insolvencia punible',
      },
      {
        id: 31,
        code: 'A5-031',
        value: 'Delitos de lesiones',
      },
      {
        id: 32,
        code: 'A5-032',
        value: 'Delitos de maltrato animal',
      },
      {
        id: 33,
        code: 'A5-033',
        value: 'Delitos de omisión del deber de socorro',
      },
      {
        id: 34,
        code: 'A5-034',
        value: 'Delitos de prevaricación',
      },
      {
        id: 35,
        code: 'A5-035',
        value: 'Delitos de robo',
      },
      {
        id: 36,
        code: 'A5-036',
        value: 'Delitos de terrorismo',
      },
      {
        id: 37,
        code: 'A5-037',
        value: 'Delitos de tráfico de drogas',
      },
      {
        id: 38,
        code: 'A5-038',
        value: 'Delitos de tráfico de influencias',
      },
      {
        id: 39,
        code: 'A5-039',
        value: 'Delitos de trata de seres humanos',
      },
      {
        id: 40,
        code: 'A5-040',
        value: 'Hurtos',
      },
      {
        id: 41,
        code: 'A5-041',
        value: 'Ocupaciones ilegales',
      },
    ],
  },
  {
    area: 'Extraprocesal',
    code: 'A6',
    id: 6,
    speciality: [
      {
        id: 1,
        code: 'A6-001',
        value: 'Acuerdos extrajudiciales',
      },
      {
        id: 2,
        code: 'A6-002',
        value: 'Actos de conciliación Laboral',
      },
      {
        id: 3,
        code: 'A6-003',
        value: 'Actos de mediación',
      },
      {
        id: 4,
        code: 'A6-004',
        value: 'Arbitrajes',
      },
      {
        id: 5,
        code: 'A6-005',
        value: 'Auditoría jurídica',
      },
      {
        id: 6,
        code: 'A6-006',
        value: 'Consultoría y asesoramiento legal preventivo',
      },
      {
        id: 7,
        code: 'A6-007',
        value: 'Contratos',
      },
      {
        id: 8,
        code: 'A6-008',
        value: 'Due diligence legal',
      },
      {
        id: 9,
        code: 'A6-009',
        value: 'Firmas en Notaria',
      },
      {
        id: 10,
        code: 'A6-010',
        value: 'Mecanismos alternativos de resolución de conflictos',
      },
      {
        id: 11,
        code: 'A6-011',
        value: 'Negociación',
      },
      {
        id: 12,
        code: 'A6-012',
        value: 'Resolución de conflictos internacionales',
      },
    ],
  },
]
const languagesOptions = [
  { value: 'es', label: 'Castellano' },
  { value: 'cat', label: 'Catalán' },
  { value: 'gl', label: 'Gallego' },
  { value: 'eu', label: 'Euskera' },
  { value: 'en', label: 'Inglés' },
  { value: 'fr', label: 'Francés' },
  { value: 'de', label: 'Alemán' },
]

const assignmentsOptions = [
  {
    value: 'hearing',
    label: 'Sustituciones presenciales',
    subLabel:
      '¿Quieres hacer sustituciones en actuaciones judiciales de manera presencial?',
    description:
      'Las sustituciones presenciales son los encargos por medio de los cuales, un cliente nos encarga la realización de una actuación judicial concreta, en un juzgado determinado.',
  },
  {
    value: 'projectst',
    label: 'Pigeon on call',
    subLabel:
      '¿Quieres confeccionar y revisar escritos judiciales para nuestros clientes?',
    description:
      'Los encargos Pigeon on call son encargos enfocados a la tarea concreta, es decir, a la confección y revisión de diversos escritos, tanto judiciales como extrajudiciales, en las diferentes ramas del Derecho.',
  },
  {
    value: 'projectlt',
    label: 'Pigeon in house',
    subLabel:
      '¿Te gustaría trabajar como freelance para un despacho desde una semana a tres meses?',
    description:
      'Los encargos Pigeon in house son encargos de larga duración para realizar proyectos determinados (suplir una baja laboral, ayudar en picos de trabajo…)',
  },
  {
    value: 'telematic',
    label: 'Sustituciones telemáticas',
    subLabel:
      '¿Te gustaría hacer sustituciones en actuaciones judiciales en formato telemático?',
    description:
      'Las sustituciones telemáticas son los encargos por medio de los cuales, un cliente nos encarga la realización de una actuación judicial concreta para realizar de forma telemática.',
  },
]

const quantity = [
  { id: '0', label: 'Seleccione cantidad' },
  { id: '1', label: '1' },
  { id: '2', label: '2' },
  { id: '3', label: '3' },
  { id: '4', label: '4' },
  { id: '5', label: '5' },
  { id: '6', label: '6' },
  { id: '7', label: '7' },
  { id: '8', label: '8' },
]

const project_st_civil = [
  {
    id: 'A2-1',
    label: 'Demanda-contestacion',
    entrega:
      'Demanda-contestación en formato word, índice documental y documentos numerados.',
    doc_type: [
      {
        id: 'A2-1-001',
        label: 'Cláusulas suelo en hipotecas',
        price: 65,
        description:
          'Redacción y revisión de una demanda de cláusula suelo en un préstamo hipotecario',
      },
      {
        id: 'A2-1-002',
        label: 'Gastos hipotecarios',
        price: 65,
        description:
          'Redacción y revisión de una demanda de gastos en un préstamo hipotecario',
      },
      {
        id: 'A2-1-003',
        label: 'IRPH',
        price: 65,
        description:
          'Redacción y revisión de una demanda de IRPH en un préstamo hipotecario',
      },
      {
        id: 'A2-1-004',
        label: 'Usura',
        price: 65,
        description: 'Redacción y revisión de una demanda en materia de usura',
      },
      {
        id: 'A2-1-005',
        label: 'Reclamación de cantidad',
        price: 95,
        description:
          'Redacción y revisión de una demanda de reclamación de cantidad',
      },
      {
        id: 'A2-1-006',
        label: 'Monitorio',
        price: 65,
        description:
          'Redacción y revisión de una demanda de procedimiento monitorio',
      },
      {
        id: 'A2-1-007',
        label: 'Incumplimiento contractual',
        price: 95,
        description:
          'Redacción y revisión de una demanda por incumplimiento contractual',
      },
      {
        id: 'A2-1-008',
        label: 'Responsabilidad Civil',
        price: 120,
        description:
          'Redacción y revisión de una demanda de responsabilidad civil',
      },
      {
        id: 'A2-1-009',
        label: 'Monitorio comunidad propietarios',
        price: 95,
        description:
          'Redacción y revisión de una demanda de reclamación de cuotas de comunidad',
      },
      {
        id: 'A2-1-010',
        label: 'Proceso de incapacitación',
        price: 100,
        description:
          'Redacción y revisión de una demanda de proceso de incapacitación',
      },
      {
        id: 'A2-1-011',
        label: 'Divorcio mutuo acuerdo',
        price: 95,
        description:
          'Redacción y revisión de una demanda de divorcio de mútuo acuerdo',
      },
      {
        id: 'A2-1-012',
        label: 'Divorcio concencioso',
        price: 150,
        description:
          'Redacción y revisión de una demanda de divorcio contencioso',
      },
      {
        id: 'A2-1-013',
        label: 'Medidas paterno-filiales',
        price: 150,
        description:
          'Redacción y revisión de una demanda de medidas paterno-filiales',
      },
      {
        id: 'A2-1-014',
        label: 'Desahucio',
        price: 65,
        description: 'Redacción y revisión de una demanda de desahucio',
      },
      {
        id: 'A2-1-015',
        label: 'Disolución de condominio',
        price: 65,
        description:
          'Redacción y revisión de una demanda de disolución de condominio',
      },
      {
        id: 'A2-1-016',
        label: 'Pérdida de equipaje',
        price: 65,
        description:
          'Redacción y revisión de una demanda por pérdida de equipaje',
      },
      {
        id: 'A2-1-017',
        label: 'Pérdida de vuelo',
        price: 65,
        description: 'Redacción y revisión de una demanda por pérdida de vuelo',
      },

      {
        id: 'A2-1-018',
        label: 'Retraso de vuelo',
        price: 65,
        description: 'Redacción y revisión de una demanda por retraso de vuelo',
      },
    ],
  },
  {
    id: 'A2-2',
    label: 'Instructa',
    entrega: 'Instructa en formato word y documentos adjuntos (en su caso).',
    doc_type: [
      {
        id: 'A2-2-001',
        label: 'Sobre clausulas abusivas',
        price: 15,
        description:
          'Redacción y revisión de una instructa para una vista en materia de cláusulas abusivas',
      },
      {
        id: 'A2-2-002',
        label: 'Resto de procedimientos',
        price: 30,
        description:
          'Redacción y revisión de una instructa para una vista en "resto de procedimientos"',
      },
    ],
  },
  {
    id: 'A2-3',
    label: 'Recurso',
    entrega: 'Recurso en formato word y documentos adjuntos (en su caso).',
    doc_type: [
      {
        id: 'A2-3-001',
        label: 'Reposición',
        price: 65,
        description: 'Redacción y revisión de un recurso de reposición',
      },
      {
        id: 'A2-3-002',
        label: 'Apelación',
        price: 150,
        description: 'Redacción y revisión de un recurso de apelación',
      },
      {
        id: 'A2-3-003',
        label: 'Oposicion recurso apelación',
        price: 150,
        description: 'Redacción y revisión de un recurso de apelación',
      },
      {
        id: 'A2-3-004',
        label: 'Casación',
        price: 400,
        description: 'Redacción y revisión de un recurso de casación',
      },
      {
        id: 'A2-3-005',
        label: 'Extraordinario por infracción procesal',
        price: 400,
        description:
          'Redacción y revisión de un recurso extraordinario por infracción procesal',
      },
    ],
  },
  {
    id: 'A2-4',
    label: 'Ejecución',
    entrega:
      'Demanda en formato word, índice documental y documentos numerados.',
    doc_type: [
      {
        id: 'A2-4-001',
        label: 'Título judicial',
        price: 65,
        description:
          'Redacción y revisión de una demanda de ejecución de títulos judiciales',
      },
      {
        id: 'A2-4-002',
        label: 'Título no judicial',
        price: 65,
        description:
          'Redacción y revisión de una demanda de ejecución de títulos no judiciales',
      },
    ],
  },
  {
    id: 'A2-5',
    label: 'Tasación de costas',
    entrega:
      'Tasación de costas en formato word, minutas y criterios aplicados (en su caso).',
    doc_type: [
      {
        id: 'A2-5-001',
        label: 'Primera instancia',
        price: 30,
        description:
          'Redacción y revisión de una tasación de costas en primera instancia',
      },
      {
        id: 'A2-5-002',
        label: 'Ejecución',
        price: 30,
        description:
          'Redacción y revisión de una tasación de costas en ejecución',
      },
      {
        id: 'A2-5-003',
        label: 'Apelación',
        price: 30,
        description:
          'Redacción y revisión de una tasación de costas en apelación',
      },
      {
        id: 'A2-5-004',
        label: 'Incidente',
        price: 30,
        description:
          'Redacción y revisión de una tasación de costas en un incidente',
      },
    ],
  },
  {
    id: 'A2-6',
    label: 'Liquidación de intereses',
    entrega:
      'Liquidación de intereses en formato word y documentos adjuntos (cálculos realizados).',
    doc_type: [
      {
        id: 'A2-6-001',
        label: 'Liquidación de intereses',
        price: 30,
        description: 'Redacción y revisión de una liquidación de intereses',
      },
    ],
  },
  {
    id: 'A2-7',
    label: 'Contrato',
    entrega: 'Contrato en formato word y documentos adjuntos (en su caso).',
    doc_type: [
      {
        id: 'A2-7-001',
        label: 'Compraventa',
        price: 95,
        description: 'Redacción y revisión de un contrato de compraventa',
      },
      {
        id: 'A2-7-002',
        label: 'Arrendamiento',
        price: 95,
        description: 'Redacción y revisión de un contrato de arrendamiento',
      },
      {
        id: 'A2-7-003',
        label: 'Obra o servicio',
        price: 95,
        description: 'Redacción y revisión de un contrato de obra o servicio',
      },
      {
        id: 'A2-7-004',
        label: 'Fianza',
        price: 95,
        description: 'Redacción y revisión de un contrato de fianza',
      },
      {
        id: 'A2-7-005',
        label: 'Cesión de derechos',
        price: 95,
        description:
          'Redacción y revisión de un contrato de cesión de derechos',
      },
      {
        id: 'A2-7-006',
        label: 'Agencia',
        price: 95,
        description: 'Redacción y revisión de un contrato de agencia',
      },
    ],
  },
]

const project_st_mercantil = [
  {
    id: 'A4-1',
    label: 'Demanda-contestacion',
    entrega:
      'Demanda-contestación en formato word, índice documental y documentos numerados.',
    doc_type: [
      {
        id: 'A4-1-001',
        label: 'Concurso voluntario',
        price: 250,
        description:
          'Redacción y revisión de una demanda de concurso voluntario',
      },
      {
        id: 'A4-1-002',
        label: 'Concurso necesario',
        price: 150,
        description:
          'Redacción y revisión de una demanda de concurso necesario',
      },
      {
        id: 'A4-1-003',
        label: 'Propiedad Intelectual',
        price: 95,
        description:
          'Redacción y revisión de una demanda de propiedad intelectual',
      },
      {
        id: 'A4-1-004',
        label: 'Propiedad Industrial',
        price: 95,
        description:
          'Redacción y revisión de una demanda de propiedad iindustrial',
      },
      {
        id: 'A4-1-005',
        label: 'Competencia desleal',
        price: 95,
        description:
          'Redacción y revisión de una demanda de competencia desleal',
      },
      {
        id: 'A4-1-006',
        label: 'Cambiario',
        price: 65,
        description: 'Redacción y revisión de una demanda de cambiario',
      },
      {
        id: 'A4-1-007',
        label: 'Defensa de la competencia',
        price: 95,
        description:
          'Redacción y revisión de una demanda de defensa de la competencia',
      },
      {
        id: 'A4-1-008',
        label: 'Cártel de coches',
        price: 65,
        description: 'Redacción y revisión de una demanda por cártel de coches',
      },
    ],
  },
  {
    id: 'A4-2',
    label: 'Instructa',
    entrega: 'Instructa en formato word y documentos adjuntos (en su caso).',
    doc_type: [
      {
        id: 'A4-2-001',
        label: 'Resto de procedimientos',
        price: 30,
        description:
          'Redacción y revisión de una instructa para resto de procedimientos',
      },
    ],
  },
  {
    id: 'A4-3',
    label: 'Recurso',
    entrega: 'Recurso en formato word y documentos adjuntos (en su caso).',
    doc_type: [
      {
        id: 'A4-3-001',
        label: 'Reposición',
        price: 65,
        description: 'Redacción y revisión de un recurso de reposición',
      },
      {
        id: 'A4-3-002',
        label: 'Apelación',
        price: 150,
        description: 'Redacción y revisión de un recurso de apelación',
      },
      {
        id: 'A4-3-003',
        label: 'Oposicion recurso apelación',
        price: 150,
        description:
          'Redacción y revisión de una oposición a un recurso de apelación',
      },
      {
        id: 'A4-3-004',
        label: 'Casación',
        price: 400,
        description: 'Redacción y revisión de un recurso de casación',
      },
      {
        id: 'A4-3-005',
        label: 'Extraordinario por infracción procesal',
        price: 400,
        description:
          'Redacción y revisión de un recurso extraordinario por infracción procesal',
      },
    ],
  },
  {
    id: 'A4-4',
    label: 'Ejecución',
    entrega:
      'Demanda en formato word, índice documental y documentos numerados.',
    doc_type: [
      {
        id: 'A4-4-001',
        label: 'Sentencia',
        price: 65,
        description: 'Redacción y revisión de una ejecución de sentencia',
      },
      {
        id: 'A4-4-002',
        label: 'Laudo arbitral',
        price: 65,
        description: 'Redacción y revisión de una ejecución de laudo arbitral',
      },
    ],
  },
  {
    id: 'A4-5',
    label: 'Tasación de costas',
    entrega:
      'Tasación de costas en formato word, minutas y criterios aplicados (en su caso).',
    doc_type: [
      {
        id: 'A4-5-001',
        label: 'Primera instancia',
        price: 30,
        description:
          'Redacción y revisión de una tasación de costas en primera instancia',
      },
      {
        id: 'A4-5-002',
        label: 'Ejecución',
        price: 30,
        description:
          'Redacción y revisión de una tasación de costas en ejecución',
      },
      {
        id: 'A4-5-003',
        label: 'Apelación',
        price: 30,
        description:
          'Redacción y revisión de una tasación de costas en apelación',
      },
      {
        id: 'A4-5-004',
        label: 'Incidente',
        price: 30,
        description:
          'Redacción y revisión de una tasación de costas en un incidente',
      },
    ],
  },
  {
    id: 'A4-6',
    label: 'Liquidación de intereses',
    entrega:
      'Liquidación de intereses en formato word y documentos adjuntos (cálculos realizados).',
    doc_type: [
      {
        id: '20',
        label: 'Liquidación de intereses',
        price: 30,
        description: 'Redacción y revisión de una liquidación de intereses',
      },
    ],
  },
  {
    id: 'A4-7',
    label: 'Contrato',
    entrega: 'Contrato en formato word y documentos adjuntos (en su caso).',
    doc_type: [
      {
        id: 'A4-7-001',
        label: 'Compraventa mercantil',
        price: 95,
        description:
          'Redacción y revisión de un contrato de compraventa mercantil',
      },
      {
        id: 'A4-7-002',
        label: 'Contrato de distribución',
        price: 95,
        description: 'Redacción y revisión de un contrato de distribución',
      },
      {
        id: 'A4-7-003',
        label: 'Contrato de agencia',
        price: 95,
        description: 'Redacción y revisión de un contrato de agencia',
      },
      {
        id: 'A4-7-004',
        label: 'Leasing',
        price: 95,
        description: 'Redacción y revisión de un contrato de leasing',
      },
      {
        id: 'A4-7-005',
        label: 'Factoring',
        price: 95,
        description: 'Redacción y revisión de un contrato de factoring',
      },
      {
        id: 'A4-7-006',
        label: 'Franquicia',
        price: 95,
        description: 'Redacción y revisión de un contrato de franquicia',
      },
    ],
  },
]

const project_st_administrativo = [
  {
    id: 'A1-1',
    label: 'Demanda-contestacion',
    entrega:
      'Demanda-contestación en formato word, índice documental y documentos numerados.',
    doc_type: [
      {
        id: 'A1-1-001',
        label: 'Contencioso-administrativo',
        price: 95,
        description:
          'Redacción y revisión de una demanda de contencioso-administrativo',
      },
      {
        id: 'A1-1-002',
        label: 'Responsabilidad patrimonial de la Administración',
        price: 95,
        description:
          'Redacción y revisión de una demanda de responsabilidad patrimonial de la administración.',
      },
    ],
  },
  {
    id: 'A1-2',
    label: 'Instructa',
    entrega: 'Instructa en formato word y documentos adjuntos (en su caso).',
    doc_type: [
      {
        id: 'A1-2-001',
        label: 'Resto de procedimientos',
        price: 30,
        description:
          'Redacción y revisión de una instructa para resto de procedimientos',
      },
    ],
  },
  {
    id: 'A1-3',
    label: 'Recurso',
    entrega: 'Recurso en formato word y documentos adjuntos (en su caso).',
    doc_type: [
      {
        id: 'A1-3-001',
        label: 'Alzada',
        price: 95,
        description: 'Redacción y revisión de un recurso de alzada',
      },
      {
        id: 'A1-3-002',
        label: 'Reposición',
        price: 65,
        description: 'Redacción y revisión de un recurso de reposición',
      },
      {
        id: 'A1-3-003',
        label: 'Procedimiento sancionador',
        price: 65,
        description:
          'Redacción y revisión de un recurso en un procedimiento sancionador',
      },
      {
        id: 'A1-3-004',
        label: 'Procedimiento de revisión de actos administrativos',
        price: 65,
        description:
          'Redacción y revisión de un recurso en un procedimiento de revisión de actos administrativos',
      },
    ],
  },
  {
    id: 'A1-4',
    label: 'Ejecución',
    entrega:
      'Demanda en formato word, índice documental y documentos numerados.',
    doc_type: [
      {
        id: 'A1-4-001',
        label: 'Sentencia',
        price: 65,
        description: 'Redacción y revisión de una ejecución de sentencia',
      },
    ],
  },
  {
    id: 'A1-5',
    label: 'Tasación de costas',
    entrega:
      'Tasación de costas en formato word, minutas y criterios aplicados (en su caso).',
    doc_type: [
      {
        id: 'A1-5-001',
        label: 'Tasacion de costas',
        price: 30,
        description: 'Redacción y revisión de una tasación de costas',
      },
    ],
  },
  {
    id: 'A1-6',
    label: 'Liquidación de intereses',
    entrega:
      'Liquidación de intereses en formato word y documentos adjuntos (cálculos realizados).',
    doc_type: [
      {
        id: 'A1-6-001',
        label: 'Liquidacion de intereses',
        price: 30,
        description: 'Redacción y revisión de una liquidación de intereses',
      },
    ],
  },
  {
    id: 'A1-7',
    label: 'Contrato',
    entrega: 'Contrato en formato word y documentos adjuntos (en su caso).',
    doc_type: [
      {
        id: 'A1-7-001',
        label: 'Licitaciones administrativas',
        price: 150,
        description:
          'Redacción y revisión de un contrato de licitación administrativa',
      },
      {
        id: 'A1-7-002',
        label: 'Concesiones administrativas',
        price: 150,
        description:
          'Redacción y revisión de una licitación para una concesión administrativa',
      },
    ],
  },
]

const project_st_laboral = [
  {
    id: 'A3-1',
    label: 'Demanda-contestacion',
    entrega:
      'Demanda-contestación en formato word, índice documental y documentos numerados.',
    doc_type: [
      {
        id: 'A3-1-001',
        label: 'Despido',
        price: 95,
        description: 'Redacción y revisión de una demanda de despido',
      },
      {
        id: 'A3-1-002',
        label: 'Despido y cantidad',
        price: 95,
        description:
          'Redacción y revisión de una demanda de despido y cantidad',
      },
      {
        id: 'A3-1-003',
        label: 'Modificación sustancial de las condiciones de trabajo',
        price: 95,
        description:
          'Redacción y revisión de una demanda de modificacion sustancial de las condiciones de trabajo',
      },
      {
        id: 'A3-1-004',
        label: 'Sanciones disciplinarias',
        price: 95,
        description:
          'Redacción y revisión de una demanda de sanciones disciplinarias',
      },
      {
        id: 'A3-1-005',
        label: 'Papeleta conciliación',
        price: 95,
        description: 'Redacción y revisión de una papeleta de conciliación',
      },
    ],
  },
  {
    id: 'A3-2',
    label: 'Instructa',
    entrega: 'Instructa en formato word y documentos adjuntos (en su caso).',
    doc_type: [
      {
        id: 'A3-2-001',
        label: 'Instructa conciliación',
        price: 95,
        description: 'Redacción y revisión de una instructa de conciliación',
      },
      {
        id: 'A3-2-002',
        label: 'Instructa juicio primera instancia',
        price: 95,
        description:
          'Redacción y revisión de una instructa para juicio de primera instancia',
      },
    ],
  },
  {
    id: 'A3-3',
    label: 'Recurso',
    entrega: 'Recurso en formato word y documentos adjuntos (en su caso).',
    doc_type: [
      {
        id: 'A3-3-001',
        label: 'Suplicación',
        price: 95,
        description: 'Redacción y revisión de un recurso de suplicación',
      },
      {
        id: 'A3-3-002',
        label: 'Casación',
        price: 95,
        description: 'Redacción y revisión de un recurso de casación',
      },
    ],
  },
  {
    id: 'A3-4',
    label: 'Ejecución',
    entrega:
      'Demanda en formato word, índice documental y documentos numerados.',
    doc_type: [
      {
        id: 'A3-4-001',
        label: 'Sentencia',
        price: 95,
        description: 'Redacción y revisión de una ejecución de sentencia',
      },
    ],
  },
  {
    id: 'A3-5',
    label: 'Tasación de costas',
    entrega:
      'Tasación de costas en formato word, minutas y criterios aplicados (en su caso).',
    doc_type: [
      {
        id: 'A3-5-001',
        label: 'Tasación de costas',
        price: 95,
        description: 'Redacción y revisión de una tasación de costas',
      },
    ],
  },
  {
    id: 'A3-6',
    label: 'Liquidación de intereses',
    entrega:
      'Liquidación de intereses en formato word y documentos adjuntos (cálculos realizados).',
    doc_type: [
      {
        id: 'A3-6-001',
        label: 'Liquidación de intereses',
        price: 95,
        description: 'Redacción y revisión de una liquidación de intereses',
      },
    ],
  },
  {
    id: 'A3-7',
    label: 'Contrato',
    entrega: 'Contrato en formato word y documentos adjuntos (en su caso).',
    doc_type: [
      {
        id: 'A3-7-001',
        label: 'Contrato de trabajo',
        price: 95,
        description: 'Redacción y revisión de un contrato de trabajo',
      },
    ],
  },
]

const project_st_penal = [
  {
    id: 'A5-1',
    label: 'Demanda',
    entrega:
      'Escrito en formato word, índice documental y documentos numerados.',
    doc_type: [
      {
        id: 'A5-1-001',
        label: 'Querella',
        price: 95,
        description: 'Redacción y revisión de una querella',
      },
      {
        id: 'A5-1-002',
        label: 'Denuncia',
        price: 65,
        description: 'Redacción y revisión de una denuncia',
      },
    ],
  },
  {
    id: 'A5-2',
    label: 'Instructa',
    entrega: 'Instructa en formato word y documentos adjuntos (en su caso).',
    doc_type: [
      {
        id: 'A5-2-001',
        label: 'Declaraciones',
        price: 30,
        description: 'Redacción y revisión de unas declaraciones',
      },
      {
        id: 'A5-2-002',
        label: 'Juicio oral',
        price: 30,
        description: 'Redacción y revisión de una instructa para juicio oral',
      },
    ],
  },
  {
    id: 'A5-3',
    label: 'Recurso',
    entrega: 'Recurso en formato word y documentos adjuntos (en su caso).',
    doc_type: [
      {
        id: 'A5-3-001',
        label: 'Reforma',
        price: 65,
        description: 'Redacción y revisión de un recurso de reforma',
      },
      {
        id: 'A5-3-002',
        label: 'Apelación',
        price: 150,
        description: 'Redacción y revisión de un recurso de apelación',
      },
      {
        id: 'A5-3-003',
        label: 'Casación',
        price: 400,
        description: 'Redacción y revisión de un recurso de casación',
      },
    ],
  },
  {
    id: 'A5-4',
    label: 'Ejecución',
    entrega:
      'Demanda en formato word, índice documental y documentos numerados.',
    doc_type: [
      {
        id: 'A5-4-001',
        label: 'Sentencia',
        price: 75,
        description: 'Redacción y revisión de una ejecución de sentencia',
      },
    ],
  },
]

const project_st_type = {
  A1: project_st_administrativo,
  A2: project_st_civil,
  A3: project_st_laboral,
  A4: project_st_mercantil,
  A5: project_st_penal,
}

const durations = [
  { id: '0', label: 'Seleccione duración' },
  { id: '1', label: '1 semana' },
  { id: '2', label: '2 semanas' },
  { id: '3', label: '3 semanas' },
  { id: '4', label: '1 mes' },
]

const durationsLongTerm = [
  { id: '0', label: 'Seleccione duración', value: 0 },
  { id: '1', label: '1 semana', value: 1 },
  { id: '2', label: '2 semanas', value: 2 },
  { id: '3', label: '3 semanas', value: 3 },
  { id: '4', label: '1 mes', value: 4 },
  { id: '5', label: '2 meses', value: 8 },
  { id: '6', label: '3 meses', value: 12 },
]

const experience = [
  { id: '0', label: 'Seleccione experiencia', value: '' },
  { id: '1', label: 'Paralegal', value: 'paralegal' },
  { id: '2', label: 'Junior (1-3 años)', value: 'junior' },
  { id: '3', label: 'Mid-Senior (3-5 años)', value: 'midSenior' },
  { id: '4', label: 'Senior (5+ años)', value: 'senior' },
]

const workTypes = [
  { id: '0', label: 'Selecciona la modalidad de trabajo', value: '' },
  { id: '1', label: 'Presencial', value: 'onSite' },
  { id: '2', label: 'Remoto', value: 'remote' },
  { id: '3', label: 'Híbrido', value: 'hybrid' },
]
const journeies = [
  { id: '0', label: 'Seleccione jornada', value: '' },
  { id: '1', label: 'Media jornada', value: 'partTime' },
  { id: '2', label: 'Jornada completa', value: 'fullTime' },
]

const assumptionOptions = [
  { id: 'contestación', label: 'contestación' },
  { id: 'allanamiento', label: 'allanamiento' },
]

module.exports = {
  substituteSkills,
  languagesOptions,
  assignmentsOptions,
  quantity,
  project_st_type,
  durations,
  durationsLongTerm,
  experience,
  workTypes,
  journeies,
  assumptionOptions,
}
