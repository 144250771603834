import React from 'react'
import { Button, CardBody, FormGroup, Row, Col } from 'reactstrap'
import { toast } from 'react-toastify'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { judicialParties } from '../../services/judicialParties'
import { hearingTypeList } from '../../services/hearingType'
import { withRouter } from 'react-router-dom'
import Select from 'react-select'
import { gtmEvent } from '../../services/gtm-handler'

const JudicialParty = ({
  inputStyle,
  setExpedient,
  expedient,
  history,
  location,
}) => {
  if (localStorage.getItem('token')) {
    history.push('/panel/expedients')
  }

  const onChangeJudicialParty = async (e) => {
    if (!e) {
      return
    }
    setExpedient({
      ...expedient,
      judicial_party: e.value,
      judicialPartyValue: e.label,
    })
  }

  const validateCheckout = async (e, errors, values) => {
    if (errors.length > 0) {
      return toast.error('Por favor rellena los campos correctamente')
    }
    if (
      !values.hearing_type ||
      values.hearing_type === 'Seleccionar tipo de encargo'
    ) {
      return toast.error('Por favor selecciona el tipo de encargo')
    }

    if (
      !expedient.judicial_party ||
      expedient.judicial_party === 'Seleccionar partido judicial'
    ) {
      return toast.error('Por favor selecciona el partido judicial')
    }

    gtmEvent({
      ecommerce: {
        currencyCode: 'EUR',
        detail: {
          products: [
            {
              id: 'VP-L',
              name: 'Vista prepagada form landing',
              price: '95',
              quantity: 1,
            },
          ],
        },
      },
    })

    gtmEvent({
      event: 'begin_checkout',
      ecommerce: {
        items: [
          {
            item_name: 'Vista prepagada form landing', // Name o ID requerido.
            item_id: 'VP-L',
            price: '95',
            currency: 'EUR',
            quantity: 1,
          },
        ],
      },
    })

    history.push(`/create-expedient-form/time${location.search}`)
  }

  return (
    <CardBody>
      <div className="d-flex justify-content-md-center align-items-md-center">
        <h2 style={{ lineHeight: '1.5' }} className="col-md-6 text-center">
          ¡Bienvenid@! Dinos el partido judicial donde necesitas al abogado
          colaborador y el tipo de encargo
        </h2>
      </div>
      <AvForm onSubmit={validateCheckout}>
        <Row className="d-flex justify-content-center align-items-center">
          <FormGroup className="col-md-3 d-md-flex justify-content-end m-md-4 mt-4 mb-n2">
            <Col md="10">
              <Select
                isClearable
                className="react-select-container"
                options={judicialParties.map((i) => ({
                  value: i.id,
                  label: i.name,
                }))}
                onChange={onChangeJudicialParty}
                placeholder="Seleccione el partido judicial"
                value={
                  expedient.judicial_party
                    ? [
                        {
                          value: expedient.judicial_party,
                          label: expedient.judicialPartyValue,
                        },
                      ]
                    : false
                }
              />
            </Col>
          </FormGroup>

          <FormGroup className="col-md-3 d-md-flex justify-content-start">
            <Col md="10">
              <AvField
                style={inputStyle}
                type="select"
                name="hearing_type"
                onChange={(event, data) => {
                  const hearingTypeId = hearingTypeList.find(
                    (type) => type.value === data
                  ).id
                  const hearingTypeValue = hearingTypeList.find(
                    (type) => type.value === data
                  ).value
                  setExpedient({
                    ...expedient,
                    hearing_type: hearingTypeId,
                    hearingTypeValue,
                  })
                }}
                value={expedient.hearingTypeValue}
              >
                <option>Seleccionar tipo de encargo</option>
                {hearingTypeList.map((t) => (
                  <option key={t.id}>{t.value}</option>
                ))}
              </AvField>
            </Col>
          </FormGroup>
        </Row>
        <div className="d-flex flex-md-row flex-column justify-content-md-center align-items-md-center">
          <Button color="primary" size="lg">
            Siguiente
          </Button>
        </div>
      </AvForm>
    </CardBody>
  )
}

export default withRouter(JudicialParty)
