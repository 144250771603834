import React, { useState, useEffect } from 'react'
import { Button, CardBody, Label } from 'reactstrap'
import { AvForm, AvInput, AvGroup } from 'availity-reactstrap-validation'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { createClientForm, loadSubstitutes } from '../../redux/actions/users'
import { loginForm } from '../../redux/actions/auth'
import { toast } from 'react-toastify'
import Loading from '../../components/common/Loading'
import queryString from 'querystring'
import { gtmEvent } from '../../services/gtm-handler'

const PayingInfo = ({
  user,
  expedient,
  history,
  createClientForm,
  setExpedient,
  setUser,
  loadSubstitutes,
  loginForm,
  location,
}) => {
  const [substitute_id, setSubstitute_id] = useState(false)
  const [loading, setLoading] = useState(false)
  const [substituteNumber, setSubstituteNumber] = useState(0)
  const [password, setPassword] = useState(false)

  useEffect(() => {
    const getSubs = async () => {
      await substituteHandler()
    }
    setSubstituteNumber(Math.floor(Math.random() * (12 - 3)) + 3)
    //Force Cognito validation password
    setPassword('Aa1_' + Math.random().toString(36).substring(2, 15))
    getSubs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (
    !expedient.judicial_party ||
    expedient.hearing_type < 0 ||
    !expedient.hearing_date ||
    !expedient.dificulty ||
    !expedient.court ||
    !expedient.resume ||
    !user.name ||
    !user.last_name
  ) {
    history.push('/create-expedient-form/name')
  }

  const substituteHandler = async () => {
    const substitute_idRes = await loadSubstitutes({
      party_id: expedient.judicial_party,
    })
      .then(async (res) => {
        return res.payload[0]._id
      })
      .catch((err) => {
        return false
      })

    setSubstitute_id(substitute_idRes)
  }

  const checkoutIntent = async () => {
    const searchParams = queryString.parse(location.search.replace('?', ''))

    if (
      searchParams &&
      (searchParams.utm_source || searchParams.utm_campaign)
    ) {
      user['utm_source'] = searchParams.utm_source
        ? searchParams.utm_source
        : ''
      user['utm_campaign'] = searchParams.utm_campaign
        ? searchParams.utm_campaign
        : ''
    }

    const response = await createClientForm({
      user: { ...user, password, role: 'client' },
    }).then(async (res) => {
      setUser({ ...res.payload.user })
      // const { _id, name, last_name, email, created_at } = res.payload.user
      // action.identify(_id);
      // action.people.set({
      //   $first_name: name,
      //   $last_name: last_name,
      //   $email: email,
      //   $created_at: created_at,
      //   $role: 'client',
      //   $is_substitute: false,
      //   $source: source || 'Organic'
      // })
      // action.track('ClientSignUp')

      await loginForm({ ...res.payload.user, password })
      return res
    })

    if (!response || !response.payload.user) {
      return history.push('/login')
    }

    setExpedient({
      ...expedient,
      substitute_id,
      status: 'awaiting_acceptation',
      creator_id: response.payload.user._id,
    })

    gtmEvent({
      event: 'checkout',
      ecommerce: {
        currencyCode: 'EUR',
        checkout: {
          actionField: { step: 1 },
          products: [
            {
              id: 'VP-L',
              name: 'Vista prepagada form landing',
              price: '95',
              quantity: 1,
            },
          ],
        },
      },
    })

    gtmEvent({
      event: 'begin_checkout',
      ecommerce: {
        items: [
          {
            item_name: 'Vista prepagada form landing', // Name o ID requerido.
            item_id: 'VP-L',
            price: '95',
            currency: 'EUR',
            quantity: 1,
          },
        ],
      },
    })

    history.push('/create-expedient-form/paying-process')
  }

  const submit = async (e, errors, values) => {
    if (errors.length > 0 || values.password === '') {
      return toast.error('Por favor lea y acepte las condiciones')
    }
    setLoading(true)
    return checkoutIntent()
  }

  return (
    <CardBody>
      <Loading loading={loading} />
      <div className="d-flex justify-content-center align-items-center flex-column">
        <h2 style={{ lineHeight: '1.5' }} className="col-lg-6 text-center">
          Ya está todo listo. Tenemos a {substituteNumber} abogados sustitutos
          disponibles en {expedient.judicialPartyValue}. Date prisa porque es
          probable que no estén disponibles por mucho tiempo.
        </h2>
        <h2 style={{ lineHeight: '1.5' }} className="col-lg-6 text-center">
          Reserva ya y asegúrate que uno de ellos acuda a tu encargo.
        </h2>
        <h2 style={{ lineHeight: '1.5' }} className="col-lg-6 text-center">
          Completa la forma de pago para finalizar tu solicitud.
        </h2>
      </div>
      <AvForm onSubmit={submit}>
        <AvGroup className="mt-4 mb-3 text-center" check>
          <AvInput type="checkbox" name="checkbox" required />
          <Label check for="checkbox">
            Manifiestas haber leído y aceptas nuestra{' '}
            <a
              href="https://legalpigeon.com/politica-de-privacidad-2/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Política de privacidad
            </a>{' '}
            y las{' '}
            <a
              href="https://legalpigeon.com/condiciones-generales-de-uso-y-contratacion/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Condiciones generales
            </a>
          </Label>
        </AvGroup>
        <div className="d-flex flex-md-row flex-column justify-content-md-center align-items-md-center">
          <Button color="success" size="lg">
            RESERVA YA
          </Button>
        </div>
      </AvForm>
    </CardBody>
  )
}

export default withRouter(
  connect(null, { createClientForm, loadSubstitutes, loginForm })(PayingInfo)
)
