import React, { useState } from 'react'
import ButtonNewAssigment from './button'
import { Modal } from 'reactstrap'
import CreateNewAssigment from './createNewAssigment'
import { Button } from 'reactstrap'

const CreateNewAssigmentModal = () => {
  const [showModal, setShowModal] = useState(false)

  const handleButtonClick = () => {
    setShowModal(true)
  }

  return (
    <div>
      <ButtonNewAssigment open={handleButtonClick}>
        Open Modal
      </ButtonNewAssigment>
      <Modal
        style={{
          maxWidth: '900px',
          height: '800px',
        }}
        isOpen={showModal}
        key={showModal}
        toggle={() => setShowModal(false)}
      >
        <div cssModule={{ 'modal-title': 'w-100 ' }} className="m-3  pb-1">
          <div className="d-flex justify-content-between">
            <div className="m-3 h2 d-inline border-0">
              ¿En que te podemos ayudar?
            </div>
            <div className="flex align-items-end justify-content-end pb-1 mr-2 ">
              <Button
                type="button"
                color="outline-danger"
                className="border-0"
                onClick={() => setShowModal(false)}
              >
                {' x '}
              </Button>
            </div>
          </div>
          <CreateNewAssigment closeModal={() => setShowModal(false)} />
        </div>
      </Modal>
    </div>
  )
}

export default CreateNewAssigmentModal
