import React,{useState} from 'react';
import { Button,CardBody, FormGroup, Row } from "reactstrap";
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { toast } from 'react-toastify'
import { withRouter } from 'react-router-dom'
import { PasswordEye } from '../../components/PasswordEye';

const Contact = ({ inputStyle, setUser, user, history, location }) => {

  const [showPassword, setShowPassword] = useState(false)

  const handleOnHover = () => {
    setShowPassword(!showPassword)
  }

  if (
    !user.name ||
    !user.last_name ||
    !user.phone ||
    !user.email
  ) {
    history.push(`/create-user-form/name${location.search}`)
  }

  const validateCheckout = async (e, errors, values) => {
    if (errors.length > 0) {
      return toast.error('Por favor rellena los campos correctamente')
    }

    if (!values.password) {
      return toast.error('Por favor introduce una contraseña')
    }

    setUser({ ...user, ...values, password2: values.password, checkbox: true })

    return history.push(`/create-user-form/choose${location.search}`)
  }

  return (
    <CardBody>
      <div className='d-flex justify-content-center align-items-center'>
        <h2 style={{ lineHeight: '1.5' }} className='col-md-6 text-center'>Crea una contraseña para poder acceder a la aplicación.</h2>
      </div>
      <AvForm onSubmit={validateCheckout}>
        <Row className='d-flex justify-content-center align-items-center'>
          <FormGroup className='col-md-3 d-md-flex justify-content-center mt-md-4 mt-n4 text-bottom'>
            <AvField
              name='password'
              bsSize='lg'
              placeholder='Contraseña'
              value={user.password}
              type={showPassword ? 'text' : 'password'}
              validate={{
                pattern: {value: "/^(?=.*\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9])\\S{8,}$/g"},
                minLength: {value: 8}
              }}
              errorMessage="Mínimo 8 caracteres, 1 mayúscula, 1 minúscula, 1 número, 1 carácter especial"
              style={{inputStyle, borderRight: 'none', borderBottomRightRadius:'0px', borderTopRightRadius:'0px'}}
            />
            <PasswordEye 
              showPassword={setShowPassword(!showPassword)}
              handleOnHover={handleOnHover}
            />
          </FormGroup>
        </Row>
        <div className='d-flex flex-md-row flex-column justify-content-md-center align-items-md-center'>
          <Button color="primary" size='lg'>Siguiente</Button>
        </div>
        <div className='d-flex mt-4 justify-content-center align-items-center'>
        <p>* Al registrarte manifiestas haber leído y aceptas nuestra <a href='https://legalpigeon.com/politica-de-privacidad-2/' target='_blank' rel='noopener noreferrer'>Política de privacidad</a> y las <a href='https://legalpigeon.com/condiciones-generales-de-uso-y-contratacion/' target='_blank' rel='noopener noreferrer'>Condiciones generales</a></p>
        </div>
      </AvForm>
    </CardBody>
  )
}

export default withRouter(Contact)