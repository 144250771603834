import React from 'react'
import { Button, CardBody, FormGroup, Row } from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'

const Information = ({
  inputStyle,
  setExpedient,
  expedient,
  history,
  location,
}) => {
  if (
    !expedient.judicial_party ||
    expedient.hearing_type < 0 ||
    !expedient.hearing_date
  ) {
    history.push(`/create-expedient-form/time${location.search}`)
  }

  const validateCheckout = async (e, errors, values) => {
    if (errors.length > 0) {
      return toast.error('Por favor rellena los campos correctamente')
    }
    if (
      !values.dificulty ||
      values.dificulty === 'Seleccionar tipo de encargo'
    ) {
      return toast.error('Por favor selecciona el tipo de encargo')
    }

    if (!values.court) {
      return toast.error('Por favor introduce una referencia interna')
    }

    if (!values.resume) {
      return toast.error('Por favor, explica sobre qué asunto trata el encargo')
    }

    setExpedient({
      ...expedient,
      ...values,
      valueDificulty: values.dificulty,
      dificulty: values.dificulty.split(' ')[0] * 1,
    })

    return history.push(`/create-expedient-form/name${location.search}`)
  }

  return (
    <CardBody>
      <div className="d-flex justify-content-center align-items-center">
        <h2 style={{ lineHeight: '1.5' }} className="col-md-6 text-center">
          Ahora necesitamos un poco más de información sobre el encargo...
        </h2>
      </div>

      <AvForm onSubmit={validateCheckout}>
        <Row className="d-flex justify-content-center align-items-center">
          <FormGroup className="col-md-3 d-md-flex justify-content-end m-md-4">
            <AvField
              style={inputStyle}
              type="select"
              name="dificulty"
              value={expedient.valueDificulty}
            >
              <option>Seleccionar dificultad</option>
              <option>1 muy fácil</option>
              <option>2 fácil</option>
              <option>3 normal</option>
              <option>4 difícil</option>
              <option>5 muy difícil</option>
            </AvField>
          </FormGroup>

          <FormGroup className="col-md-3 d-md-flex justify-content-start m-md-4 mt-n4">
            <AvField
              style={inputStyle}
              name="court"
              placeholder="Número de juzgado"
              value={expedient.court}
            />
          </FormGroup>
        </Row>
        <Row className="d-md-flex justify-content-center align-items-center">
          <FormGroup className="col-md-4 ">
            <AvField
              type="textarea"
              name="resume"
              placeholder="Explica brevemente de qué trata el asunto"
              value={expedient.resume}
              required
              errorMessage="Explica brevemente de qué trata el asunto"
            />
          </FormGroup>
        </Row>
        <div className="d-flex flex-md-row flex-column justify-content-md-center align-items-md-center">
          <Button color="primary" size="lg">
            Siguiente
          </Button>
        </div>
      </AvForm>
    </CardBody>
  )
}

export default withRouter(Information)
