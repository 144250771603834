import React, {useState} from 'react'
import {
  Button,
  CardBody,
  FormGroup,
  Row,
  Col
} from 'reactstrap'
import { toast } from 'react-toastify'
import { AvForm } from 'availity-reactstrap-validation'
import { substituteSkills } from '../../services/substituteSkills'
import { withRouter } from 'react-router-dom'
import Select from 'react-select'
import { connect } from 'react-redux'
import { createClientForm } from '../../redux/actions/users'
import { loginForm } from '../../redux/actions/auth'
import Loading from '../../components/common/Loading'
import action from '../../mixPanelsActions'
import { restartFormIfMissingValues } from './SkillsHandlers'
import queryString from 'querystring'

const Skills = ({ setUser, user, history, createClientForm, loginForm, location }) => {
  restartFormIfMissingValues(history, user)

  const [skills, setSkills] = useState([])
  const [loading, setLoading] = useState(false)

  const handleSkills = skills => {
    const substituteSkills = skills ? skills.map(skill => skill.value) : null
    setUser({ ...user, substitute_skills: substituteSkills })
    setSkills(skills)
  }

  const validate = async (e, errors, values) => {
    if (!user.substitute_skills || user.substitute_skills === 'Seleccionar sus especialidades') {
      return toast.error('Por favor selecciona tus especialidades')
    }

    const maxSkillsLength = 5

    if (skills.length > maxSkillsLength) {
      return toast.error('Ha sobrepasado el máximo de especialidades')
    }

    setLoading(true)
    const source = queryString.parse(location.search.replace('?', '')).source
    const res = await createClientForm({ user: { ...user, source}})

    if(!res || !res.payload.user) {
      toast.error('Este email ya esta asociado a un usuario, si es tu email haz login')
      history.push('/login')
    } else {
      const { _id, name, last_name, email, created_at } = res.payload.user
      action.identify(_id)
      action.people.set({
        $first_name: name,
        $last_name: last_name,
        $email: email,
        $created_at: created_at,
        $role:'substitute',
        $is_substitute: true,
        $source: source
      })
      action.track('SubstituteSignUp')

      await loginForm({ ...res.payload.user, password: user.password })
      history.push('/thanks-substitute')
    }

    setLoading(false)
  }

  return (
    <CardBody>
      <Loading loading={loading} />
      <div className='d-flex justify-content-md-center align-items-md-center'>
        <h2 style={{lineHeight: '1.5'}} className='col-md-6 text-center'>Por último, dinos tus especialidades.<br/>
        <span><br/><p style={{ fontSize: '14px' }}>Especialidades (multiselección, máx. 5)</p></span>
        </h2>
      </div>
      <AvForm onSubmit={validate} >
        <Row className='d-flex justify-content-center align-items-center'>
          <FormGroup className='col-md-3 d-md-flex justify-content-center m-md-4 mt-4 mb-n2'>
            <Col md='10'>
              <Select
                placeholder='Seleccione sus especialidades'
                name='substitute_skills'
                className="react-select-container mb-4"
                classNamePrefix="react-select"
                options={substituteSkills.map(i => ({ value: i.id, label: i.name }))}
                isMulti
                onChange={e => handleSkills(e)}
                defaultValue={skills}
                key={`sk-${skills ? skills.length : 0}`}
              />
            </Col>
          </FormGroup>
        </Row>
        <div className='d-flex flex-md-row flex-column justify-content-md-center align-items-md-center'>
          <Button color='primary' size='lg' disabled={skills.length === 0}>Siguiente</Button>
        </div>
      </AvForm>
    </CardBody>
  )
}

export default withRouter(connect(null, { createClientForm, loginForm })(Skills))
