import React from 'react'
import logoImg from '../../assets/images/2.png'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPersonWalking,
  faGavel,
  faHandshakeSimple,
  faFileSignature,
} from '@fortawesome/free-solid-svg-icons'
import { ENABLE_PIGEON_IN_HOUSE } from '../../config'

const options = [
  {
    title: 'Pigeon substitute',
    content:
      'Contrata un letrado para representarte en cualquier parte de España, en actuaciones judiciales y extrajudiciales, o para cualquier otra actuación que necesites!',
    fontAwesome: faGavel,
    to: '/panel/hearing',
    show: true,
    isNew: false,
    isCommingSoon: false,
  },
  {
    title: 'Pigeon procura',
    content:
      'Contamos con procuradores listos para ofrecerte una sustitución eficiente y profesional, asegurando que cada actuación judicial sea manejada con la máxima diligencia y expertise.',
    fontAwesome: faFileSignature,
    to: '/panel/new-procura',
    show: true,
    isNew: new Date() < new Date('2024-12-31'),
    isCommingSoon: false,
    disabled: false,
  },
  {
    title: 'Pigeon on call',
    content:
      'Te ayudamos a confeccionar y revisar escritos judiciales. Nuestra red de abogados especializados está disponible para apoyar a tu organización en la redacción y revisión de documentos legales con la máxima precisión y profesionalismo.',
    fontAwesome: faHandshakeSimple,
    to: '/panel/project-short-term',
    show: true,
    isNew: new Date() < new Date('2024-12-31'),
    isCommingSoon: false,
  },
  {
    title: 'Pigeon in House',
    subtitle:
      '¿Necesitas un abogado dedicado exclusivamente a ti por más de 15 días? ',
    content:
      'Asegura la disponibilidad constante de un abogado adaptado a tus requerimientos para respaldarte a lo largo del tiempo que necesites.',
    fontAwesome: faPersonWalking,
    to: '/panel/new-pigeon-inhouse',
    show: ENABLE_PIGEON_IN_HOUSE === 'true',
    isNew: false,
    isCommingSoon: !ENABLE_PIGEON_IN_HOUSE === 'true',
    disabled: !ENABLE_PIGEON_IN_HOUSE === 'true',
  },
]

const createNewAssigment = ({ closeModal }) => {
  return (
    <div className="d-flex justify-content-center align-items-start mx-4 ">
      <div className="d-flex flex-column " style={{ width: '500px' }}>
        {options.map((option) => (
          <Link
            className={`card btn btn-pill btn-outline-primary border border-primary rounded d-flex justify-content-start ${
              !option.show || option.disabled ? 'disabled' : ''
            }`}
            key={option.title}
            to={option.to}
            onClick={() => {
              closeModal()
            }}
          >
            <div
              className="d-flex align-items-center"
              style={{ position: 'relative' }}
            >
              {option.isNew && (
                <h4
                  style={{
                    position: 'absolute',
                    top: '0',
                    right: '-20px',
                    transform: 'rotate(45deg)',
                    backgroundColor: 'white',
                    border: '2px solid green',
                    borderRadius: '10px',
                    padding: '3px',
                    color: 'green',
                    fontWeight: 'bold',
                  }}
                >
                  New!!
                </h4>
              )}
              {option.isCommingSoon && (
                <h4
                  style={{
                    position: 'absolute',
                    right: '-26px',
                    top: '0px',
                    width: '90px',
                    transform: 'rotate(45deg)',
                    backgroundColor: 'white',
                    border: '2px solid black',
                    borderRadius: '10px',
                    padding: '3px',
                    color: 'black',
                    fontWeight: 'bold',
                  }}
                >
                  Comming soon!
                </h4>
              )}

              <FontAwesomeIcon
                className="ml-3"
                size="2x"
                icon={option.fontAwesome}
              />
              <div>
                <div className="card-body ">
                  <h3
                    style={
                      option.show
                        ? {
                            color: 'inherit',
                            textAlign: 'left',
                          }
                        : { color: 'transparent', textShadow: '0 0 20px #000' }
                    }
                  >
                    <strong>{option.title}</strong>
                  </h3>
                  <p
                    className="mb-1 strong"
                    style={
                      option.show
                        ? {
                            color: 'inherit',
                            textAlign: 'left',
                          }
                        : { color: 'transparent', textShadow: '0 0 20px #000' }
                    }
                  >
                    <strong>{option.subtitle}</strong>
                  </p>
                  <p
                    className="mb-0"
                    style={
                      option.show
                        ? {
                            color: 'inherit',
                            textAlign: 'left',
                          }
                        : { color: 'transparent', textShadow: '0 0 20px #000' }
                    }
                  >
                    {option.content}
                  </p>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
      {window.innerWidth > 716 && (
        <div
          className="d-flex justify-content-center mx-3"
          style={{ maxWidth: '40%' }}
        >
          <img src={logoImg} alt="logo" className="img-fluid" />
        </div>
      )}
    </div>
  )
}

export default createNewAssigment
