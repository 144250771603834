import * as types from '../constants'
import { toast } from 'react-toastify'

const defaultState = {
  created: {},
  issue: {},
}

export default function (state = defaultState, action) {
  switch (action.type) {
    case types.GET_ISSUE_SUCCESS:
      return {
        ...state,
        issue: action.payload,
      }

    case types.GET_ISSUE_ERROR:
      toast.error('Ha ocurrido algún error')
      return state

    case types.CREATE_ISSUE_SUCCESS:
      toast.success('Incidencia creada')

      return {
        ...state,
        created: action.payload.issue,
      }

    case types.CREATE_ISSUE_ERROR:
      toast.error('Ha ocurrido algún error')
      return state

    case types.UPDATE_ISSUE_SUCCESS:
      toast.success('Incidencia actualizada')
      return {
        ...state,
        issue: action.payload.issue,
      }

    case types.UPDATE_ISSUE_ERROR:
      toast.error('Ha ocurrido algún error')
      return state

    default:
      return state
  }
}
