import React from "react";
import { Provider } from "react-redux"

import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes/routes"
import { ToastContainer, Slide } from 'react-toastify'

import configureStore from "./redux/store/index"

const store = configureStore()

const RoutesContainer = () => {
  return <Routes />
}

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <RoutesContainer />
      </Router>
      <ToastContainer transition={Slide} />
    </Provider>
)}

export default App
