import React from 'react'
import { connect } from 'react-redux'
import { Button, Form, FormGroup, Label, Input, InputGroup } from 'reactstrap'
import { login } from '../../redux/actions/auth'
import { Link, withRouter } from 'react-router-dom'
import { PasswordEye } from '../PasswordEye'

class LoginForm extends React.Component {
  state = {
    showPassword: false,
  }

  login = (e) => {
    e.preventDefault()
    const { email, password } = this
    this.props.login({ email: email.value, password: password.value })
    this.props.history.push('/panel')
  }

  handleOnHover = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  render() {
    return (
      <Form onSubmit={this.login}>
        <FormGroup>
          <Label>Email</Label>
          <Input
            innerRef={(val) => (this.email = val)}
            bsSize="lg"
            type="email"
            placeholder="Email"
          />
        </FormGroup>
        <FormGroup>
          <Label>Contraseña</Label>
          <InputGroup className="text-bottom">
            <Input
              innerRef={(val) => (this.password = val)}
              bsSize="lg"
              type={!this.state.showPassword ? 'password' : 'text'}
              placeholder="Contraseña"
              style={{ borderRight: 'none' }}
            />
            <PasswordEye
              showPassword={this.state.showPassword}
              handleOnHover={this.handleOnHover}
            />
          </InputGroup>
          {this.props.showAwsCognitoInfo && (
            <small>
              {' '}
              <Link to="/forgot-password">
                ¿Has olvidado la contraseña?
              </Link>{' '}
            </small>
          )}
        </FormGroup>
        <div className="d-flex justify-content-between">
          <Button color="primary" className="w-100">
            Entrar
          </Button>
        </div>

        <div className="mt-5">
          <small>
            ¿Aún no tienes cuenta? Regístrate gratis ahora como
            <Link
              to={`/client-signup${this.props.location.search}`}
              style={{ color: 'var(--secondary)' }}
            >
              {' '}
              cliente{' '}
            </Link>{' '}
            o
            <Link
              to={`/substitute-signup${this.props.location.search}`}
              style={{ color: 'var(--secondary)' }}
            >
              {' '}
              sustituto{' '}
            </Link>
          </small>
        </div>
      </Form>
    )
  }
}

export default withRouter(connect(null, { login })(LoginForm))
