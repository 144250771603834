import mixpanel from 'mixpanel-browser'
import { MIXPANEL_TOKEN } from './config'

if (MIXPANEL_TOKEN) {
  mixpanel.init(
    MIXPANEL_TOKEN,
    { api_host: 'https://api-eu.mixpanel.com', debug: true },
    ''
  )
}

let actions = {
  identify: (id) => {
    if (MIXPANEL_TOKEN) mixpanel.identify(id)
    else console.log('mixpanel idenity', id)
  },
  alias: (id) => {
    if (MIXPANEL_TOKEN) mixpanel.alias(id)
    else console.log('mixpanel alias', id)
  },
  track: (name, props) => {
    if (MIXPANEL_TOKEN) mixpanel.track(name, props)
    else console.log('mixpanel track', name, props)
  },
  people: {
    set: (props) => {
      if (MIXPANEL_TOKEN) mixpanel.people.set(props)
      else console.log('mixpanel set', props)
    },
  },
}

export default actions
