import React, { useState } from 'react';
import { Collapse, Navbar, Nav, UncontrolledDropdown} from "reactstrap";
import { RotateCcw } from 'react-feather'
import logo from '../../assets/images/logo.png'
import { useParams } from "react-router-dom";
import JudicialParty from './JudicialParty'
import Time from './Time'
import Information from './Information'
import Name from './Name'
import Contact from './Contact'
import Charging from './Charging'
import PayingInfo from './PayingInfo'
import PayingProcess from './PayingProcess'
import LastStep from './LastStep';
import { withRouter } from 'react-router-dom'

const inputStyle = { height: '35px', marginTop: '30px' }

const CreateExpedientForm = ({ location }) => {
  const { stepParam } = useParams();
  const [expedient, setExpedient] = useState({})
  const [user, setUser] = useState({})

  const reset = () => {
    setExpedient({})
    setUser({})
  }

  return (
    <div>
      <Navbar color="white" light expand>
        <Collapse className='mr-5' navbar>
          <Nav navbar>
            <UncontrolledDropdown nav inNavbar>
              <a href='https://legalpigeon.com/'>
                <img
                  src={logo}
                  alt="legaltechies logo"
                  className="img-fluid"
                  width="132"
                  height="132"
                />
              </a>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
        <div className="ml-auto d-flex align-items-end" >
          <Collapse className='mr-5' navbar>
            <Nav navbar>
              <UncontrolledDropdown
                style={{ cursor: 'pointer' }}
                onClick={reset}
                nav inNavbar
              >
                <RotateCcw size={18} />
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </div>
      </Navbar>

      <div className='d-flex justify-content-center align-items-center' style={{ minHeight: '60vh' }}>

        { stepParam === 'judicial-party' &&
          <JudicialParty
            inputStyle={inputStyle}
            setExpedient={setExpedient}
            expedient={expedient}
            user={user}
          />
        }

        { stepParam === 'time' &&
          <Time
            inputStyle={inputStyle}
            setExpedient={setExpedient}
            expedient={expedient}
          />
        }

        { stepParam === 'information' &&
          <Information
            inputStyle={inputStyle}
            setExpedient={setExpedient}
            expedient={expedient}
          />
        }

        { stepParam === 'name' &&
          <Name
            inputStyle={inputStyle}
            setUser={setUser}
            user={user}
            expedient={expedient}
          />
        }

        { stepParam === 'contact' &&
          <Contact
            inputStyle={inputStyle}
            setUser={setUser}
            user={user}
            expedient={expedient}
          />
        }

        { stepParam === 'charging' &&
          <Charging
            expedient={expedient}
            user={user}
          />
        }

        { stepParam === 'paying-info' &&
          <PayingInfo
            expedient={expedient}
            user={user}
            setExpedient={setExpedient}
            setUser={setUser}
          />
        }

        { stepParam === 'paying-process' &&
          <PayingProcess
            expedient={expedient}
            user={user}
            setExpedient={setExpedient}
            setUser={setUser}
          />
        }

        { stepParam === 'last-step' &&
          <LastStep
            expedient={expedient}
            user={user}
          />
        }

      </div>
    </div>
  )
}


export default withRouter(CreateExpedientForm)