import React from 'react'
import PageLayout from '../layouts/Page'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import { judicialParties } from '../services/judicialParties'
import Select from 'react-select'
import { connect } from 'react-redux'
import { loadSubstitutes } from '../redux/actions/users'
import queryString from 'query-string'
import ExpedientWizard from '../components/ExpedientWizard'
import PigeonImg from '../components/common/PigeonImg'
import action from '../mixPanelsActions'
import { gtmEvent } from '../services/gtm-handler'

class Landing extends React.Component {
  state = {
    searched: false,
    party: {},
    utm_source: null,
    utm_campaign: null,
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.search()
    }
  }

  componentDidMount() {
    const { search } = this.props.location
    const searchParams = queryString.parse(search)

    this.setState({
      utm_source: searchParams.utm_source,
      utm_campaign: searchParams.utm_campaign,
    })

    if (searchParams.party) {
      this.search()
    }
  }

  search = () => {
    const { search } = this.props.location
    const searchParams = queryString.parse(search)
    let party = judicialParties.find((p) => p.id === searchParams.party * 1)
    party = party ? { value: party.id, label: party.name } : {}

    this.setState({ searched: true, party })
    this.props.loadSubstitutes({ party_id: party.value })
  }

  onClick = (s, nextBaseUrl, activeUser) => {
    const { party, utm_source, utm_campaign } = this.state

    if (activeUser && activeUser._id) {
      const { _id } = activeUser
      action.identify(_id)
      action.track('NewHearing', {
        flow_type: 'landing page/old flow',
      })
    } else {
      action.track('CuriousNewHearing')
    }

    gtmEvent({
      ecommerce: {
        currencyCode: 'EUR',
        detail: {
          products: [
            {
              id: 'VA',
              name: 'Vista no prepagada flow antiguo',
              price: '95',
              quantity: 1,
            },
          ],
        },
      },
    })

    gtmEvent({
      event: 'begin_checkout',
      ecommerce: {
        items: [
          {
            item_name: 'Vista no prepagada flow antiguo', // Name o ID requerido.
            item_id: 'VA',
            price: '95',
            currency: 'EUR',
            quantity: 1,
          },
        ],
      },
    })

    const utmsource = utm_source ? `utm_source=${utm_source}&` : ''
    const utmcampaign = utm_source ? `utm_campaign=${utm_campaign}&` : ''

    this.props.history.push(
      `/${nextBaseUrl}?${utmsource}${utmcampaign}party=${party.value}&party_label=${party.label}&substitute=${s._id}&name=${s.name}`
    )
  }

  render() {
    const { auth, users } = this.props
    const { substitutes } = users
    const { searched, party, utm_campaign, utm_source } = this.state

    const nextBaseUrl = auth.activeUser._id
      ? 'create-expedient'
      : 'create-client-expedient'

    if (!party) return <div />

    return (
      <PageLayout className="text-center" lightHeader>
        <h1 className="text-center mt-3 mb-4">
          Encuentra a un abogado colaborador
        </h1>
        <Card className="landing">
          <CardHeader>
            <ExpedientWizard step={1} activeUser={this.props.auth.activeUser} />
          </CardHeader>
          <CardBody>
            <p className="lead mt-4 mb-3">Buscar por partido judicial:</p>
            <Row className="mb-4 mt-3">
              <Col md="6" className="offset-md-3">
                <Select
                  key={party.value}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={judicialParties.map((i) => ({
                    value: i.id,
                    label: i.name,
                  }))}
                  onChange={(party) =>
                    this.props.history.push(
                      `/substitute-list?utm_source=${utm_source}&utm_campaign=${utm_campaign}&party=${party.value}`
                    )
                  }
                  placeholder="Ex: Barcelona..."
                  defaultValue={party.value ? party : null}
                />
              </Col>
            </Row>

            <section className={`pt-4 pb-4 ${searched ? '' : 'hide'}`}>
              <h4 className="blue2">Abogados disponibles:</h4>
              {substitutes.length === 0 ? (
                <p>Sin resultados</p>
              ) : (
                <div className="mt-4">
                  {substitutes.map((s) => {
                    const skillsAreas =
                      s.skills.filter((sk) => !sk.value.includes('-')) || []

                    const skillsSpecialities =
                      s.skills.filter((sk) => sk.value.includes('-')) || []

                    return (
                      <Col
                        md="10"
                        className="mb-2 offset-md-1"
                        key={`subs-${s._id}`}
                      >
                        <Card className="substitute-card">
                          <div className="d-flex">
                            <h4 className="blue2">Abogado:</h4>
                            {s.substitute_score >= 5 && (
                              <h5 className="ml-auto">
                                <PigeonImg height="25" className="mb-0 mr-1" />
                                Recomendado
                              </h5>
                            )}
                          </div>

                          <h4>{s.name}</h4>

                          <h4 className="blue2 mt-2">Especialidades:</h4>
                          {skillsAreas.map((ska) => {
                            const areaSpecialities = skillsSpecialities.filter(
                              (sks) => sks.value.includes(ska.value)
                            )
                            const specialities =
                              areaSpecialities.length > 0
                                ? String(
                                    areaSpecialities.map((sk) => ` ${sk.label}`)
                                  )
                                : ''
                            return (
                              <div key={s._is + ska.value}>
                                <b>
                                  {ska.label}
                                  {areaSpecialities.length > 0 ? ':' : ''}
                                </b>
                                {specialities}
                              </div>
                            )
                          })}

                          <div className="mt-4">
                            <button
                              className="btn btn-success"
                              onClick={() =>
                                this.onClick(s, nextBaseUrl, auth.activeUser)
                              }
                            >
                              Seleccionar
                            </button>
                          </div>
                        </Card>
                      </Col>
                    )
                  })}
                </div>
              )}
            </section>
          </CardBody>
        </Card>
      </PageLayout>
    )
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  auth: state.auth,
})

export default connect(mapStateToProps, { loadSubstitutes })(Landing)
