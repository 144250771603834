import * as types from '../constants'
import { toast } from 'react-toastify'

const defaultState = {
  expedients: {},
  created: {},
  expedient: {},
  assigned: {},
  drafts: {},
}

export default function (state = defaultState, action) {
  switch (action.type) {
    case types.CREATE_NOTARY_SUCCESS:
      toast.success('Encargo creado')

      return {
        ...state,
        created: action.payload.expedient,
      }

    case types.CREATE_NOTARY_ERROR:
      const message = action.payload?.response?.message

      toast.error(
        message ||
          'Ha ocurrido un error, si persiste contacte con el administrador'
      )
      return state

    default:
      return state
  }
}
