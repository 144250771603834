const judicialParties = [
  { id: 1, name: 'A Coruña', timezone: 'Europe/Madrid' },
  { id: 2, name: 'A Estrada', timezone: 'Europe/Madrid' },
  { id: 3, name: 'A Fonsagrada', timezone: 'Europe/Madrid' },
  { id: 4, name: 'A Pobra de Trives', timezone: 'Europe/Madrid' },
  { id: 5, name: 'Aguilar de la Frontera', timezone: 'Europe/Madrid' },
  { id: 6, name: 'Albacete', timezone: 'Europe/Madrid' },
  { id: 7, name: 'Alcala de Guadaira', timezone: 'Europe/Madrid' },
  { id: 8, name: 'Alcala de Henares', timezone: 'Europe/Madrid' },
  { id: 9, name: 'Alcala la Real', timezone: 'Europe/Madrid' },
  { id: 10, name: 'Alcañiz', timezone: 'Europe/Madrid' },
  { id: 11, name: 'Alcaraz', timezone: 'Europe/Madrid' },
  { id: 12, name: 'Alcazar de San Juan', timezone: 'Europe/Madrid' },
  { id: 13, name: 'Alcobendas', timezone: 'Europe/Madrid' },
  { id: 14, name: 'Alcorcon', timezone: 'Europe/Madrid' },
  { id: 15, name: 'Alcoy/alcoi', timezone: 'Europe/Madrid' },
  { id: 16, name: 'Algeciras', timezone: 'Europe/Madrid' },
  { id: 17, name: 'Alicante/Alacant', timezone: 'Europe/Madrid' },
  { id: 18, name: 'Almaden', timezone: 'Europe/Madrid' },
  { id: 19, name: 'Almagro', timezone: 'Europe/Madrid' },
  { id: 20, name: 'Almansa', timezone: 'Europe/Madrid' },
  { id: 21, name: 'Almazan', timezone: 'Europe/Madrid' },
  { id: 22, name: 'Almendralejo', timezone: 'Europe/Madrid' },
  { id: 23, name: 'Almeria', timezone: 'Europe/Madrid' },
  { id: 24, name: 'Almuñecar', timezone: 'Europe/Madrid' },
  { id: 25, name: 'Alzira', timezone: 'Europe/Madrid' },
  { id: 26, name: 'Amposta', timezone: 'Europe/Madrid' },
  { id: 27, name: 'Amurrio', timezone: 'Europe/Madrid' },
  { id: 28, name: 'Andujar', timezone: 'Europe/Madrid' },
  { id: 29, name: 'Antequera', timezone: 'Europe/Madrid' },
  { id: 30, name: 'Aoiz/agoitz', timezone: 'Europe/Madrid' },
  { id: 31, name: 'Aracena', timezone: 'Europe/Madrid' },
  { id: 32, name: 'Aranda de Duero', timezone: 'Europe/Madrid' },
  { id: 33, name: 'Aranjuez', timezone: 'Europe/Madrid' },
  { id: 34, name: 'Archidona', timezone: 'Europe/Madrid' },
  { id: 35, name: 'Arcos de la Frontera', timezone: 'Europe/Madrid' },
  { id: 36, name: 'Arenas de San Pedro', timezone: 'Europe/Madrid' },
  { id: 37, name: 'Arenys de Mar', timezone: 'Europe/Madrid' },
  { id: 38, name: 'Arevalo', timezone: 'Europe/Madrid' },
  { id: 39, name: 'Arganda del Rey', timezone: 'Europe/Madrid' },
  { id: 40, name: 'Arona', timezone: 'Atlantic/Canary' },
  { id: 41, name: 'Arrecife', timezone: 'Atlantic/Canary' },
  { id: 42, name: 'Arucas', timezone: 'Atlantic/Canary' },
  { id: 43, name: 'Arzua', timezone: 'Europe/Madrid' },
  { id: 44, name: 'Astorga', timezone: 'Europe/Madrid' },
  { id: 45, name: 'Avila', timezone: 'Europe/Madrid' },
  { id: 46, name: 'Aviles', timezone: 'Europe/Madrid' },
  { id: 47, name: 'Ayamonte', timezone: 'Europe/Madrid' },
  { id: 48, name: 'Azpeitia', timezone: 'Europe/Madrid' },
  { id: 49, name: 'Badajoz', timezone: 'Europe/Madrid' },
  { id: 50, name: 'Badalona', timezone: 'Europe/Madrid' },
  { id: 51, name: 'Baena', timezone: 'Europe/Madrid' },
  { id: 52, name: 'Baeza', timezone: 'Europe/Madrid' },
  { id: 53, name: 'Balaguer', timezone: 'Europe/Madrid' },
  { id: 54, name: 'Balmaseda', timezone: 'Europe/Madrid' },
  { id: 55, name: 'Bande', timezone: 'Europe/Madrid' },
  { id: 56, name: 'Barakaldo', timezone: 'Europe/Madrid' },
  { id: 57, name: 'Barbastro', timezone: 'Europe/Madrid' },
  { id: 58, name: 'Barbate', timezone: 'Europe/Madrid' },
  { id: 59, name: 'Barcelona', timezone: 'Europe/Madrid' },
  { id: 60, name: 'Baza', timezone: 'Europe/Madrid' },
  { id: 61, name: 'Becerrea', timezone: 'Europe/Madrid' },
  { id: 62, name: 'Bejar', timezone: 'Europe/Madrid' },
  { id: 63, name: 'Benavente', timezone: 'Europe/Madrid' },
  { id: 64, name: 'Benidorm', timezone: 'Europe/Madrid' },
  { id: 65, name: 'Berga', timezone: 'Europe/Madrid' },
  { id: 66, name: 'Bergara', timezone: 'Europe/Madrid' },
  { id: 67, name: 'Berja', timezone: 'Europe/Madrid' },
  { id: 68, name: 'Betanzos', timezone: 'Europe/Madrid' },
  { id: 69, name: 'Bilbao', timezone: 'Europe/Madrid' },
  { id: 70, name: 'Blanes', timezone: 'Europe/Madrid' },
  { id: 71, name: 'Boltaña', timezone: 'Europe/Madrid' },
  { id: 72, name: 'Briviesca', timezone: 'Europe/Madrid' },
  { id: 73, name: 'Burgo de Osma-ciudad de Osma', timezone: 'Europe/Madrid' },
  { id: 74, name: 'Burgos', timezone: 'Europe/Madrid' },
  { id: 75, name: 'Cabra', timezone: 'Europe/Madrid' },
  { id: 76, name: 'Caceres', timezone: 'Europe/Madrid' },
  { id: 77, name: 'Cadiz', timezone: 'Europe/Madrid' },
  { id: 78, name: 'Calahorra', timezone: 'Europe/Madrid' },
  { id: 79, name: 'Calamocha', timezone: 'Europe/Madrid' },
  { id: 80, name: 'Calatayud', timezone: 'Europe/Madrid' },
  { id: 81, name: 'Caldas de Reis', timezone: 'Europe/Madrid' },
  { id: 82, name: 'Cambados', timezone: 'Europe/Madrid' },
  { id: 83, name: 'Cangas', timezone: 'Europe/Madrid' },
  { id: 84, name: 'Cangas de Onis', timezone: 'Europe/Madrid' },
  { id: 85, name: 'Cangas del Narcea', timezone: 'Europe/Madrid' },
  { id: 86, name: 'Caravaca de la Cruz', timezone: 'Europe/Madrid' },
  { id: 87, name: 'Carballo', timezone: 'Europe/Madrid' },
  { id: 88, name: 'Carlet', timezone: 'Europe/Madrid' },
  { id: 89, name: 'Carmona', timezone: 'Europe/Madrid' },
  { id: 90, name: 'Carrion de Los Condes', timezone: 'Europe/Madrid' },
  { id: 91, name: 'Cartagena', timezone: 'Europe/Madrid' },
  { id: 92, name: 'Casas-ibañez', timezone: 'Europe/Madrid' },
  { id: 93, name: 'Caspe', timezone: 'Europe/Madrid' },
  {
    id: 94,
    name: 'Castellón de la Plana/castelló de la Plana',
    timezone: 'Europe/Madrid',
  },
  { id: 95, name: 'Castropol', timezone: 'Europe/Madrid' },
  { id: 96, name: 'Castro-urdiales', timezone: 'Europe/Madrid' },
  { id: 97, name: 'Castuera', timezone: 'Europe/Madrid' },
  { id: 98, name: 'Catarroja', timezone: 'Europe/Madrid' },
  { id: 99, name: 'Cazalla de la Sierra', timezone: 'Europe/Madrid' },
  { id: 100, name: 'Cazorla', timezone: 'Europe/Madrid' },
  { id: 101, name: 'Celanova', timezone: 'Europe/Madrid' },
  { id: 102, name: 'Cerdanyola del Valles', timezone: 'Europe/Madrid' },
  { id: 103, name: 'Cervera', timezone: 'Europe/Madrid' },
  { id: 104, name: 'Cervera de Pisuerga', timezone: 'Europe/Madrid' },
  { id: 105, name: 'Ceuta', timezone: 'Europe/Madrid' },
  { id: 106, name: 'Chantada', timezone: 'Europe/Madrid' },
  { id: 107, name: 'Chiclana de la Frontera', timezone: 'Europe/Madrid' },
  { id: 108, name: 'Cieza', timezone: 'Europe/Madrid' },
  { id: 109, name: 'Cistierna', timezone: 'Europe/Madrid' },
  { id: 110, name: 'Ciudad Real', timezone: 'Europe/Madrid' },
  { id: 111, name: 'Ciudad Rodrigo', timezone: 'Europe/Madrid' },
  { id: 112, name: 'Ciutadella de Menorca', timezone: 'Europe/Madrid' },
  { id: 113, name: 'Coin', timezone: 'Europe/Madrid' },
  { id: 114, name: 'Collado Villalba', timezone: 'Europe/Madrid' },
  { id: 115, name: 'Colmenar Viejo', timezone: 'Europe/Madrid' },
  { id: 116, name: 'Corcubion', timezone: 'Europe/Madrid' },
  { id: 117, name: 'Cordoba', timezone: 'Europe/Madrid' },
  { id: 118, name: 'Coria', timezone: 'Europe/Madrid' },
  { id: 119, name: 'Coria del Rio', timezone: 'Europe/Madrid' },
  { id: 120, name: 'Cornella de Llobregat', timezone: 'Europe/Madrid' },
  { id: 121, name: 'Coslada', timezone: 'Europe/Madrid' },
  { id: 122, name: 'Cuellar', timezone: 'Europe/Madrid' },
  { id: 123, name: 'Cuenca', timezone: 'Europe/Madrid' },
  { id: 124, name: 'Daimiel', timezone: 'Europe/Madrid' },
  { id: 125, name: 'Daroca', timezone: 'Europe/Madrid' },
  { id: 126, name: 'Denia', timezone: 'Europe/Madrid' },
  { id: 127, name: 'Don Benito', timezone: 'Europe/Madrid' },
  { id: 128, name: 'Donostia-san Sebastian', timezone: 'Europe/Madrid' },
  { id: 129, name: 'Dos Hermanas', timezone: 'Europe/Madrid' },
  { id: 130, name: 'Durango', timezone: 'Europe/Madrid' },
  { id: 131, name: 'Ecija', timezone: 'Europe/Madrid' },
  { id: 132, name: 'Eibar', timezone: 'Europe/Madrid' },
  { id: 133, name: 'Eivissa', timezone: 'Europe/Madrid' },
  { id: 134, name: 'Ejea de Los Caballeros', timezone: 'Europe/Madrid' },
  { id: 135, name: 'El Ejido', timezone: 'Europe/Madrid' },
  { id: 136, name: 'El Prat de Llobregat', timezone: 'Europe/Madrid' },
  { id: 137, name: 'El Puerto de Santa Maria', timezone: 'Europe/Madrid' },
  { id: 138, name: 'El Vendrell', timezone: 'Europe/Madrid' },
  { id: 139, name: 'Elche/elx', timezone: 'Europe/Madrid' },
  { id: 140, name: 'Elda', timezone: 'Europe/Madrid' },
  { id: 141, name: 'Esplugues de Llobregat', timezone: 'Europe/Madrid' },
  { id: 142, name: 'Estella/lizarra', timezone: 'Europe/Madrid' },
  { id: 143, name: 'Estepa', timezone: 'Europe/Madrid' },
  { id: 144, name: 'Estepona', timezone: 'Europe/Madrid' },
  { id: 145, name: 'Falset', timezone: 'Europe/Madrid' },
  { id: 146, name: 'Ferrol', timezone: 'Europe/Madrid' },
  { id: 147, name: 'Figueres', timezone: 'Europe/Madrid' },
  { id: 148, name: 'Fraga', timezone: 'Europe/Madrid' },
  { id: 149, name: 'Fregenal de la Sierra', timezone: 'Europe/Madrid' },
  { id: 150, name: 'Fuengirola', timezone: 'Europe/Madrid' },
  { id: 151, name: 'Fuenlabrada', timezone: 'Europe/Madrid' },
  { id: 152, name: 'Gandesa', timezone: 'Europe/Madrid' },
  { id: 153, name: 'Gandia', timezone: 'Europe/Madrid' },
  { id: 154, name: 'Gava', timezone: 'Europe/Madrid' },
  { id: 155, name: 'Gernika-lumo', timezone: 'Europe/Madrid' },
  { id: 156, name: 'Getafe', timezone: 'Europe/Madrid' },
  { id: 157, name: 'Getxo', timezone: 'Europe/Madrid' },
  { id: 158, name: 'Gijon', timezone: 'Europe/Madrid' },
  { id: 159, name: 'Girona', timezone: 'Europe/Madrid' },
  { id: 160, name: 'Grado', timezone: 'Europe/Madrid' },
  { id: 161, name: 'Granada', timezone: 'Europe/Madrid' },
  { id: 162, name: 'Granadilla de Abona', timezone: 'Atlantic/Canary' },
  { id: 163, name: 'Granollers', timezone: 'Europe/Madrid' },
  { id: 164, name: 'Guadalajara', timezone: 'Europe/Madrid' },
  { id: 165, name: 'Guadix', timezone: 'Europe/Madrid' },
  { id: 166, name: 'Güimar', timezone: 'Atlantic/Canary' },
  { id: 167, name: 'Haro', timezone: 'Europe/Madrid' },
  { id: 168, name: 'Hellin', timezone: 'Europe/Madrid' },
  { id: 169, name: 'Herrera del Duque', timezone: 'Europe/Madrid' },
  { id: 170, name: 'Huelva', timezone: 'Europe/Madrid' },
  { id: 171, name: 'Huercal-overa', timezone: 'Europe/Madrid' },
  { id: 172, name: 'Huesca', timezone: 'Europe/Madrid' },
  { id: 173, name: 'Huescar', timezone: 'Europe/Madrid' },
  { id: 174, name: 'Ibi', timezone: 'Europe/Madrid' },
  { id: 175, name: 'Icod de Los Vinos', timezone: 'Atlantic/Canary' },
  { id: 176, name: 'Igualada', timezone: 'Europe/Madrid' },
  { id: 177, name: 'Illescas', timezone: 'Europe/Madrid' },
  { id: 178, name: 'Inca', timezone: 'Europe/Madrid' },
  { id: 179, name: 'Irun', timezone: 'Europe/Madrid' },
  { id: 180, name: 'Jaca', timezone: 'Europe/Madrid' },
  { id: 181, name: 'Jaen', timezone: 'Europe/Madrid' },
  { id: 182, name: 'Jerez de la Frontera', timezone: 'Europe/Madrid' },
  { id: 183, name: 'Jerez de Los Caballeros', timezone: 'Europe/Madrid' },
  { id: 184, name: 'Jumilla', timezone: 'Europe/Madrid' },
  { id: 185, name: 'La Almunia de Doña Godina', timezone: 'Europe/Madrid' },
  { id: 186, name: 'La Bañeza', timezone: 'Europe/Madrid' },
  { id: 187, name: "La Bisbal d'emporda", timezone: 'Europe/Madrid' },
  { id: 188, name: 'La Carolina', timezone: 'Europe/Madrid' },
  { id: 189, name: 'La Linea de la Concepcion', timezone: 'Europe/Madrid' },
  { id: 190, name: 'La Orotava', timezone: 'Atlantic/Canary' },
  { id: 191, name: 'La Palma del Condado', timezone: 'Europe/Madrid' },
  { id: 192, name: 'La Roda', timezone: 'Europe/Madrid' },
  { id: 193, name: "La Seu d'urgell", timezone: 'Europe/Madrid' },
  { id: 194, name: 'Lalin', timezone: 'Europe/Madrid' },
  { id: 195, name: 'Langreo', timezone: 'Europe/Madrid' },
  { id: 196, name: 'Laredo', timezone: 'Europe/Madrid' },
  { id: 197, name: 'Las Palmas de Gran Canaria', timezone: 'Atlantic/Canary' },
  { id: 198, name: 'Laviana', timezone: 'Europe/Madrid' },
  { id: 199, name: 'Lebrija', timezone: 'Europe/Madrid' },
  { id: 200, name: 'Leganes', timezone: 'Europe/Madrid' },
  { id: 201, name: 'Lena', timezone: 'Europe/Madrid' },
  { id: 202, name: 'Leon', timezone: 'Europe/Madrid' },
  { id: 203, name: 'Lerma', timezone: 'Europe/Madrid' },
  { id: 204, name: "L'hospitalet de Llobregat", timezone: 'Europe/Madrid' },
  { id: 205, name: 'Linares', timezone: 'Europe/Madrid' },
  { id: 206, name: 'Llanes', timezone: 'Europe/Madrid' },
  { id: 207, name: 'Lleida', timezone: 'Europe/Madrid' },
  { id: 208, name: 'Llerena', timezone: 'Europe/Madrid' },
  { id: 209, name: 'Lliria', timezone: 'Europe/Madrid' },
  { id: 210, name: 'Logroño', timezone: 'Europe/Madrid' },
  { id: 211, name: 'Logrosan', timezone: 'Europe/Madrid' },
  { id: 212, name: 'Loja', timezone: 'Europe/Madrid' },
  { id: 213, name: 'Lora del Rio', timezone: 'Europe/Madrid' },
  { id: 214, name: 'Lorca', timezone: 'Europe/Madrid' },
  { id: 215, name: 'Los Llanos de Aridane', timezone: 'Atlantic/Canary' },
  { id: 216, name: 'Lucena', timezone: 'Europe/Madrid' },
  { id: 217, name: 'Lugo', timezone: 'Europe/Madrid' },
  { id: 218, name: 'Madrid', timezone: 'Europe/Madrid' },
  { id: 219, name: 'Mahon', timezone: 'Europe/Madrid' },
  { id: 220, name: 'Majadahonda', timezone: 'Europe/Madrid' },
  { id: 221, name: 'Malaga', timezone: 'Europe/Madrid' },
  { id: 222, name: 'Manacor', timezone: 'Europe/Madrid' },
  { id: 223, name: 'Manresa', timezone: 'Europe/Madrid' },
  { id: 224, name: 'Manzanares', timezone: 'Europe/Madrid' },
  { id: 225, name: 'Marbella', timezone: 'Europe/Madrid' },
  { id: 226, name: 'Marchena', timezone: 'Europe/Madrid' },
  { id: 227, name: 'Marin', timezone: 'Europe/Madrid' },
  { id: 228, name: 'Martorell', timezone: 'Europe/Madrid' },
  { id: 229, name: 'Martos', timezone: 'Europe/Madrid' },
  { id: 230, name: 'Massamagrell', timezone: 'Europe/Madrid' },
  { id: 231, name: 'Mataro', timezone: 'Europe/Madrid' },
  { id: 232, name: 'Medina de Rioseco', timezone: 'Europe/Madrid' },
  { id: 233, name: 'Medina del Campo', timezone: 'Europe/Madrid' },
  { id: 234, name: 'Medio Cudeyo', timezone: 'Europe/Madrid' },
  { id: 235, name: 'Melilla', timezone: 'Europe/Madrid' },
  { id: 236, name: 'Merida', timezone: 'Europe/Madrid' },
  { id: 237, name: 'Mieres', timezone: 'Europe/Madrid' },
  { id: 238, name: 'Miranda de Ebro', timezone: 'Europe/Madrid' },
  { id: 239, name: 'Mislata', timezone: 'Europe/Madrid' },
  { id: 240, name: 'Moguer', timezone: 'Europe/Madrid' },
  { id: 241, name: 'Molina de Aragon', timezone: 'Europe/Madrid' },
  { id: 242, name: 'Molina de Segura', timezone: 'Europe/Madrid' },
  { id: 243, name: 'Mollet del Valles', timezone: 'Europe/Madrid' },
  { id: 244, name: 'Moncada', timezone: 'Europe/Madrid' },
  { id: 245, name: 'Mondoñedo', timezone: 'Europe/Madrid' },
  { id: 246, name: 'Monforte de Lemos', timezone: 'Europe/Madrid' },
  { id: 247, name: 'Montijo', timezone: 'Europe/Madrid' },
  { id: 248, name: 'Montilla', timezone: 'Europe/Madrid' },
  { id: 249, name: 'Montoro', timezone: 'Europe/Madrid' },
  { id: 250, name: 'Monzon', timezone: 'Europe/Madrid' },
  { id: 251, name: 'Moron de la Frontera', timezone: 'Europe/Madrid' },
  { id: 252, name: 'Mostoles', timezone: 'Europe/Madrid' },
  { id: 253, name: 'Motilla del Palancar', timezone: 'Europe/Madrid' },
  { id: 254, name: 'Motril', timezone: 'Europe/Madrid' },
  { id: 255, name: 'Mula', timezone: 'Europe/Madrid' },
  { id: 256, name: 'Murcia', timezone: 'Europe/Madrid' },
  { id: 257, name: 'Muros', timezone: 'Europe/Madrid' },
  { id: 258, name: 'Navalcarnero', timezone: 'Europe/Madrid' },
  { id: 259, name: 'Navalmoral de la Mata', timezone: 'Europe/Madrid' },
  { id: 260, name: 'Negreira', timezone: 'Europe/Madrid' },
  { id: 261, name: 'Noia', timezone: 'Europe/Madrid' },
  { id: 262, name: 'Novelda', timezone: 'Europe/Madrid' },
  { id: 263, name: 'Nules', timezone: 'Europe/Madrid' },
  { id: 264, name: 'O Barco de Valdeorras', timezone: 'Europe/Madrid' },
  { id: 265, name: 'O Carballiño', timezone: 'Europe/Madrid' },
  { id: 266, name: 'O Porriño', timezone: 'Europe/Madrid' },
  { id: 267, name: 'Ocaña', timezone: 'Europe/Madrid' },
  { id: 268, name: 'Olivenza', timezone: 'Europe/Madrid' },
  { id: 269, name: 'Olot', timezone: 'Europe/Madrid' },
  { id: 270, name: 'Ontinyent', timezone: 'Europe/Madrid' },
  { id: 271, name: 'Ordes', timezone: 'Europe/Madrid' },
  { id: 272, name: 'Orgaz', timezone: 'Europe/Madrid' },
  { id: 273, name: 'Orgiva', timezone: 'Europe/Madrid' },
  { id: 274, name: 'Orihuela', timezone: 'Europe/Madrid' },
  { id: 275, name: 'Ortigueira', timezone: 'Europe/Madrid' },
  { id: 276, name: 'Osuna', timezone: 'Europe/Madrid' },
  { id: 277, name: 'Ourense', timezone: 'Europe/Madrid' },
  { id: 278, name: 'Oviedo', timezone: 'Europe/Madrid' },
  { id: 279, name: 'Padron', timezone: 'Europe/Madrid' },
  { id: 280, name: 'Palencia', timezone: 'Europe/Madrid' },
  { id: 281, name: 'Palma de Mallorca', timezone: 'Europe/Madrid' },
  { id: 282, name: 'Pamplona/iruña', timezone: 'Europe/Madrid' },
  { id: 283, name: 'Parla', timezone: 'Europe/Madrid' },
  { id: 284, name: 'Paterna', timezone: 'Europe/Madrid' },
  { id: 285, name: 'Peñaranda de Bracamonte', timezone: 'Europe/Madrid' },
  { id: 286, name: 'Peñarroya-pueblonuevo', timezone: 'Europe/Madrid' },
  { id: 287, name: 'Picassent', timezone: 'Europe/Madrid' },
  { id: 288, name: 'Piedrahita', timezone: 'Europe/Madrid' },
  { id: 289, name: 'Piloña', timezone: 'Europe/Madrid' },
  { id: 290, name: 'Plasencia', timezone: 'Europe/Madrid' },
  { id: 291, name: 'Ponferrada', timezone: 'Europe/Madrid' },
  { id: 292, name: 'Ponteareas', timezone: 'Europe/Madrid' },
  { id: 293, name: 'Pontevedra', timezone: 'Europe/Madrid' },
  { id: 294, name: 'Posadas', timezone: 'Europe/Madrid' },
  { id: 295, name: 'Pozoblanco', timezone: 'Europe/Madrid' },
  { id: 296, name: 'Pozuelo de Alarcon', timezone: 'Europe/Madrid' },
  { id: 297, name: 'Pravia', timezone: 'Europe/Madrid' },
  { id: 298, name: 'Priego de Cordoba', timezone: 'Europe/Madrid' },
  { id: 299, name: 'Puebla de Sanabria', timezone: 'Europe/Madrid' },
  { id: 300, name: 'Puente Genil', timezone: 'Europe/Madrid' },
  { id: 301, name: 'Puerto de la Cruz', timezone: 'Atlantic/Canary' },
  { id: 302, name: 'Puerto del Rosario', timezone: 'Atlantic/Canary' },
  { id: 303, name: 'Puerto Real', timezone: 'Europe/Madrid' },
  { id: 304, name: 'Puertollano', timezone: 'Europe/Madrid' },
  { id: 305, name: 'Puigcerda', timezone: 'Europe/Madrid' },
  { id: 306, name: 'Purchena', timezone: 'Europe/Madrid' },
  { id: 307, name: 'Quart de Poblet', timezone: 'Europe/Madrid' },
  { id: 308, name: 'Quintanar de la Orden', timezone: 'Europe/Madrid' },
  { id: 309, name: 'Redondela', timezone: 'Europe/Madrid' },
  { id: 310, name: 'Reinosa', timezone: 'Europe/Madrid' },
  { id: 311, name: 'Requena', timezone: 'Europe/Madrid' },
  { id: 312, name: 'Reus', timezone: 'Europe/Madrid' },
  { id: 313, name: 'Ribadavia', timezone: 'Europe/Madrid' },
  { id: 314, name: 'Ribeira', timezone: 'Europe/Madrid' },
  { id: 315, name: 'Ripoll', timezone: 'Europe/Madrid' },
  { id: 316, name: 'Ronda', timezone: 'Europe/Madrid' },
  { id: 317, name: 'Roquetas de Mar', timezone: 'Europe/Madrid' },
  { id: 318, name: 'Rota', timezone: 'Europe/Madrid' },
  { id: 319, name: 'Rubi', timezone: 'Europe/Madrid' },
  { id: 320, name: 'Sabadell', timezone: 'Europe/Madrid' },
  { id: 321, name: 'Sagunto/sagunt', timezone: 'Europe/Madrid' },
  { id: 322, name: 'Sahagun', timezone: 'Europe/Madrid' },
  { id: 323, name: 'Salamanca', timezone: 'Europe/Madrid' },
  { id: 324, name: 'Salas de Los Infantes', timezone: 'Europe/Madrid' },
  { id: 325, name: 'San Bartolome de Tirajana', timezone: 'Atlantic/Canary' },
  { id: 326, name: 'San Clemente', timezone: 'Europe/Madrid' },
  { id: 327, name: 'San Cristobal de la Laguna', timezone: 'Atlantic/Canary' },
  { id: 328, name: 'San Fernando', timezone: 'Europe/Madrid' },
  { id: 329, name: 'San Javier', timezone: 'Europe/Madrid' },
  { id: 330, name: 'San Lorenzo de El Escorial', timezone: 'Europe/Madrid' },
  { id: 331, name: 'San Roque', timezone: 'Europe/Madrid' },
  { id: 332, name: 'San Sebastian de la Gomera', timezone: 'Atlantic/Canary' },
  { id: 333, name: 'San Vicente de la Barquera', timezone: 'Europe/Madrid' },
  {
    id: 334,
    name: 'San Vicente del Raspeig/sant Vicent del Raspeig',
    timezone: 'Europe/Madrid',
  },
  { id: 335, name: 'Sanlucar de Barrameda', timezone: 'Europe/Madrid' },
  { id: 336, name: 'Sanlucar la Mayor', timezone: 'Europe/Madrid' },
  { id: 337, name: 'Sant Boi de Llobregat', timezone: 'Europe/Madrid' },
  { id: 338, name: 'Sant Feliu de Guixols', timezone: 'Europe/Madrid' },
  { id: 339, name: 'Sant Feliu de Llobregat', timezone: 'Europe/Madrid' },
  { id: 340, name: 'Santa Coloma de Farners', timezone: 'Europe/Madrid' },
  { id: 341, name: 'Santa Coloma de Gramenet', timezone: 'Europe/Madrid' },
  { id: 342, name: 'Santa Cruz de la Palma', timezone: 'Atlantic/Canary' },
  { id: 343, name: 'Santa Cruz de Tenerife', timezone: 'Atlantic/Canary' },
  { id: 344, name: 'Santa Fe', timezone: 'Europe/Madrid' },
  {
    id: 345,
    name: 'Santa Maria de Guia de Gran Canaria',
    timezone: 'Atlantic/Canary',
  },
  { id: 346, name: 'Santa Maria la Real de Nieva', timezone: 'Europe/Madrid' },
  { id: 347, name: 'Santander', timezone: 'Europe/Madrid' },
  { id: 348, name: 'Santiago de Compostela', timezone: 'Europe/Madrid' },
  { id: 349, name: 'Santoña', timezone: 'Europe/Madrid' },
  { id: 350, name: 'Sarria', timezone: 'Europe/Madrid' },
  { id: 351, name: 'Segorbe', timezone: 'Europe/Madrid' },
  { id: 352, name: 'Segovia', timezone: 'Europe/Madrid' },
  { id: 353, name: 'Sepulveda', timezone: 'Europe/Madrid' },
  { id: 354, name: 'Sevilla', timezone: 'Europe/Madrid' },
  { id: 355, name: 'Siero', timezone: 'Europe/Madrid' },
  { id: 356, name: 'Sigüenza', timezone: 'Europe/Madrid' },
  { id: 357, name: 'Solsona', timezone: 'Europe/Madrid' },
  { id: 358, name: 'Soria', timezone: 'Europe/Madrid' },
  { id: 359, name: 'Sueca', timezone: 'Europe/Madrid' },
  { id: 360, name: 'Tafalla', timezone: 'Europe/Madrid' },
  { id: 361, name: 'Talavera de la Reina', timezone: 'Europe/Madrid' },
  { id: 362, name: 'Tarancon', timezone: 'Europe/Madrid' },
  { id: 363, name: 'Tarazona', timezone: 'Europe/Madrid' },
  { id: 364, name: 'Tarragona', timezone: 'Europe/Madrid' },
  { id: 365, name: 'Telde', timezone: 'Atlantic/Canary' },
  { id: 366, name: 'Terrassa', timezone: 'Europe/Madrid' },
  { id: 367, name: 'Teruel', timezone: 'Europe/Madrid' },
  { id: 368, name: 'Tineo', timezone: 'Europe/Madrid' },
  { id: 369, name: 'Toledo', timezone: 'Europe/Madrid' },
  { id: 370, name: 'Tolosa', timezone: 'Europe/Madrid' },
  { id: 371, name: 'Tomelloso', timezone: 'Europe/Madrid' },
  { id: 372, name: 'Toro', timezone: 'Europe/Madrid' },
  { id: 373, name: 'Torrejon de Ardoz', timezone: 'Europe/Madrid' },
  { id: 374, name: 'Torrelaguna', timezone: 'Europe/Madrid' },
  { id: 375, name: 'Torrelavega', timezone: 'Europe/Madrid' },
  { id: 376, name: 'Torremolinos', timezone: 'Europe/Madrid' },
  { id: 377, name: 'Torrent', timezone: 'Europe/Madrid' },
  { id: 378, name: 'Torrevieja', timezone: 'Europe/Madrid' },
  { id: 379, name: 'Torrijos', timezone: 'Europe/Madrid' },
  { id: 380, name: 'Torrox', timezone: 'Europe/Madrid' },
  { id: 381, name: 'Tortosa', timezone: 'Europe/Madrid' },
  { id: 382, name: 'Totana', timezone: 'Europe/Madrid' },
  { id: 383, name: 'Tremp', timezone: 'Europe/Madrid' },
  { id: 384, name: 'Trujillo', timezone: 'Europe/Madrid' },
  { id: 385, name: 'Tudela', timezone: 'Europe/Madrid' },
  { id: 386, name: 'Tui', timezone: 'Europe/Madrid' },
  { id: 387, name: 'Ubeda', timezone: 'Europe/Madrid' },
  { id: 388, name: 'Ubrique', timezone: 'Europe/Madrid' },
  { id: 389, name: 'Utrera', timezone: 'Europe/Madrid' },
  { id: 390, name: 'Valdemoro', timezone: 'Europe/Madrid' },
  { id: 391, name: 'Valdepeñas', timezone: 'Europe/Madrid' },
  { id: 392, name: 'Valdes', timezone: 'Europe/Madrid' },
  { id: 393, name: 'Valencia', timezone: 'Europe/Madrid' },
  { id: 394, name: 'Valencia de Alcantara', timezone: 'Europe/Madrid' },
  { id: 395, name: 'Valladolid', timezone: 'Europe/Madrid' },
  { id: 396, name: 'Valls', timezone: 'Europe/Madrid' },
  { id: 397, name: 'Valverde', timezone: 'Atlantic/Canary' },
  { id: 398, name: 'Valverde del Camino', timezone: 'Europe/Madrid' },
  { id: 399, name: 'Velez-malaga', timezone: 'Europe/Madrid' },
  { id: 400, name: 'Velez-rubio', timezone: 'Europe/Madrid' },
  { id: 401, name: 'Vera', timezone: 'Europe/Madrid' },
  { id: 402, name: 'Verin', timezone: 'Europe/Madrid' },
  { id: 403, name: 'Vic', timezone: 'Europe/Madrid' },
  { id: 404, name: 'Vielha E Mijaran', timezone: 'Europe/Madrid' },
  { id: 405, name: 'Vigo', timezone: 'Europe/Madrid' },
  { id: 406, name: 'Vilafranca del Penedes', timezone: 'Europe/Madrid' },
  { id: 407, name: 'Vilagarcia de Arousa', timezone: 'Europe/Madrid' },
  { id: 408, name: 'Vilalba', timezone: 'Europe/Madrid' },
  { id: 409, name: 'Vilanova I la Geltru', timezone: 'Europe/Madrid' },
  { id: 410, name: 'Villablino', timezone: 'Europe/Madrid' },
  { id: 411, name: 'Villacarrillo', timezone: 'Europe/Madrid' },
  { id: 412, name: 'Villafranca de Los Barros', timezone: 'Europe/Madrid' },
  { id: 413, name: 'Villajoyosa/vila Joiosa, La', timezone: 'Europe/Madrid' },
  { id: 414, name: 'Villalpando', timezone: 'Europe/Madrid' },
  { id: 415, name: 'Villanueva de la Serena', timezone: 'Europe/Madrid' },
  { id: 416, name: 'Villanueva de Los Infantes', timezone: 'Europe/Madrid' },
  {
    id: 417,
    name: 'Villarcayo de Merindad de Castilla la Vieja',
    timezone: 'Europe/Madrid',
  },
  { id: 418, name: 'Villarreal/vila-real', timezone: 'Europe/Madrid' },
  { id: 419, name: 'Villarrobledo', timezone: 'Europe/Madrid' },
  { id: 420, name: 'Villaviciosa', timezone: 'Europe/Madrid' },
  { id: 421, name: 'Villena', timezone: 'Europe/Madrid' },
  { id: 422, name: 'Vinaros', timezone: 'Europe/Madrid' },
  { id: 423, name: 'Vitigudino', timezone: 'Europe/Madrid' },
  { id: 424, name: 'Vitoria-gasteiz', timezone: 'Europe/Madrid' },
  { id: 425, name: 'Viveiro', timezone: 'Europe/Madrid' },
  { id: 426, name: 'Xativa', timezone: 'Europe/Madrid' },
  { id: 427, name: 'Xinzo de Limia', timezone: 'Europe/Madrid' },
  { id: 428, name: 'Yecla', timezone: 'Europe/Madrid' },
  { id: 429, name: 'Zafra', timezone: 'Europe/Madrid' },
  { id: 430, name: 'Zamora', timezone: 'Europe/Madrid' },
  { id: 431, name: 'Zaragoza', timezone: 'Europe/Madrid' },
]

module.exports = { judicialParties }
