// #region SETTINGS
export const SIDEBAR_VISIBILITY_TOGGLE = "SIDEBAR_VISIBILITY_TOGGLE";
export const SIDEBAR_VISIBILITY_SHOW = "SIDEBAR_VISIBILITY_SHOW";
export const SIDEBAR_VISIBILITY_HIDE = "SIDEBAR_VISIBILITY_HIDE";

// #region Auth
export const LOGIN_REQUEST = Symbol('LOGIN_REQUEST')
export const LOGIN_SUCCESS = Symbol('LOGIN_SUCCESS')
export const LOGIN_ERROR = Symbol('LOGIN_ERROR')

export const PRELOGIN_REQUEST = Symbol('LOGIN_REQUEST')
export const PRELOGIN_SUCCESS = Symbol('LOGIN_SUCCESS')
export const PRELOGIN_ERROR = Symbol('LOGIN_ERROR')

export const LOGIN_FORM_REQUEST = Symbol('LOGIN_FORM_REQUEST')
export const LOGIN_FORM_SUCCESS = Symbol('LOGIN_FORM_SUCCESS')
export const LOGIN_FORM_ERROR = Symbol('LOGIN_FORM_ERROR')

export const SESSION_REQUEST = Symbol('SESSION_REQUEST')
export const SESSION_SUCCESS = Symbol('SESSION_SUCCESS')
export const SESSION_ERROR = Symbol('SESSION_ERROR')

export const CHANGE_EMAIL_REQ_REQUEST = Symbol('CHANGE_EMAIL_REQ_REQUEST')
export const CHANGE_EMAIL_REQ_SUCCESS = Symbol('CHANGE_EMAIL_REQ_SUCCESS')
export const CHANGE_EMAIL_REQ_ERROR = Symbol('CHANGE_EMAIL_REQ_ERROR')

export const CHANGE_EMAIL_REQUEST = Symbol('CHANGE_EMAIL_REQUEST')
export const CHANGE_EMAIL_SUCCESS = Symbol('CHANGE_EMAIL_SUCCESS')
export const CHANGE_EMAIL_ERROR = Symbol('CHANGE_EMAIL_ERROR')

export const LOGOUT_SUCCESS = Symbol('LOGOUT_SUCCESS')

// #region USERS
export const LOAD_USERS_REQUEST = Symbol('LOAD_USERS_REQUEST')
export const LOAD_USERS_SUCCESS = Symbol('LOAD_USERS_SUCCESS')
export const LOAD_USERS_ERROR = Symbol('LOAD_USERS_ERROR')

export const CREATE_CLIENT_REQUEST = Symbol('CREATE_CLIENT_REQUEST')
export const CREATE_CLIENT_SUCCESS = Symbol('CREATE_CLIENT_SUCCESS')
export const CREATE_CLIENT_ERROR = Symbol('CREATE_CLIENT_ERROR')

export const CREATE_CLIENT_FORM_REQUEST = Symbol('CREATE_CLIENT_REQUEST')
export const CREATE_CLIENT_FORM_SUCCESS = Symbol('CREATE_CLIENT_SUCCESS')
export const CREATE_CLIENT_FORM_ERROR = Symbol('CREATE_CLIENT_ERROR')

export const LOAD_USER_REQUEST = Symbol('LOAD_USER_REQUEST')
export const LOAD_USER_SUCCESS = Symbol('LOAD_USER_SUCCESS')
export const LOAD_USER_ERROR = Symbol('LOAD_USER_ERROR')

export const CREATE_USER_REQUEST = Symbol('CREATE_USER_REQUEST')
export const CREATE_USER_SUCCESS = Symbol('CREATE_USER_SUCCESS')
export const CREATE_USER_ERROR = Symbol('CREATE_USER_ERROR')

export const UPDATE_USER_REQUEST = Symbol('UPDATE_USER_REQUEST')
export const UPDATE_USER_SUCCESS = Symbol('UPDATE_USER_SUCCESS')
export const UPDATE_USER_ERROR = Symbol('UPDATE_USER_ERROR')

export const DELETE_USER_REQUEST = Symbol('DELETE_USER_REQUEST')
export const DELETE_USER_SUCCESS = Symbol('DELETE_USER_SUCCESS')
export const DELETE_USER_ERROR = Symbol('DELETE_USER_ERROR')

export const LOAD_SUBTITUTES_REQUEST = Symbol('LOAD_SUBTITUTES_REQUEST')
export const LOAD_SUBTITUTES_SUCCESS = Symbol('LOAD_SUBTITUTES_SUCCESS')
export const LOAD_SUBTITUTES_ERROR = Symbol('LOAD_SUBTITUTES_ERROR')

export const ADD_SUBSTITUTE_EXCLUDED_REQUEST = Symbol('ADD_SUBSTITUTE_EXCLUDED_REQUEST')
export const ADD_SUBSTITUTE_EXCLUDED_SUCCESS = Symbol('ADD_SUBSTITUTE_EXCLUDED_SUCCESS')
export const ADD_SUBSTITUTE_EXCLUDED_ERROR = Symbol('ADD_SUBSTITUTE_EXCLUDED_ERROR')

export const REMOVE_SUBSTITUTE_EXCLUDED_REQUEST = Symbol('REMOVE_SUBSTITUTE_EXCLUDED_REQUEST')
export const REMOVE_SUBSTITUTE_EXCLUDED_SUCCESS = Symbol('REMOVE_SUBSTITUTE_EXCLUDED_SUCCESS')
export const REMOVE_SUBSTITUTE_EXCLUDED_ERROR = Symbol('REMOVE_SUBSTITUTE_EXCLUDED_ERROR')

export const CHANGE_PARTNER_TO_COMPANY_REQUEST = Symbol('CHANGE_PARTNER_TO_COMPANY_REQUEST')
export const CHANGE_PARTNER_TO_COMPANY_SUCCESS = Symbol('CHANGE_PARTNER_TO_COMPANY_SUCCESS')
export const CHANGE_PARTNER_TO_COMPANY_ERROR = Symbol('CHANGE_PARTNER_TO_COMPANY_ERROR')

export const ADD_AUTH_POOL_REQUEST = Symbol('ADD_AUTH_POOL_REQUEST')
export const ADD_AUTH_POOL_SUCCESS = Symbol('ADD_AUTH_POOL_SUCCESS')
export const ADD_AUTH_POOL_ERROR = Symbol('ADD_AUTH_POOL_ERROR')

export const EDIT_AUTH_POOL_REQUEST = Symbol('EDIT_AUTH_POOL_REQUEST')
export const EDIT_AUTH_POOL_SUCCESS = Symbol('EDIT_AUTH_POOL_SUCCESS')
export const EDIT_AUTH_POOL_ERROR = Symbol('EDIT_AUTH_POOL_ERROR')

export const URGENT_ISSUE_REMINDER_EXPEDIENT_REQUEST = Symbol('URGENT_ISSUE_REMINDER_EXPEDIENT_REQUEST')
export const URGENT_ISSUE_REMINDER_EXPEDIENT_SUCCESS = Symbol('URGENT_ISSUE_REMINDER_EXPEDIENT_SUCCESS')
export const URGENT_ISSUE_REMINDER_EXPEDIENT_ERROR = Symbol('URGENT_ISSUE_REMINDER_EXPEDIENT_ERROR')


export const URGENT_ISSUE_REMINDER_PROCURA_REQUEST = Symbol('URGENT_ISSUE_REMINDER_PROCURA_REQUEST')
export const URGENT_ISSUE_REMINDER_PROCURA_SUCCESS = Symbol('URGENT_ISSUE_REMINDER_PROCURA_SUCCESS')
export const URGENT_ISSUE_REMINDER_PROCURA_ERROR = Symbol('URGENT_ISSUE_REMINDER_PROCURA_ERROR')
// #region USERS - LINKED PARTNERS
export const SET_LINKED_PARTNERS_REQUEST = Symbol('SET_LINKED_PARTNERS_REQUEST')
export const SET_LINKED_PARTNERS_SUCCESS = Symbol('SET_LINKED_PARTNERS_SUCCESS')
export const SET_LINKED_PARTNERS_ERROR = Symbol('SET_LINKED_PARTNERS_ERROR')

export const DELETE_LINKED_PARTNER_REQUEST = Symbol('DELETE_LINKED_PARTNER_REQUEST')
export const DELETE_LINKED_PARTNER_SUCCESS = Symbol('DELETE_LINKED_PARTNER_SUCCESS')
export const DELETE_LINKED_PARTNER_ERROR = Symbol('DELETE_LINKED_PARTNER_ERROR')

// #region USERS - CUSTOM FIELDS
export const ADD_CUSTOM_FIELD_REQUEST = Symbol('ADD_CUSTOM_FIELD_REQUEST')
export const ADD_CUSTOM_FIELD_SUCCESS = Symbol('ADD_CUSTOM_FIELD_SUCCESS')
export const ADD_CUSTOM_FIELD_ERROR = Symbol('ADD_CUSTOM_FIELD_ERROR')

export const UPDATE_CUSTOM_FIELD_REQUEST = Symbol('UPDATE_CUSTOM_FIELD_REQUEST')
export const UPDATE_CUSTOM_FIELD_SUCCESS = Symbol('UPDATE_CUSTOM_FIELD_SUCCESS')
export const UPDATE_CUSTOM_FIELD_ERROR = Symbol('UPDATE_CUSTOM_FIELD_ERROR')

// #region EXPEDIENT
export const CREATE_EXPEDIENT_REQUEST = Symbol('CREATE_EXPEDIENT_REQUEST')
export const CREATE_EXPEDIENT_SUCCESS = Symbol('CREATE_EXPEDIENT_SUCCESS')
export const CREATE_EXPEDIENT_ERROR = Symbol('CREATE_EXPEDIENT_ERROR')

export const CREATE_SELF_EXPEDIENT_REQUEST = Symbol('CREATE_SELF_EXPEDIENT_REQUEST')
export const CREATE_SELF_EXPEDIENT_SUCCESS = Symbol('CREATE_SELF_EXPEDIENT_SUCCESS')
export const CREATE_SELF_EXPEDIENT_ERROR = Symbol('CREATE_SELF_EXPEDIENT_ERROR')

export const LOAD_EXPEDIENT_REQUEST = Symbol('LOAD_EXPEDIENT_REQUEST')
export const LOAD_EXPEDIENT_SUCCESS = Symbol('LOAD_EXPEDIENT_SUCCESS')
export const LOAD_EXPEDIENT_ERROR = Symbol('LOAD_EXPEDIENT_ERROR')

export const LOAD_EXPEDIENTS_REQUEST = Symbol('LOAD_EXPEDIENTS_REQUEST')
export const LOAD_EXPEDIENTS_SUCCESS = Symbol('LOAD_EXPEDIENTS_SUCCESS')
export const LOAD_EXPEDIENTS_ERROR = Symbol('LOAD_EXPEDIENTS_ERROR')

export const LOAD_ASSIGNED_EXPEDIENTS_REQUEST = Symbol('LOAD_ASSIGNED_EXPEDIENTS_REQUEST')
export const LOAD_ASSIGNED_EXPEDIENTS_SUCCESS = Symbol('LOAD_ASSIGNED_EXPEDIENTS_SUCCESS')
export const LOAD_ASSIGNED_EXPEDIENTS_ERROR = Symbol('LOAD_ASSIGNED_EXPEDIENTS_ERROR')

export const RESET_CREATED_EXPEDIENT = Symbol('RESET_CREATED_EXPEDIENT')

export const UPDATE_EXPEDIENT_REQUEST = Symbol('UPDATE_EXPEDIENT_REQUEST')
export const UPDATE_EXPEDIENT_SUCCESS = Symbol('UPDATE_EXPEDIENT_SUCCESS')
export const UPDATE_EXPEDIENT_ERROR = Symbol('UPDATE_EXPEDIENT_ERROR')

export const UPDATE_REPORT_REQUEST = Symbol('UPDATE_REPORT_REQUEST')
export const UPDATE_REPORT_SUCCESS = Symbol('UPDATE_REPORT_SUCCESS')
export const UPDATE_REPORT_ERROR = Symbol('UPDATE_REPORT_ERROR')

export const LOAD_DASHBOARD_REQUEST = Symbol('LOAD_DASHBOARD_REQUEST')
export const LOAD_DASHBOARD_SUCCESS = Symbol('LOAD_DASHBOARD_SUCCESS')
export const LOAD_DASHBOARD_ERROR = Symbol('LOAD_DASHBOARD_ERROR')

export const LOAD_DRAFTS_REQUEST = Symbol('LOAD_DRAFTS_REQUEST')
export const LOAD_DRAFTS_SUCCESS = Symbol('LOAD_DRAFTS_SUCCESS')
export const LOAD_DRAFTS_ERROR = Symbol('LOAD_DRAFTS_ERROR')

export const EXPEDIENT_DELETED_REQUEST = Symbol('EXPEDIENT_DELETED_REQUEST')
export const EXPEDIENT_DELETED_SUCCESS = Symbol('EXPEDIENT_DELETED_SUCCESS')
export const EXPEDIENT_DELETED_ERROR = Symbol('EXPEDIENT_DELETED_ERROR')

export const EXPEDIENT_DOC_REMINDER_REQUEST = Symbol('EXPEDIENT_DOC_REMINDER_REQUEST')
export const EXPEDIENT_DOC_REMINDER_SUCCESS = Symbol('EXPEDIENT_DOC_REMINDER_SUCCESS')
export const EXPEDIENT_DOC_REMINDER_ERROR = Symbol('EXPEDIENT_DOC_REMINDER_ERROR')

export const REASSING_EXPEDIENT_REQUEST = Symbol('REASSING_EXPEDIENT_REQUEST')
export const REASSING_EXPEDIENT_SUCCESS = Symbol('REASSING_EXPEDIENT_SUCCESS')
export const REASSING_EXPEDIENT_ERROR = Symbol('REASSING_EXPEDIENT_ERROR')

export const EXPEDIENT_NOTIFICATION_REQUEST = Symbol('EXPEDIENT_NOTIFICATION_REQUEST')
export const EXPEDIENT_NOTIFICATION_SUCCESS = Symbol('EXPEDIENT_NOTIFICATION_SUCCESS')
export const EXPEDIENT_NOTIFICATION_ERROR = Symbol('EXPEDIENT_NOTIFICATION_ERROR')

export const EXPEDIENT_DELETE_FILE_REQUEST = Symbol('EXPEDIENT_DELETE_FILE_REQUEST')
export const EXPEDIENT_DELETE_FILE_SUCCESS = Symbol('EXPEDIENT_DELETE_FILE_SUCCESS')
export const EXPEDIENT_DELETE_FILE_ERROR = Symbol('EXPEDIENT_DELETE_FILE_ERROR')

// #region SUBSTITUTE INVOICE
export const GET_INVOICES_REQUEST = Symbol('GET_INVOICES_REQUEST')
export const GET_INVOICES_SUCCESS = Symbol('GET_INVOICES_SUCCESS')
export const GET_INVOICES_ERROR = Symbol('GET_INVOICES_ERROR')

export const GET_INVOICE_REQUEST = Symbol('GET_INVOICE_REQUEST')
export const GET_INVOICE_SUCCESS = Symbol('GET_INVOICE_SUCCESS')
export const GET_INVOICE_ERROR = Symbol('GET_INVOICE_ERROR')

export const UPDATE_INVOICE_REQUEST = Symbol('UPDATE_INVOICE_REQUEST')
export const UPDATE_INVOICE_SUCCESS = Symbol('UPDATE_INVOICE_SUCCESS')
export const UPDATE_INVOICE_ERROR = Symbol('UPDATE_INVOICE_ERROR')


// #region PROMOTIONS
export const GET_PROMOTIONS_REQUEST = Symbol('GET_PROMOTIONS_REQUEST')
export const GET_PROMOTIONS_SUCCESS = Symbol('GET_PROMOTIONS_SUCCESS')
export const GET_PROMOTIONS_ERROR = Symbol('GET_PROMOTIONS_ERROR')

export const GET_PROMOTION_REQUEST = Symbol('GET_PROMOTIONS_REQUEST')
export const GET_PROMOTION_SUCCESS = Symbol('GET_PROMOTIONS_SUCCESS')
export const GET_PROMOTION_ERROR = Symbol('GET_PROMOTIONS_ERROR')

export const UPDATE_PROMOTION_REQUEST = Symbol('UPDATE_PROMOTION_REQUEST')
export const UPDATE_PROMOTION_SUCCESS = Symbol('UPDATE_PROMOTION_SUCCESS')
export const UPDATE_PROMOTION_ERROR = Symbol('UPDATE_PROMOTION_ERROR')

export const CREATE_PROMOTION_REQUEST = Symbol('CREATE_PROMOTION_REQUEST')
export const CREATE_PROMOTION_SUCCESS = Symbol('CREATE_PROMOTION_SUCCESS')
export const CREATE_PROMOTION_ERROR = Symbol('CREATE_PROMOTION_ERROR')

export const VALIDATE_PROMOTION_REQUEST = Symbol('VALIDATE_PROMOTION_REQUEST')
export const VALIDATE_PROMOTION_SUCCESS = Symbol('VALIDATE_PROMOTION_SUCCESS')
export const VALIDATE_PROMOTION_ERROR = Symbol('VALIDATE_PROMOTION_ERROR')

export const DELETE_INVOICE_REQUEST = Symbol('DELETED_INVOICE_REQUEST')
export const DELETE_INVOICE_SUCCESS = Symbol('DELETED_INVOICE_SUCCESS')
export const DELETE_INVOICE_ERROR = Symbol('DELETED_INVOICE_ERROR')

export const SEND_INVOICE_REQUEST = Symbol('SEND_INVOICE_REQUEST')
export const SEND_INVOICE_SUCCESS = Symbol('SEND_INVOICE_SUCCESS')
export const SEND_INVOICE_ERROR = Symbol('SEND_INVOICE_ERROR')

// #region USER INVOICE ACCOUNT
export const ADD_USER_INVOICE_ACCOUNT_REQUEST = Symbol('ADD_USER_INVOICE_ACCOUNT_REQUEST')
export const ADD_USER_INVOICE_ACCOUNT_SUCCESS = Symbol('ADD_USER_INVOICE_ACCOUNT_SUCCESS')
export const ADD_USER_INVOICE_ACCOUNT_ERROR = Symbol('ADD_USER_INVOICE_ACCOUNT_ERROR')

export const DELETE_USER_INVOICE_ACCOUNT_REQUEST = Symbol('DELETE_USER_INVOICE_ACCOUNT_REQUEST')
export const DELETE_USER_INVOICE_ACCOUNT_SUCCESS = Symbol('DELETE_USER_INVOICE_ACCOUNT_SUCCESS')
export const DELETE_USER_INVOICE_ACCOUNT_ERROR = Symbol('DELETE_USER_INVOICE_ACCOUNT_ERROR')

export const UPDATE_USER_INVOICE_ACCOUNT_REQUEST = Symbol('UPDATE_USER_INVOICE_ACCOUNT_REQUEST')
export const UPDATE_USER_INVOICE_ACCOUNT_SUCCESS = Symbol('UPDATE_USER_INVOICE_ACCOUNT_SUCCESS')
export const UPDATE_USER_INVOICE_ACCOUNT_ERROR = Symbol('UPDATE_USER_INVOICE_ACCOUNT_ERROR')

export const LOAD_USER_INVOICE_ACCOUNT_REQUEST = Symbol('LOAD_USER_INVOICE_ACCOUNT_REQUEST')
export const LOAD_USER_INVOICE_ACCOUNT_SUCCESS = Symbol('LOAD_USER_INVOICE_ACCOUNT_SUCCESS')
export const LOAD_USER_INVOICE_ACCOUNT_ERROR = Symbol('LOAD_USER_INVOICE_ACCOUNT_ERROR')

export const LOAD_EXPEDIENT_CREATOR_INVOICE_ACCOUNT_REQUEST = Symbol('LOAD_EXPEDIENT_CREATOR_INVOICE_ACCOUNT_REQUEST')
export const LOAD_EXPEDIENT_CREATOR_INVOICE_ACCOUNT_SUCCESS = Symbol('LOAD_EXPEDIENT_CREATOR_INVOICE_ACCOUNT_SUCCESS')
export const LOAD_EXPEDIENT_CREATOR_INVOICE_ACCOUNT_ERROR = Symbol('LOAD_EXPEDIENT_CREATOR_INVOICE_ACCOUNT_ERROR')

export const RESET_SEPA_AUTH_URL_REQUEST = Symbol('RESET_SEPA_AUTH_URL_REQUEST')
export const RESET_SEPA_AUTH_URL_SUCCESS = Symbol('RESET_SEPA_AUTH_URL_SUCCESS')
export const RESET_SEPA_AUTH_URL_ERROR = Symbol('RESET_SEPA_AUTH_URL_ERROR')

export const LOAD_PROJECT_SHORT_TERM_CREATOR_INVOICE_ACCOUNT_REQUEST = Symbol('LOAD_PROJECT_SHORT_TERM_CREATOR_INVOICE_ACCOUNT_REQUEST')
export const LOAD_PROJECT_SHORT_TERM_CREATOR_INVOICE_ACCOUNT_SUCCESS = Symbol('LOAD_PROJECT_SHORT_TERM_CREATOR_INVOICE_ACCOUNT_SUCCESS')
export const LOAD_PROJECT_SHORT_TERM_CREATOR_INVOICE_ACCOUNT_ERROR = Symbol('LOAD_PROJECT_SHORT_TERM_CREATOR_INVOICE_ACCOUNT_ERROR')

export const LOAD_PROJECT_LONG_TERM_CREATOR_INVOICE_ACCOUNT_REQUEST = Symbol('LOAD_PROJECT_LONG_TERM_CREATOR_INVOICE_ACCOUNT_REQUEST')
export const LOAD_PROJECT_LONG_TERM_CREATOR_INVOICE_ACCOUNT_SUCCESS = Symbol('LOAD_PROJECT_LONG_TERM_CREATOR_INVOICE_ACCOUNT_SUCCESS')
export const LOAD_PROJECT_LONG_TERM_CREATOR_INVOICE_ACCOUNT_ERROR = Symbol('LOAD_PROJECT_LONG_TERM_CREATOR_INVOICE_ACCOUNT_ERROR')

// #region REVIEWS
export const GET_EXPEDIENT_REVIEW_REQUEST = Symbol('GET_EXPEDIENT_REVIEW_REQUEST')
export const GET_EXPEDIENT_REVIEW_SUCCESS = Symbol('GET_EXPEDIENT_REVIEW_SUCCESS')
export const GET_EXPEDIENT_REVIEW_ERROR = Symbol('GET_EXPEDIENT_REVIEW_ERROR')

export const CREATE_SUBSTITUTE_REVIEW_REQUEST = Symbol('CREATE_SUBSTITUTE_REVIEW_REQUEST')
export const CREATE_SUBSTITUTE_REVIEW_SUCCESS = Symbol('CREATE_SUBSTITUTE_REVIEW_SUCCESS')
export const CREATE_SUBSTITUTE_REVIEW_ERROR = Symbol('CREATE_SUBSTITUTE_REVIEW_ERROR')

export const UPDATE_SUBSTITUTE_REVIEW_REQUEST = Symbol('UPDATE_SUBSTITUTE_REVIEW_REQUEST')
export const UPDATE_SUBSTITUTE_REVIEW_SUCCESS = Symbol('UPDATE_SUBSTITUTE_REVIEW_SUCCESS')
export const UPDATE_SUBSTITUTE_REVIEW_ERROR = Symbol('UPDATE_SUBSTITUTE_REVIEW_ERROR')

export const GET_SUBSTITUTE_REVIEWS_REQUEST = Symbol('GET_SUBSTITUTE_REVIEWS_REQUEST')
export const GET_SUBSTITUTE_REVIEWS_SUCCESS = Symbol('GET_SUBSTITUTE_REVIEWS_SUCCESS')
export const GET_SUBSTITUTE_REVIEWS_ERROR = Symbol('GET_SUBSTITUTE_REVIEWS_ERROR')

// #region JUDICIAL BRIEFS
export const GET_JUDICIAL_BRIEF_REQUEST = Symbol('GET_JUDICIAL_BRIEF_REQUEST')
export const GET_JUDICIAL_BRIEF_SUCCESS = Symbol('GET_JUDICIAL_BRIEF_SUCCESS')
export const GET_JUDICIAL_BRIEF_ERROR = Symbol('GET_JUDICIAL_BRIEF_ERROR')

export const GET_JUDICIAL_BRIEFS_REQUEST = Symbol('GET_JUDICIAL_BRIEFS_REQUEST')
export const GET_JUDICIAL_BRIEFS_SUCCESS = Symbol('GET_JUDICIAL_BRIEFS_SUCCESS')
export const GET_JUDICIAL_BRIEFS_ERROR = Symbol('GET_JUDICIAL_BRIEFS_ERROR')

export const CREATE_JUDICIAL_BRIEF_REQUEST = Symbol('CREATE_JUDICIAL_BRIEF_REQUEST')
export const CREATE_JUDICIAL_BRIEF_SUCCESS = Symbol('CREATE_JUDICIAL_BRIEF_SUCCESS')
export const CREATE_JUDICIAL_BRIEF_ERROR = Symbol('CREATE_JUDICIAL_BRIEF_ERROR')

export const UPDATE_JUDICIAL_BRIEF_REQUEST = Symbol('UPDATE_JUDICIAL_BRIEF_REQUEST')
export const UPDATE_JUDICIAL_BRIEF_SUCCESS = Symbol('UPDATE_JUDICIAL_BRIEF_SUCCESS')
export const UPDATE_JUDICIAL_BRIEF_ERROR = Symbol('UPDATE_JUDICIAL_BRIEF_ERROR')

export const DELETE_JUDICIAL_BRIEF_REQUEST = Symbol('DELETE_JUDICIAL_BRIEF_REQUEST')
export const DELETE_JUDICIAL_BRIEF_SUCCESS = Symbol('DELETE_JUDICIAL_BRIEF_SUCCESS')
export const DELETE_JUDICIAL_BRIEF_ERROR = Symbol('DELETE_JUDICIAL_BRIEF_ERROR')

// #region REMITTANCES
export const GET_REMITTANCES_REQUEST = Symbol('GET_REMITTANCES_REQUEST')
export const GET_REMITTANCES_SUCCESS = Symbol('GET_REMITTANCES_SUCCESS')
export const GET_REMITTANCES_ERROR = Symbol('GET_REMITTANCES_ERROR')

export const SET_REMITTANCE_PROCESSED_REQUEST = Symbol('SET_REMITTANCE_PROCESSED_REQUEST')
export const SET_REMITTANCE_PROCESSED_SUCCESS = Symbol('SET_REMITTANCE_PROCESSED_SUCCESS')
export const SET_REMITTANCE_PROCESSED_ERROR = Symbol('SET_REMITTANCE_PROCESSED_ERROR')

export const REPEAT_REMITTANCE_REQUEST = Symbol('REPEAT_REMITTANCE_REQUEST')
export const REPEAT_REMITTANCE_SUCCESS = Symbol('REPEAT_REMITTANCE_SUCCESS')
export const REPEAT_REMITTANCE_ERROR = Symbol('REPEAT_REMITTANCE_ERROR')

export const REPEAT_INVOICE_REMITTANCE_REQUEST = Symbol('REPEAT_INVOICE_REMITTANCE_REQUEST')
export const REPEAT_INVOICE_REMITTANCE_SUCCESS = Symbol('REPEAT_INVOICE_REMITTANCE_SUCCESS')
export const REPEAT_INVOICE_REMITTANCE_ERROR = Symbol('REPEAT_INVOICE_REMITTANCE_ERROR')

export const ROLLBACK_REMITTANCE_REQUEST = Symbol('ROLLBACK_REMITTANCE_REQUEST')
export const ROLLBACK_REMITTANCE_SUCCESS = Symbol('ROLLBACK_REMITTANCE_SUCCESS')
export const ROLLBACK_REMITTANCE_ERROR = Symbol('ROLLBACK_REMITTANCE_ERROR')

export const ROLLBACK_INVOICE_REMITTANCE_REQUEST = Symbol('ROLLBACK_REMITTANCE_REQUEST')
export const ROLLBACK_INVOICE_REMITTANCE_SUCCESS = Symbol('ROLLBACK_REMITTANCE_SUCCESS')
export const ROLLBACK_INVOICE_REMITTANCE_ERROR = Symbol('ROLLBACK_REMITTANCE_ERROR')

// #region PUBLIC API
export const GET_API_KEY_REQUEST = Symbol('GET_API_KEY_REQUEST')
export const GET_API_KEY_SUCCESS = Symbol('GET_API_KEY_SUCCESS')
export const GET_API_KEY_ERROR = Symbol('GET_API_KEY_ERROR')

export const GET_LAST_API_KEY_DATE_REQUEST = Symbol('GET_LAST_API_KEY_DATE_REQUEST')
export const GET_LAST_API_KEY_DATE_SUCCESS = Symbol('GET_LAST_API_KEY_DATE_SUCCESS')
export const GET_LAST_API_KEY_DATE_ERROR = Symbol('GET_LAST_API_KEY_DATE_ERROR')

export const DELETE_LAST_API_KEY_DATE_REQUEST = Symbol('DELETE_LAST_API_KEY_DATE_REQUEST')
export const DELETE_LAST_API_KEY_DATE_SUCCESS = Symbol('DELETE_LAST_API_KEY_DATE_SUCCESS')
export const DELETE_LAST_API_KEY_DATE_ERROR = Symbol('DELETE_LAST_API_KEY_DATE_ERROR')

export const RESET_API_KEY = Symbol('RESET_API_KEY')

// #region NOTARY
export const CREATE_NOTARY_REQUEST = Symbol('CREATE_NOTARY_REQUEST')
export const CREATE_NOTARY_SUCCESS = Symbol('CREATE_NOTARY_SUCCESS')
export const CREATE_NOTARY_ERROR = Symbol('CREATE_NOTARY_ERROR')

// #region ISSUE
export const GET_ISSUE_REQUEST = Symbol('GET_ISSUE_REQUEST')
export const GET_ISSUE_SUCCESS = Symbol('GET_ISSUE_SUCCESS')
export const GET_ISSUE_ERROR = Symbol('GET_ISSUE_ERROR')

export const CREATE_ISSUE_REQUEST = Symbol('CREATE_ISSUE_REQUEST')
export const CREATE_ISSUE_SUCCESS = Symbol('CREATE_ISSUE_SUCCESS')
export const CREATE_ISSUE_ERROR = Symbol('CREATE_ISSUE_ERROR')

export const UPDATE_ISSUE_REQUEST = Symbol('UPDATE_ISSUE_REQUEST')
export const UPDATE_ISSUE_SUCCESS = Symbol('UPDATE_ISSUE_SUCCESS')
export const UPDATE_ISSUE_ERROR = Symbol('UPDATE_ISSUE_ERROR')

// #region SHORT_TERM_PROJECT
export const CREATE_PROJECT_SHORT_TERM_REQUEST = Symbol('CREATE_PROJECT_SHORT_TERM_REQUEST')
export const CREATE_PROJECT_SHORT_TERM_SUCCESS = Symbol('CREATE_PROJECT_SHORT_TERM_SUCCESS')
export const CREATE_PROJECT_SHORT_TERM_ERROR = Symbol('CREATE_PROJECT_SHORT_TERM_ERROR')

export const LOAD_PROJECT_SHORT_TERM_REQUEST = Symbol('LOAD_PROJECT_SHORT_TERM_REQUEST')
export const LOAD_PROJECT_SHORT_TERM_SUCCESS = Symbol('LOAD_PROJECT_SHORT_TERM_SUCCESS')
export const LOAD_PROJECT_SHORT_TERM_ERROR = Symbol('LOAD_PROJECT_SHORT_TERM_ERROR')

export const LOAD_PROJECTS_SHORT_TERM_REQUEST = Symbol('LOAD_PROJECTS_SHORT_TERM_REQUEST')
export const LOAD_PROJECTS_SHORT_TERM_SUCCESS = Symbol('LOAD_PROJECTS_SHORT_TERM_SUCCESS')
export const LOAD_PROJECTS_SHORT_TERM_ERROR = Symbol('LOAD_PROJECTS_SHORT_TERM_ERROR')

export const LOAD_ASSIGNED_PROJECTS_SHORT_TERM_REQUEST = Symbol('LOAD_ASSIGNED_PROJECTS_SHORT_TERM_REQUEST')
export const LOAD_ASSIGNED_PROJECTS_SHORT_TERM_SUCCESS = Symbol('LOAD_ASSIGNED_PROJECTS_SHORT_TERM_SUCCESS')
export const LOAD_ASSIGNED_PROJECTS_SHORT_TERM_ERROR = Symbol('LOAD_ASSIGNED_PROJECTS_SHORT_TERM_ERROR')

export const UPDATE_PROJECT_SHORT_TERM_REQUEST = Symbol('UPDATE_PROJECT_SHORT_TERM_REQUEST')
export const UPDATE_PROJECT_SHORT_TERM_SUCCESS = Symbol('UPDATE_PROJECT_SHORT_TERM_SUCCESS')
export const UPDATE_PROJECT_SHORT_TERM_ERROR = Symbol('UPDATE_PROJECT_SHORT_TERM_ERROR')

export const DELETE_PROJECT_SHORT_TERM_REQUEST = Symbol('DELETE_PROJECT_SHORT_TERM_REQUEST')
export const DELETE_PROJECT_SHORT_TERM_SUCCESS = Symbol('UDELETEPROJECT_SHORT_TERM_SUCCESS')
export const DELETE_PROJECT_SHORT_TERM_ERROR = Symbol('DELETE_PROJECT_SHORT_TERM_ERROR')

export const PROJECT_SHORT_DOC_RECLAMATION_REQUEST = Symbol('PROJECT_SHORT_DOC_RECLAMATION_REQUEST')
export const PROJECT_SHORT_DOC_RECLAMATION_SUCCESS = Symbol('PROJECT_SHORT_DOC_RECLAMATION_SUCCESS')
export const PROJECT_SHORT_DOC_RECLAMATION_ERROR = Symbol('PROJECT_SHORT_DOC_RECLAMATION_ERROR')

export const REASSING_PROJECTST_REQUEST = Symbol('REASSING_PROJECTST_REQUEST')
export const REASSING_PROJECTST_SUCCESS = Symbol('REASSING_PROJECTST_SUCCESS')
export const REASSING_PROJECTST_ERROR = Symbol('REASSING_PROJECTST_ERROR')

export const PROJECT_SHORT_TERM_DELETE_FILE_REQUEST = Symbol('PROJECT_SHORT_TERM_DELETE_FILE_REQUEST')
export const PROJECT_SHORT_TERM_DELETE_FILE_SUCCESS = Symbol('PROJECT_SHORT_TERM_DELETE_FILE_SUCCESS')
export const PROJECT_SHORT_TERM_DELETE_FILE_ERROR = Symbol('PROJECT_SHORT_TERM_DELETE_FILE_ERROR')

// #region CAPABILITIES
export const CREATE_CAPABILITIES_REQUEST = Symbol('CREATE_CAPABILITIES_REQUEST')
export const CREATE_CAPABILITIES_SUCCESS = Symbol('CREATE_CAPABILITIES_SUCCESS')
export const CREATE_CAPABILITIES_ERROR = Symbol('CREATE_CAPABILITIES_ERROR')

// #region LONG_TERM_PROJECT
export const CREATE_PROJECT_LONG_TERM_REQUEST = Symbol('CREATE_PROJECT_LONG_TERM_REQUEST')
export const CREATE_PROJECT_LONG_TERM_SUCCESS = Symbol('CREATE_PROJECT_LONG_TERM_SUCCESS')
export const CREATE_PROJECT_LONG_TERM_ERROR = Symbol('CREATE_PROJECT_LONG_TERM_ERROR')

export const LOAD_PROJECT_LONG_TERM_REQUEST = Symbol('LOAD_PROJECT_LONG_TERM_REQUEST')
export const LOAD_PROJECT_LONG_TERM_SUCCESS = Symbol('LOAD_PROJECT_LONG_TERM_SUCCESS')
export const LOAD_PROJECT_LONG_TERM_ERROR = Symbol('LOAD_PROJECT_LONG_TERM_ERROR')

export const LOAD_PROJECTS_LONG_TERM_REQUEST = Symbol('LOAD_PROJECTS_LONG_TERM_REQUEST')
export const LOAD_PROJECTS_LONG_TERM_SUCCESS = Symbol('LOAD_PROJECTS_LONG_TERM_SUCCESS')
export const LOAD_PROJECTS_LONG_TERM_ERROR = Symbol('LOAD_PROJECTS_LONG_TERM_ERROR')

export const LOAD_ASSIGNED_PROJECTS_LONG_TERM_REQUEST = Symbol('LOAD_ASSIGNED_PROJECTS_LONG_TERM_REQUEST')
export const LOAD_ASSIGNED_PROJECTS_LONG_TERM_SUCCESS = Symbol('LOAD_ASSIGNED_PROJECTS_LONG_TERM_SUCCESS')
export const LOAD_ASSIGNED_PROJECTS_LONG_TERM_ERROR = Symbol('LOAD_ASSIGNED_PROJECTS_LONG_TERM_ERROR')

export const UPDATE_PROJECT_LONG_TERM_REQUEST = Symbol('UPDATE_PROJECT_LONG_TERM_REQUEST')
export const UPDATE_PROJECT_LONG_TERM_SUCCESS = Symbol('UPDATE_PROJECT_LONG_TERM_SUCCESS')
export const UPDATE_PROJECT_LONG_TERM_ERROR = Symbol('UPDATE_PROJECT_LONG_TERM_ERROR')

export const DELETE_PROJECT_LONG_TERM_REQUEST = Symbol('DELETE_PROJECT_LONG_TERM_REQUEST')
export const DELETE_PROJECT_LONG_TERM_SUCCESS = Symbol('UDELETEPROJECT_LONG_TERM_SUCCESS')
export const DELETE_PROJECT_LONG_TERM_ERROR = Symbol('DELETE_PROJECT_LONG_TERM_ERROR')

export const PROJECT_LONG_DOC_RECLAMATION_REQUEST = Symbol('PROJECT_LONG_DOC_RECLAMATION_REQUEST')
export const PROJECT_LONG_DOC_RECLAMATION_SUCCESS = Symbol('PROJECT_LONG_DOC_RECLAMATION_SUCCESS')
export const PROJECT_LONG_DOC_RECLAMATION_ERROR = Symbol('PROJECT_LONG_DOC_RECLAMATION_ERROR')

export const REASSING_PROJECTLT_REQUEST = Symbol('REASSING_PROJECTLT_REQUEST')
export const REASSING_PROJECTLT_SUCCESS = Symbol('REASSING_PROJECTLT_SUCCESS')
export const REASSING_PROJECTLT_ERROR = Symbol('REASSING_PROJECTLT_ERROR')

export const APPLY_PROJECT_LONG_TERM_REQUEST = Symbol('APPLY_PROJECT_LONG_TERM_REQUEST')
export const APPLY_PROJECT_LONG_TERM_SUCCESS = Symbol('APPLY_PROJECT_LONG_TERM_SUCCESS')
export const APPLY_PROJECT_LONG_TERM_ERROR = Symbol('APPLY_PROJECT_LONG_TERM_ERROR')

export const ASSIGN_PROJECT_LONG_TERM_REQUEST = Symbol('ASSIGN_PROJECT_LONG_TERM_REQUEST')
export const ASSIGN_PROJECT_LONG_TERM_SUCCESS = Symbol('ASSIGN_PROJECT_LONG_TERM_SUCCESS')
export const ASSIGN_PROJECT_LONG_TERM_ERROR = Symbol('ASSIGN_PROJECT_LONG_TERM_ERROR')

export const CREATE_PROJECT_LONG_MEETING_REQUEST = Symbol('CREATE_PROJECT_LONG_MEETING_REQUEST')
export const CREATE_PROJECT_LONG_MEETING_SUCCESS = Symbol('CREATE_PROJECT_LONG_MEETING_SUCCESS')
export const CREATE_PROJECT_LONG_MEETING_ERROR = Symbol('CREATE_PROJECT_LONG_MEETING_ERROR')

// #region PROCURA EXPEDIENT
export const CREATE_PROCURA_REQUEST = Symbol('CREATE_PROCURA_REQUEST')
export const CREATE_PROCURA_SUCCESS = Symbol('CREATE_PROCURA_SUCCESS')
export const CREATE_PROCURA_ERROR = Symbol('CREATE_PROCURA_ERROR')

export const LOAD_PROCURAS_REQUEST = Symbol('LOAD_PROCURAS_REQUEST')
export const LOAD_PROCURAS_SUCCESS = Symbol('LOAD_PROCURAS_SUCCESS')
export const LOAD_PROCURAS_ERROR = Symbol('LOAD_PROCURAS_ERROR')

export const LOAD_PROCURA_REQUEST = Symbol('LOAD_PROCURA_REQUEST')
export const LOAD_PROCURA_SUCCESS = Symbol('LOAD_PROCURA_SUCCESS')
export const LOAD_PROCURA_ERROR = Symbol('LOAD_PROCURA_ERROR')

export const LOAD_ASSIGNED_PROCURA_REQUEST = Symbol('LOAD_ASSIGNED_PROCURA_REQUEST')
export const LOAD_ASSIGNED_PROCURA_SUCCESS = Symbol('LOAD_ASSIGNED_PROCURA_SUCCESS')
export const LOAD_ASSIGNED_PROCURA_ERROR = Symbol('LOAD_ASSIGNED_PROCURA_ERROR')

export const RESET_CREATED_PROCURA = Symbol('RESET_CREATED_PROCURA')
export const CLEAR_PROCURA = Symbol('CLEAR_PROCURA')

export const UPDATE_PROCURA_REQUEST = Symbol('UPDATE_PROCURA_REQUEST')
export const UPDATE_PROCURA_SUCCESS = Symbol('UPDATE_PROCURA_SUCCESS')
export const UPDATE_PROCURA_ERROR = Symbol('UPDATE_PROCURA_ERROR')

export const UPDATE_PROCURA_REPORT_REQUEST = Symbol('UPDATE_PROCURA_REPORT_REQUEST')
export const UPDATE_PROCURA_REPORT_SUCCESS = Symbol('UPDATE_PROCURA_REPORT_SUCCESS')
export const UPDATE_PROCURA_REPORT_ERROR = Symbol('UPDATE_PROCURA_REPORT_ERROR')

export const LOAD_PROCURA_DRAFTS_REQUEST = Symbol('LOAD_PROCURA_DRAFTS_REQUEST')
export const LOAD_PROCURA_DRAFTS_SUCCESS = Symbol('LOAD_PROCURA_DRAFTS_SUCCESS')
export const LOAD_PROCURA_DRAFTS_ERROR = Symbol('LOAD_PROCURA_DRAFTS_ERROR')

export const PROCURA_DELETED_REQUEST = Symbol('PROCURA_DELETED_REQUEST')
export const PROCURA_DELETED_SUCCESS = Symbol('PROCURA_DELETED_SUCCESS')
export const PROCURA_DELETED_ERROR = Symbol('PROCURA_DELETED_ERROR')

export const PROCURA_DOC_REMINDER_REQUEST = Symbol('PROCURA_DOC_REMINDER_REQUEST')
export const PROCURA_DOC_REMINDER_SUCCESS = Symbol('PROCURA_DOC_REMINDER_SUCCESS')
export const PROCURA_DOC_REMINDER_ERROR = Symbol('PROCURA_DOC_REMINDER_ERROR')

export const REASSING_PROCURA_REQUEST = Symbol('REASSING_PROCURA_REQUEST')
export const REASSING_PROCURA_SUCCESS = Symbol('REASSING_PROCURA_SUCCESS')
export const REASSING_PROCURA_ERROR = Symbol('REASSING_PROCURA_ERROR')

export const PROCURA_NOTIFICATION_REQUEST = Symbol('PROCURA_NOTIFICATION_REQUEST')
export const PROCURA_NOTIFICATION_SUCCESS = Symbol('PROCURA_NOTIFICATION_SUCCESS')
export const PROCURA_NOTIFICATION_ERROR = Symbol('PROCURA_NOTIFICATION_ERROR')

export const PROCURA_DELETE_FILE_REQUEST = Symbol('PROCURA_DELETE_FILE_REQUEST')
export const PROCURA_DELETE_FILE_SUCCESS = Symbol('PROCURA_DELETE_FILE_SUCCESS')
export const PROCURA_DELETE_FILE_ERROR = Symbol('PROCURA_DELETE_FILE_ERROR')

// #region DRAFTS
export const LOAD_PROJECTST_DRAFTS_REQUEST = Symbol('LOAD_PROJECTST_DRAFTS_REQUEST')
export const LOAD_PROJECTST_DRAFTS_SUCCESS = Symbol('LOAD_PROJECTST_DRAFTS_SUCCESS')
export const LOAD_PROJECTST_DRAFTS_ERROR = Symbol('LOAD_PROJECTST_DRAFTS_ERROR')

// #region POSSESSIONS
export const CREATE_POSSESSION_REQUEST = Symbol('CREATE_POSSESSION_REQUEST')
export const CREATE_POSSESSION_SUCCESS = Symbol('CREATE_POSSESSION_SUCCESS')
export const CREATE_POSSESSION_ERROR = Symbol('CREATE_POSSESSION_ERROR')

// #region COMMUNITY_PARTNER
export const GET_COMMUNITY_PARTNERS_REQUEST = Symbol('GET_COMMUNITY_PARTNERS_REQUEST');
export const GET_COMMUNITY_PARTNERS_SUCCESS = Symbol('GET_COMMUNITY_PARTNERS_SUCCESS');
export const GET_COMMUNITY_PARTNERS_ERROR = Symbol('GET_COMMUNITY_PARTNERS_ERROR');

export const GET_COMMUNITY_PARTNER_REQUEST = Symbol('GET_COMMUNITY_PARTNER_REQUEST');
export const GET_COMMUNITY_PARTNER_SUCCESS = Symbol('GET_COMMUNITY_PARTNER_SUCCESS');
export const GET_COMMUNITY_PARTNER_ERROR = Symbol('GET_COMMUNITY_PARTNER_ERROR');

export const CREATE_COMMUNITY_PARTNER_REQUEST = Symbol('CREATE_COMMUNITY_PARTNER_REQUEST');
export const CREATE_COMMUNITY_PARTNER_SUCCESS = Symbol('CREATE_COMMUNITY_PARTNER_SUCCESS');
export const CREATE_COMMUNITY_PARTNER_ERROR = Symbol('CREATE_COMMUNITY_PARTNER_ERROR');

export const UPDATE_COMMUNITY_PARTNER_REQUEST = Symbol('UPDATE_COMMUNITY_PARTNER_REQUEST');
export const UPDATE_COMMUNITY_PARTNER_SUCCESS = Symbol('UPDATE_COMMUNITY_PARTNER_SUCCESS');
export const UPDATE_COMMUNITY_PARTNER_ERROR = Symbol('UPDATE_COMMUNITY_PARTNER_ERROR');

export const DELETE_COMMUNITY_PARTNER_REQUEST = Symbol('DELETE_COMMUNITY_PARTNER_REQUEST');
export const DELETE_COMMUNITY_PARTNER_SUCCESS = Symbol('DELETE_COMMUNITY_PARTNER_SUCCESS');
export const DELETE_COMMUNITY_PARTNER_ERROR = Symbol('DELETE_COMMUNITY_PARTNER_ERROR');

// #region SPECIAL PRICES
export const GET_SPECIAL_PRICES_REQUEST = Symbol('GET_SPECIAL_PRICES_REQUEST')
export const GET_SPECIAL_PRICES_SUCCESS = Symbol('GET_SPECIAL_PRICES_SUCCESS')
export const GET_SPECIAL_PRICES_ERROR = Symbol('GET_SPECIAL_PRICES_ERROR')

export const GET_SPECIAL_PRICE_REQUEST = Symbol('GET_SPECIAL_PRICE_REQUEST')
export const GET_SPECIAL_PRICE_SUCCESS = Symbol('GET_SPECIAL_PRICE_SUCCESS')
export const GET_SPECIAL_PRICE_ERROR = Symbol('GET_SPECIAL_PRICE_ERROR')

export const CREATE_SPECIAL_PRICE_REQUEST = Symbol('CREATE_SPECIAL_PRICE_REQUEST')
export const CREATE_SPECIAL_PRICE_SUCCESS = Symbol('CREATE_SPECIAL_PRICE_SUCCESS')
export const CREATE_SPECIAL_PRICE_ERROR = Symbol('CREATE_SPECIAL_PRICE_ERROR')

export const UPDATE_SPECIAL_PRICE_REQUEST = Symbol('UPDATE_SPECIAL_PRICE_REQUEST')
export const UPDATE_SPECIAL_PRICE_SUCCESS = Symbol('UPDATE_SPECIAL_PRICE_SUCCESS')
export const UPDATE_SPECIAL_PRICE_ERROR = Symbol('UPDATE_SPECIAL_PRICE_ERROR')

export const DELETE_SPECIAL_PRICE_REQUEST = Symbol('DELETE_SPECIAL_PRICE_REQUEST')
export const DELETE_SPECIAL_PRICE_SUCCESS = Symbol('DELETE_SPECIAL_PRICE_SUCCESS')
export const DELETE_SPECIAL_PRICE_ERROR = Symbol('DELETE_SPECIAL_PRICE_ERROR')

// #region URGENT ISSUES
export const GET_URGENT_ISSUES_LIST_REQUEST = Symbol('GET_URGENT_ISSUES_LIST_REQUEST')
export const GET_URGENT_ISSUES_LIST_SUCCESS = Symbol('GET_URGENT_ISSUES_LIST_SUCCESS')
export const GET_URGENT_ISSUES_LIST_ERROR = Symbol('GET_URGENT_ISSUES_LIST_ERROR')