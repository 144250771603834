//prettier-ignore
const config = {
  baseAPI: process.env.REACT_APP_BASE_API || 'http://localhost:3000',
  STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY || 'pk_test_i0J1my6uIS8WYN6M6xlB3XDw00ARxIKz8y',
  GTM: process.env.REACT_APP_GTM,// 'GTM-5QR9Q7G'
  MIXPANEL_TOKEN: process.env.REACT_APP_MIXPANEL_TOKEN || 'a2c139df35d31759e8ab5f4b77faccc4',
  MAINTENANCE: process.env.REACT_APP_MAINTENANCE || false,
  AWS_COGNITO_DOMAIN: process.env.REACT_APP_AWS_COGNITO_DOMAIN || 'https://legalpigeonsandbox.auth.eu-west-3.amazoncognito.com',
  AWS_COGNITO_CLIENT_ID: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID || '5oalpd3qvjmpu0b5jq5a7filu5',
  AWS_REDIRECT_URI_SIGNUP: process.env.REACT_APP_AWS_AWS_COGNITO_REDIRECT_URI_SIGNUP || 'http://localhost:3001/client-signup',
  AWS_REDIRECT_URI_LOGIN: process.env.REACT_APP_AWS_COGNITO_REDIRECT_URI_LOGIN || 'http://localhost:3001/login',
  AWS_REDIRECT_URI_LOGOUT: process.env.REACT_APP_AWS_COGNITO_REDIRECT_URI_LOGOUT || 'http://localhost:3001/logout',
  ENABLE_SOCIAL_LOGIN: process.env.REACT_APP_ENABLE_SOCIAL_LOGIN || false,
  SHOW_PROFILE_IMAGE: process.env.REACT_APP_SHOW_PROFILE_IMAGE || false,
  ENABLE_TELEMATIC_EXPEDIENTS: process.env.REACT_APP_ENABLE_TELEMATIC_EXPEDIENTS || false,
  ENABLE_PIGEON_IN_HOUSE: process.env.REACT_APP_ENABLE_PIGEON_IN_HOUSE || false,
  USERS_ASSUMPTION_ENABLED: process.env.REACT_APP_USERS_ASSUMPTION_ENABLED?.split(',').filter(Boolean) || []
}

module.exports = config
