import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createMiddleware } from 'redux-api-middleware'
import rootReducer from '../reducers/index'
import { createLogger } from 'redux-logger'

const logger = createLogger({
  level: 'info',
  collapsed: false,
  logger: console,
  predicate: (getState, action) => true,
})

const apiMiddleware = createMiddleware()

const createStoreWithMiddleware =
  process.env.NODE_ENV === 'production'
    ? applyMiddleware(thunkMiddleware, apiMiddleware)(createStore)
    : applyMiddleware(thunkMiddleware, apiMiddleware, logger)(createStore)

const configureStore = () => {
  const store = createStoreWithMiddleware(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('../reducers/index', () => {
        store.replaceReducer(rootReducer)
      })
    }
  }

  return store
}

export default configureStore
