import React from 'react'
import { injectStripe, CardElement } from 'react-stripe-elements'
import { Modal, ModalBody, ModalHeader, Button } from 'reactstrap'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { updateUser } from '../redux/actions/users'
import { baseAPI } from '../config'
import * as Icon from 'react-feather'
import { IoMdClose } from 'react-icons/io'

class CheckoutModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showSaveCards: true }
  }

  paymentSaveCard = async (paymentMethodId) => {
    const { closeCheckoutModal, createExpedient, auth, isUrgentHearing } =
      this.props

    fetch(`${baseAPI}/charge-save-card`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id: auth.activeUser._id,
        payment_method_id: paymentMethodId,
        isUrgentHearing,
      }),
    })
      .then(async (res) => {
        if (res.status === 200) return res.json()
      })
      .then((res) => {
        if (res.error) {
          toast.error(res.error.message)
          return closeCheckoutModal()
        }
        res.is_urgent_hearing = isUrgentHearing
        createExpedient(res.paymentIntent)
      })
      .catch((err) => {
        // console.log(err)
        return toast.error('Algo salió mal')
      })
  }

  updateUser = (e, errors, values) => {
    this.props.updateUser({ user: values, id: this.props.auth.activeUser._id })
  }

  handleSubmit = (ev) => {
    ev.preventDefault()
    const {
      stripe,
      elements,
      checkoutIntent,
      closeCheckoutModal,
      createExpedient,
      auth,
      isUrgentHearing,
    } = this.props

    stripe
      .confirmCardPayment(checkoutIntent.client_secret, {
        payment_method: {
          card: elements.getElement('card'),
          billing_details: {
            email: auth.activeUser.email,
            name: auth.activeUser.name,
          },
        },
        setup_future_usage: 'off_session',
      })
      .then((res) => {
        if (res.error) {
          toast.error(res.error.message)
          return closeCheckoutModal()
        }
        res.is_urgent_hearing = isUrgentHearing
        createExpedient(res.paymentIntent)
      })
      .catch((err) => {
        toast.error('Se ha produccido un error')
        return closeCheckoutModal()
      })
  }

  closeCheckoutModal = (ev) => {
    ev.preventDefault()
    const { closeCheckoutModal } = this.props
    return closeCheckoutModal()
  }

  render() {
    const { show, price = 95, auth, cards, isUrgentHearing } = this.props
    const { activeUser } = auth

    const vat =
      activeUser.role !== 'admin'
        ? isUrgentHearing
          ? (price + (activeUser.urgent_hearing_extra_price || 30)) * 0.21
          : price * 0.21
        : price * 0.21

    const basePrice = price
    const hearingExtraPrice = activeUser.urgent_hearing_extra_price || 30

    const total =
      activeUser.role !== 'admin'
        ? isUrgentHearing
          ? price + (activeUser.urgent_hearing_extra_price || 30) + vat
          : price + vat
        : price + vat

    return (
      <Modal isOpen={show} className="checkout-modal">
        <div className="d-flex justify-content-end">
          <button
            className="m-1 p-0 btn btn-danger d-flex justify-content-center align-items-center "
            style={{ width: '15px', height: '15px' }}
          >
            <IoMdClose onClick={this.closeCheckoutModal} />
          </button>
        </div>

        <ModalHeader>
          <div className="h3 mb-0">Pago</div>
        </ModalHeader>

        <ModalBody>
          <h4 className="blue2">Resumen</h4>

          <div className="card">
            <div className="card-body">
              <dl className="d-flex">
                <dt>Precio:</dt>
                <dd className="ml-auto">{basePrice}€</dd>
              </dl>
              {isUrgentHearing && hearingExtraPrice > 0 && (
                <dl className="d-flex">
                  <dt>Extra Encargo Urgente:</dt>
                  <dd className="ml-auto">{hearingExtraPrice}€</dd>
                </dl>
              )}
              <dl className="d-flex">
                <dt>IVA (21%):</dt>
                <dd className="ml-auto">{vat}€</dd>
              </dl>
              <hr />
              <dl className="d-flex">
                <dt>Total:</dt>
                <dd className="ml-auto text-dark b">
                  <strong>{total}€</strong>
                </dd>
              </dl>
            </div>
          </div>
          <div style={{ display: `${!cards.length ? 'none' : ''}` }}>
            <h4 className="blue2 mb-0">Pague con una de sus tarjetas</h4>
            <ul className="col-md-8 col-12">
              {this.props.cards.map((card) => (
                <li
                  key={card.pmId}
                  className="my-3 d-flex flex-column"
                  style={{ listStyle: 'none' }}
                >
                  <Button
                    className="btn btn-success d-flex justify-content-between paySaveCardStripe"
                    onClick={() => this.paymentSaveCard(card.pmId)}
                  >
                    <Icon.CreditCard />
                    <div>{card.brand}</div>
                    {` termina en ${card.last4}`}
                  </Button>
                </li>
              ))}
            </ul>

            <Button
              style={{ display: `${this.state.showSaveCards ? '' : 'none'}` }}
              onClick={() => this.setState({ showSaveCards: false })}
              size="lg"
            >
              Añadir tarjeta
            </Button>
            <Button
              style={{ display: `${this.state.showSaveCards ? '' : 'none'}` }}
              onClick={this.closeCheckoutModal}
              size="lg"
              className=" text-center btn btn-danger m-1 sm-m-t-1"
            >
              Salir
            </Button>
          </div>

          <form
            style={{
              display: `${
                this.state.showSaveCards && !!this.props.cards.length
                  ? 'none'
                  : ''
              }`,
            }}
            onSubmit={this.handleSubmit}
          >
            <h4 className="blue2 mb-0">Tarjeta</h4>
            <small className="mb-3">
              (Número de tarjeta, fecha de caducidad, CVC, código postal)
            </small>

            <div className="mt-3">
              <CardElement className="MyCardElement" style={style} />
            </div>

            <div className="d-flex flex-column flex-md-row justify-content-md-center m-3">
              <button
                id="oldFlowPayStripe"
                className="text-center btn btn-success m-1 sm-m-t-1 btn-lg"
              >
                Pagar de forma segura
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default injectStripe(
  connect(mapStateToProps, { updateUser })(CheckoutModal)
)

const style = {
  base: {
    color: '#32325d',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
  },
}
