import React from 'react'
import { Link } from 'react-router-dom'
import LogoLandscape from '../../assets/images/logo-landscape.png'
import { connect } from 'react-redux'

class LightHeader extends React.Component {
  render() {
    const { activeUser } = this.props.auth

    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light mb-4" style={{padding: '0.2rem 1.25rem'}}>
        <div className='container'>
          <Link className="navbar-brand" to='/'>
            <img src={LogoLandscape}  height='38' alt='LegalPigeon' />
          </Link>
          <div className="my-1 my-lg-0">
            {
              activeUser && activeUser._id
                ? <Link to='/panel'>Panel de control</Link>
                : <Link to='/login'>Login</Link>
            }
          </div>
        </div>
      </nav>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps)(LightHeader)