import * as types from '../constants'
import { toast } from 'react-toastify'

const defaultState = {
  projectLongTerm: {},
  projectsLongTerm: [],
  created: {},
  assigned: {},
}

export default function (state = defaultState, action) {
  switch (action.type) {
    case types.CREATE_PROJECT_LONG_TERM_SUCCESS:
      toast.success('Un proyecto para usted ha sido creado.')

      return {
        ...state,
        created: action.payload.projectst,
      }

    case types.CREATE_PROJECT_LONG_TERM_ERROR:
      if (action.payload.status === 404) {
        toast.error(
          'No tenemos colaboradores disponibles para este tipo de encargo. Contacta con el administrador.'
        )
      } else {
        toast.error(
          'Ha ocurrido un error, si persiste contacte con el administrador'
        )
      }

      return state

    case types.LOAD_PROJECTS_LONG_TERM_SUCCESS:
      return {
        ...state,
        projectsLongTerm: action.payload,
      }

    case types.LOAD_PROJECTS_LONG_TERM_ERROR:
      toast.error(
        'Ha ocurrido un error, si persiste contacte con el administrador'
      )

      return state

    case types.LOAD_ASSIGNED_PROJECTS_LONG_TERM_SUCCESS:
      return {
        ...state,
        assigned: action.payload,
      }

    case types.LOAD_ASSIGNED_PROJECTS_LONG_TERM_ERROR:
      toast.error(
        'Ha ocurrido un error, si persiste contacte con el administrador'
      )

      return state

    case types.LOAD_PROJECT_LONG_TERM_SUCCESS:
      return {
        ...state,
        projectLongTerm: action.payload.projectlt,
      }
    case types.LOAD_PROJECT_LONG_TERM_ERROR:
      toast.error(
        'Ha ocurrido un error, si persiste contacte con el administrador'
      )

      return state

    case types.UPDATE_PROJECT_LONG_TERM_SUCCESS:
      toast.success('El proyecto ha sido actualizado.')

      return {
        ...state,
        projectLongTerm: action.payload.projectlt,
      }

    case types.UPDATE_PROJECT_LONG_TERM_ERROR:
      toast.error(
        'Ha ocurrido un error, si persiste contacte con el administrador'
      )

      return state

    case types.DELETE_PROJECT_LONG_TERM_REQUEST:
      toast.success('El proyecto ha sido eliminado.')

      return {
        ...state,
      }

    case types.DELETE_PROJECT_LONG_TERM_ERROR:
      toast.error(
        'Ha ocurrido un error, si persiste contacte con el administrador'
      )

      return state

    case types.PROJECT_LONG_DOC_RECLAMATION_SUCCESS:
      toast.success('Recordatorio enviado')

      return {
        ...state,
        projectLongTerm: action.payload.projectst,
      }

    case types.PROJECT_LONG_DOC_RECLAMATION_ERROR:
      toast.error(
        action.payload.response.message ||
          'Ha ocurrido un error enviando el recordatorio'
      )
      return { ...state }

    case types.REASSING_PROJECTLT_SUCCESS:
      toast.success('Encargo reasignado')
      return {
        ...state,
        projectLongTerm: action.payload.projectst,
      }

    case types.REASSING_PROJECTLT_ERROR:
      toast.error('Ha ocurrido un error al reasignar el encargo')
      return { ...state }

    case types.APPLY_PROJECT_LONG_TERM_SUCCESS:
      toast.success('Has aplicado para el proyecto.')

      return {
        ...state,
        projectLongTerm: action.payload.projectlt,
      }

    case types.APPLY_PROJECT_LONG_TERM_ERROR:
      toast.error('Ha ocurrido un error al aplicar para el proyecto')

      return state

    case types.ASSIGN_PROJECT_LONG_TERM_SUCCESS:
      toast.success('El proyecto ha sido asignado.')

      return {
        ...state,
        projectLongTerm: action.payload.projectlt,
      }

    case types.ASSIGN_PROJECT_LONG_TERM_ERROR:
      toast.error('Ha ocurrido un error al asignar el encargo')

      return state

    case types.CREATE_PROJECT_LONG_MEETING_SUCCESS:
      toast.success('La reunión ha sido creada.')

      return state

    case types.CREATE_PROJECT_LONG_MEETING_ERROR:
      toast.error('Error al crear la reunión')

      return state

    default:
      return state
  }
}
