import React, { Fragment, useEffect, useState } from 'react'
import { AvField } from 'availity-reactstrap-validation'
import { Col, Row, Card, CardBody, CardHeader} from 'reactstrap'
import { validateSpanishId } from 'spain-id'

const MultiString = ({ field, formDisabled, data = {} }) => {
  const { text, attributes, id } = field
  const [items, setItems] = useState(1)

  useEffect(() =>{
    if(data && data.internal_reference){
      const { id } = field
      const keys = Object.keys(data)

      const values = keys.filter(i => i.includes(`${id}-`))

      let items = 1
      for (let i = 0; i < values.length; i++) {
        const value = values[i];
        let item = value.split('-')[2]
        if((item * 1 + 1) > items) items = item * 1 + 1
      }
      setItems(items)
    }
  }, [data, field])

  return (
    <Col md='12'>
      <div className="form-group mt-2">
        <label className='mb-3'>
          <h4 className='d-flex'>{text}</h4>
          {
            !formDisabled && 
              <Fragment>
                <span className='text-secondary pointer' onClick={() => setItems(items + 1)}>+ Añadir</span>
                {
                  items > 1 &&
                    <span className='text-secondary pointer ml-3' onClick={() => setItems(items - 1)}> - Eliminar</span>  
                }
              </Fragment>
          }
        </label>

        {
          Array.from(Array(items), (e, i) => {
            return (
              <Card key={`${ field.item || 'Item' }${i}`}>
                <CardHeader className='bold'>
                  { field.item || 'Item' } { i + 1 }
                </CardHeader>
                <CardBody className='pt-1'>
                  <Row>
                  {
                    attributes && attributes.map(a => {
                      const idField = id + '-' + a.id + '-' + i
                      return (
                        <Col xs='12' sm='6' md='4' key={idField}>
                          <AvField
                            className='mb-2 d-flex'
                            name={idField}
                            required={a.required || false}
                            label={a.text}
                            disabled={formDisabled}
                            value={data[idField]}
                            type={a.type}
                            validate={idField.includes('nif_dni') ? { async: v => validateSpanishId(v) } : {}}
                          />
                        </Col>
                      )
                    })
                  }
                </Row> 
                </CardBody>
              </Card>
            )
          })
        }
      </div>
      <hr/>
    </Col>
  )
}

export default MultiString