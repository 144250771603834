import React from 'react';
import {
  Button,
  CardBody,
  FormGroup,
  Row
} from "reactstrap"
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'

const Name = ({ inputStyle, setUser, user, history, location }) => {
  if (localStorage.getItem('token')) {
    history.push('/panel/expedients')
  }

  const validateCheckout = async (e, errors, values) => {
    if (errors.length > 0) {
      return toast.error('Por favor rellena los campos correctamente')
    }

    if (!values.name) {
      return toast.error('Por favor introduce un nombre')
    }

    if (!values.last_name) {
      return toast.error('Por favor introduce un apellido')
    }

    setUser({ ...user, ...values })

    return history.push(`/create-user-form/contact${location.search}`)
  }

  return (
    <CardBody>
      <div className='d-flex justify-content-center align-items-center'>
        <h2 style={{ lineHeight: '1.5' }} className='col-md-6 text-center'>Regístrate gratis en Legal Pigeon. Dinos tu nombre y apellidos.</h2>
      </div>
      <AvForm onSubmit={validateCheckout}>
        <Row className='d-flex justify-content-center align-items-center'>
          <FormGroup className='col-md-3 d-md-flex justify-content-end m-md-4'>
            <AvField
              style={inputStyle}
              name='name'
              placeholder='Nombre'
              value={user.name}
            />
          </FormGroup>
          <FormGroup className='col-md-3 d-md-flex justify-content-start m-md-4 mt-n4'>
            <AvField
              style={inputStyle}
              name='last_name'
              placeholder='Apellidos'
              value={user.last_name}
            />
          </FormGroup>
        </Row>
        <div className='d-flex flex-md-row flex-column justify-content-md-center align-items-md-center'>
          <Button color="primary" size='lg'>Siguiente</Button>
        </div>
      </AvForm>
    </CardBody>
  )
}

export default withRouter(Name)