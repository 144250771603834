import React, { Fragment } from 'react'
import Dropzone from 'react-dropzone'
import { toast } from 'react-toastify'
import { baseAPI } from '../../config'
import Loading from '../common/Loading'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import action from '../../mixPanelsActions'
class UploadDocument extends React.Component {
  state = {
    uploadedFiles: this.props.defaultFiles || [],
    loading: false,
    documentType: 'expedients',
    document: null,
  }

  componentDidMount() {
    this.setState({
      documentType: this.props.documentType || 'expedients',
      uploadedFiles: this.props.defaultFiles || [],
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.defaultFiles !== prevProps.defaultFiles) {
      this.setState({
        documentType: this.props.documentType || 'expedients',
        uploadedFiles: this.props.defaultFiles || [],
      })
    }
  }

  onDrop = async (files) => {
    if (files.length === 0) {
      // console.log('no files')
      return
    }

    const { multiple } = this.props
    files = multiple ? files : [files[0]]

    this.setState({ loading: true })

    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      file['group'] = this.props.group
      await this.uploadFile(file, i)
      // console.log('file', i)
    }

    this.setState({ loading: false })
    this.props.update(this.state.uploadedFiles)
  }

  uploadFile = async (file, index) => {
    let { name, type, group } = file
    let document = null

    switch (this.state.documentType) {
      case 'expedients':
        document = this.props.expedient?.expedient
        this.setState({ document })
        break
      case 'pigeonProcura':
        document = this.props.procura?.procura
        this.setState({ document })
        break
      case 'pigeonOnCall':
        document = this.props.projectShortTerm?.projectShortTerm
        this.setState({ document })
        break
      case 'pigeonInHouse':
        document = this.props.projectLongTerm?.projectShortTerm
        this.setState({ document })
        break
      default:
        document = null
    }

    //Remove file ext. and replace special chars
    name = name.replace(/[#?*+~,()'"!:@]/g, '_')

    const file_details = {
      name,
      type,
      group,
      document_id: document?._id,
      documentType: this.state.documentType,
    }
    const signedData = await fetch(`${baseAPI}/signed-file`, {
      method: 'POST',
      body: JSON.stringify({ file_details }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (res) => await res.json())
      .catch((err) => ({ err }))

    if (signedData.err) {
      this.setState({ loading: false })
      return console.log('error', signedData.err)
    }

    const { returnData } = signedData.data
    const { url, signedRequest, key, bucket } = returnData

    // Put the fileType in the headers for the upload
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': type,
      },
      body: file,
    }

    return await fetch(signedRequest, options)
      .then((result) => {
        if (result.ok) {
          toast.success(`Archivo ${index + 1} guardado con éxito`)
          const newFile = {
            url,
            name,
            type,
            status: 'active',
            group,
            key,
            document_id: document?._id,
            bucket,
            uploaded_date: moment(),
            documentType: this.state.documentType,
          }

          this.setState({
            uploadedFiles: [...this.state.uploadedFiles, newFile],
          })

          return newFile
        } else {
          toast.error(
            'Hubo un problema en la carga, porfavor intentelo más tarde'
          )
          console.log('Response from s3', result)
          return this.setState({ loading: false })
        }
      })
      .catch((error) => {
        toast.error(
          'Hubo un problema en la carga, porfavor intentelo más tarde'
        )
        console.log('ERROR ' + error)
        return this.setState({ loading: false })
      })
  }

  downloadFile = async (file) => {
    fetch(`${baseAPI}/get-signed-file/`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(file),
    })
      .then(async (res) => await res.json())
      .then((res) => {
        if (!res) {
          return toast.error(
            'No se ha podido descargar el archivo, contacte con el administrador'
          )
        }
        const userAgent = window.navigator.userAgent.toLowerCase()
        const link = document.createElement('a')
        // Check for iphone/ipad devices
        if (!/iphone|ipad/i.test(userAgent)) {
          link.setAttribute('target', 'about:blank')
        }
        link.href = res.data.returnData.signedRequest
        link.download = res.data.returnData.file.name
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
      .catch((err) => {
        // console.log(err)
        return toast.error(
          'No se ha podido descargar el archivo, contacte con el administrador'
        )
      })
  }

  onDropRejected = () => {
    toast.warn('El archivo es demasiado grande (máx. 50Mb)')
    const { document } = this.state.document
    const { activeUser } = this.props.auth

    action.identify(activeUser._id)
    action.track('FileRejected', {
      document: document._id,
      documentType: this.state.documentType,
    })
  }

  render() {
    const { multiple, disabled, auth, deleteFile, allowDelete } = this.props
    const { uploadedFiles, loading, document } = this.state

    let filesToShow = uploadedFiles.filter((f) => f.status !== 'deleted')

    const permissionToDelete =
      (auth.activeUser.role === 'admin' ||
        document?.creator_id?._id === auth.activeUser?._id ||
        document?.company_id?._id === auth.activeUser?._id) &&
      allowDelete

    return (
      <Fragment>
        <Loading loading={loading} />
        {!disabled && (
          <Dropzone
            onDrop={this.onDrop}
            onDropRejected={this.onDropRejected}
            accept="image/jpeg, image/png, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain"
            minSize={0}
            maxSize={50485760}
            multiple={multiple || false}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className="dropzone">
                <input {...getInputProps()} />
                <span className="text-center">
                  Arrastra el archivo o click para seleccionar nuevo
                </span>
              </div>
            )}
          </Dropzone>
        )}

        {!filesToShow.length ? (
          <li
            key="sin"
            className="text-center list-group-item"
            style={{ padding: '1 1.25rem' }}
          >
            {' '}
            Sin archivos{' '}
          </li>
        ) : (
          uploadedFiles.map((uploadedFile, index) =>
            typeof uploadedFile === 'string' ? (
              <li
                key={`${index}-${uploadedFile}`}
                className="text-left list-group-item list-group-item-success p-0 pl-4"
              >
                <div className="d-flex justify-content-between ">
                  <a
                    href={uploadedFile}
                    className="mt-1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {uploadedFile.split('___')[1].replaceAll(/[_-]/g, ' ')}
                  </a>
                  {permissionToDelete && (
                    <span
                      className="btn pointer border-0"
                      style={{ color: 'red' }}
                      onClick={() => deleteFile(uploadedFile)}
                    >
                      {' '}
                      x{' '}
                    </span>
                  )}
                </div>
              </li>
            ) : (
              uploadedFile.status !== 'deleted' && (
                <li
                  key={`${index}-${uploadedFile}`}
                  className="text-left list-group-item list-group-item-success p-0 pl-4"
                >
                  <div className="d-flex justify-content-between ">
                    <button
                      onClick={() => this.downloadFile(uploadedFile)}
                      rel="noopener noreferrer"
                      type="button"
                      style={{
                        color: '#354052',
                        all: 'unset',
                        cursor: 'pointer',
                        marginTop: '2px',
                        marginBottom: '2px',
                      }}
                    >
                      {`${uploadedFile.name.replaceAll(/[_-]/g, ' ')}`}
                    </button>
                    {permissionToDelete && (
                      <span
                        className="btn pointer border-0"
                        style={{ color: 'red' }}
                        onClick={() => deleteFile(uploadedFile)}
                        role="button"
                      >
                        {' '}
                        x{' '}
                      </span>
                    )}
                  </div>
                </li>
              )
            )
          )
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  expedient: state.expedient,
  projectShortTerm: state.projectShortTerm,
  projectLongTerm: state.projectLongTerm,
  procura: state.procura,
})

export default withRouter(connect(mapStateToProps, {})(UploadDocument))
