import React, { useState } from 'react'
import {
  Button,
  CardBody,
  FormGroup,
  Row,
  Col
} from 'reactstrap'
import { AvForm } from 'availity-reactstrap-validation'
import { withRouter } from 'react-router-dom'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { restartFormIfMissingValues, validateCheckout } from './JudicialPartyHandlers'
import { selectProps } from './JudicialPartyChildrenprops'

const JudicialParty = ({ setUser, user, history }) => {
  restartFormIfMissingValues(history, user)
  const [selectedParties, setSelectedParties] = useState([])
  const animatedComponents = makeAnimated()

  return (
    <CardBody>
      <div className='d-flex justify-content-md-center align-items-md-center'>
        <h2 style={{ lineHeight: '1.5' }} className='col-md-6 text-center'>
          Indícanos los partidos judiciales en los que quieras ofrecer tus servicios.<br/>
          <span><br/><p style={{ fontSize: '14px' }}>Partidos judiciales (multiselección, máx. 5)</p></span>
        </h2>
      </div>
      <AvForm onSubmit={() => validateCheckout(history, user)}>
        <Row className='d-flex justify-content-center align-items-center'>
          <FormGroup className='col-md-3 d-md-flex justify-content-center m-md-4 mt-4 mb-n2'>
            <Col md='10'>
              <Select { ...selectProps(animatedComponents, setUser, setSelectedParties, user) } />
            </Col>
          </FormGroup>
        </Row>
        <div className='d-flex flex-md-row flex-column justify-content-md-center align-items-md-center'>
          <Button color='primary' size='lg' disabled={selectedParties.length === 0}>Siguiente</Button>
        </div>
      </AvForm>
    </CardBody>
  )
}

export default withRouter(JudicialParty)
