import React from 'react'
import { Card, CardBody, CardHeader, Row, Col, Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import ClientForm from '../components/forms/ClientForm'
import PageLayout from '../layouts/Page'
import { connect } from 'react-redux'
import ExpedientWizard from '../components/ExpedientWizard'
import Logo from '../components/common/Logo'
import LoginForm from '../components/forms/LoginForm'
import { toast } from 'react-toastify'
import { withRouter } from 'react-router'
import { createClient } from '../redux/actions/users'
import queryString from 'query-string'

class CreateClientExpedient extends React.Component {
  state = {
    showModal: false,
    searchParams: {}
  }

  componentDidMount(){
    const searchParams = queryString.parse(this.props.location.search)
    this.setState({searchParams})
  }

  componentDidUpdate(prevProps){
    if(this.props.auth.activeUser._id){
      toast.success('Bienvenido')
      this.props.history.push(`/create-expedient${this.props.location.search}`)
      const searchParams = queryString.parse(this.props.location.search)
      this.setState({searchParams})
    }
  }

  newUser = values => {
    const user = { ...values, role: 'client' }
    this.props.createClient({ user })
  }

  toggleModal = () => this.setState({showModal: !this.state.showModal})

  render() {
    const { showModal, searchParams } = this.state

    return (
      <PageLayout className='text-center' lightHeader>
        <Logo className='mt-4 mb-4 text-center' width='200px' height='auto' style={{
          padding: '2rem 0',
          margin: '0 auto'
        }}/>

        <Card className='pb-4'>
          <CardHeader>
            <ExpedientWizard step={2} activeUser={this.props.auth.activeUser} />
          </CardHeader>

          <CardBody>
            <Row className='mt-3 mb-4'>
              <Col sm={{ size: '4', offset: 1 }}>
                <h3 className='blue2 mb-4'>Ya soy cliente</h3>
                <LoginForm />
              </Col>
              <Col sm={{ size: '4', offset: 1 }}>
                <h3 className='blue2 mb-4'>Nuevo cliente</h3>
                <p>Quiero registrarme como nuevo cliente</p>
                <Button onClick={this.toggleModal}>Registro</Button>

                <Modal isOpen={showModal} toggle={this.toggleModal} className=''>
                  <ModalHeader toggle={this.toggleModal}><h2 className='mb-0'>Nuevo cliente</h2></ModalHeader>
                  <ModalBody>
                    <ClientForm onSubmit={this.newUser} showPassword showInvoice={false} isNew={true} searchParams={searchParams}/>
                  </ModalBody>
                </Modal>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </PageLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default withRouter(connect(mapStateToProps, { createClient })(CreateClientExpedient))
