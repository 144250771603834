import React, { useState } from 'react';
import {
  Button,
  CardBody,
  FormGroup,
  Row
} from "reactstrap";
import { withRouter } from 'react-router-dom'
import { createClientForm } from '../../redux/actions/users'
import { loginForm } from '../../redux/actions/auth'
import { connect } from 'react-redux'
import Loading from '../../components/common/Loading'
import { toast } from 'react-toastify'
import action from '../../mixPanelsActions'
import queryString from 'querystring'

const Choose = ({ setUser, user, history, createClientForm, loginForm, location }) => {
  const [loading, setLoading] = useState(false)
  if (
    !user.name ||
    !user.last_name ||
    !user.phone ||
    !user.email ||
    !user.password ||
    !user.checkbox
  ) {
    history.push(`/create-user-form/name${location.search}`)
  }

  const substituteElection = () => {
    setUser({ ...user, role: 'client', is_substitute: true, is_substitute_accepted: false, checkbox: true })
    history.push(`/create-user-form/judicial-party${location.search}`)
  }

  const clientElection = async () => {
    const source =  queryString.parse(location.search.replace('?', '')).source

    setUser({ ...user, role: 'client', source })
    setLoading(true)
    const res = await createClientForm({user: {...user, role: 'client', source }})
    if (!res || !res.payload.user) {
      toast.error('Este email ya esta asociado a un usuario, si es tu email haz login')
      history.push('/login')
    } else {
      const { _id, name, last_name, email, created_at } = res.payload.user
      action.identify(_id);
      action.people.set({
        $first_name: name,
        $last_name: last_name,
        $email: email,
        $created_at: created_at,
        $role: 'client',
        $is_substitute: false,
        $source: source || 'Organic'
      })
      action.track('ClientSignUp')

      await loginForm({ ...res.payload.user, password: user.password })
      history.push('/thanks-client')
    }
    setLoading(false)
  }

  return (
    <CardBody>
      <Loading loading={loading} />
      <div className='d-flex justify-content-center align-items-center'>
        <h2 style={{ lineHeight: '1.5' }} className='col-md-6 text-center'>{`${user.name}, ¿Qué buscas en Legal Pigeon?`}</h2>
      </div>
      <Row className='d-flex flex-column justify-content-center align-items-center'>
        <FormGroup className='d-md-flex m-md-4 mt-n4'>
          <Button color="success" size='lg' onClick={clientElection} >Contratar sustituciones</Button>
        </FormGroup>
        <FormGroup className='d-md-flex m-md-1 mt-n4'>
          <Button color="primary" size='lg' onClick={substituteElection}>Ser abogado colaborador</Button>
        </FormGroup>
      </Row>
    </CardBody>
  )
}

export default withRouter(connect(null, { createClientForm, loginForm })(Choose))