import * as types from '../constants'
import { toast } from 'react-toastify'

const defaultState = {
  created: {},
}

export default function (state = defaultState, action) {
  switch (action.type) {
    case types.CREATE_CAPABILITIES_SUCCESS:
      toast.success('Test finalizado con éxito')

      return {
        ...state,
        created: action.payload.expedient,
      }

    case types.CREATE_CAPABILITIES_ERROR:
      toast.error('Error al finalizar el test')
      // console.log(action.payload.response)
      return state

    default:
      return state
  }
}
