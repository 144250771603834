import * as types from '../constants'
import { toast } from 'react-toastify'

const defaultState = {
  expedients: {},
  created: {},
  expedient: {},
  assigned: {},
  drafts: {},
}

export default function (state = defaultState, action) {
  switch (action.type) {
    case types.LOAD_EXPEDIENTS_SUCCESS:
      return {
        ...state,
        expedients: action.payload,
      }

    case types.LOAD_EXPEDIENT_SUCCESS:
      return {
        ...state,
        expedient: action.payload.expedient,
      }

    case types.CREATE_EXPEDIENT_SUCCESS:
      toast.success('Encargo creado')

      return {
        ...state,
        created: action.payload.expedient,
      }

    case types.CREATE_SELF_EXPEDIENT_SUCCESS:
      toast.success('Encargo creado')
      return {
        ...state,
        created: action.payload.expedient,
      }

    case types.CREATE_SELF_EXPEDIENT_ERROR:
      toast.error(action.payload.response.message)
      return state

    case types.CREATE_EXPEDIENT_ERROR:
      if (action.payload.status === 503) {
        toast.error(
          'Ahora mismo no tenemos colaboradores disponibles. Contacte con el administrador.'
        )
      } else {
        toast.error(
          'Ha ocurrido un error, si persiste contacte con el administrador'
        )
      }
      return state

    case types.RESET_CREATED_EXPEDIENT:
      return {
        ...state,
        created: {},
      }

    case types.UPDATE_EXPEDIENT_SUCCESS:
      toast.success('Expediente actualizado')
      return {
        ...state,
        expedient: action.payload.expedient,
      }

    case types.UPDATE_EXPEDIENT_ERROR:
      let msg = 'Ha ocurrido un error actualizando la vista'
      if (action.payload.status === 401)
        msg = 'No tienes permisos para actualizar la vista'
      toast.error(msg)
      return {
        ...state,
      }

    case types.LOAD_ASSIGNED_EXPEDIENTS_SUCCESS:
      return {
        ...state,
        assigned: action.payload,
      }

    case types.UPDATE_REPORT_SUCCESS:
      toast.success('Informe guardado')
      return {
        ...state,
        expedient: action.payload.expedient,
      }

    case types.UPDATE_REPORT_ERROR:
      toast.error('Error guardando el informe')
      return {
        ...state,
      }

    case types.LOAD_DRAFTS_SUCCESS:
      return {
        ...state,
        drafts: action.payload,
      }

    case types.EXPEDIENT_DELETED_ERROR:
      msg = 'Ha ocurrido un error eliminando el encargo'
      if (action.payload.status === 401)
        msg = 'No tienes permisos para eliminar el encargo'
      toast.error(msg)

      return { ...state }

    case types.EXPEDIENT_DELETED_SUCCESS:
      toast.success('Expediente cancelado/eliminado correctamente')
      return {
        ...state,
      }

    case types.EXPEDIENT_DOC_REMINDER_SUCCESS:
      toast.success('Recordatorio enviado')

      return {
        ...state,
        expedient: action.payload.expedient,
      }

    case types.EXPEDIENT_DOC_REMINDER_ERROR:
      toast.error(
        action.payload.response.message ||
          'Ha ocurrido un error enviando el recordatorio'
      )
      return { ...state }

    case types.REASSING_EXPEDIENT_SUCCESS:
      toast.success('Encargo reasignado')
      return {
        ...state,
        expedient: action.payload.expedient,
      }

    case types.REASSING_EXPEDIENT_ERROR:
      toast.error('Ha ocurrido un error al reasignar el encargo')
      return { ...state }

    case types.EXPEDIENT_NOTIFICATION_SUCCESS:
      return { ...state }

    case types.EXPEDIENT_NOTIFICATION_ERROR:
      return { ...state }

    case types.EXPEDIENT_DELETE_FILE_SUCCESS:
      toast.success('Documento eliminado correctamente')
      return {
        ...state,
        expedient: action.payload.expedient,
      }

    case types.EXPEDIENT_DELETE_FILE_ERROR:
      toast.error('No se ha podido eliminar el documento')
      return { ...state }

    case types.URGENT_ISSUE_REMINDER_EXPEDIENT_SUCCESS:
      toast.success('Se han mandado los emails correctamente')
      return {
        ...state,
        expedient: {
          ...state.expedient,
          ...action.payload.document,
        },
      }
    case types.URGENT_ISSUE_REMINDER_EXPEDIENT_ERROR:
      toast.error('Ha ocurrido algun error')
      return { ...state }

    default:
      return state
  }
}
