import React, { useState, useEffect, Suspense, lazy } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Loading from '../components/common/Loading'
import Sidebar from '../components/common/Sidebar'
import Navbar from '../components/common/Navbar'
import Footer from '../components/common/Footer'
import Page404 from '../pages/auth/Page404'

// Component mapping (consider this like a manual registry of async components)
// prettier-ignore
const components = {
  Users: lazy(() => import('../pages/Users/Users')),
  UserPage: lazy(() => import('../pages/Users/UserPage')),
  ExpedientsPage: lazy(() => import('../pages/expedients/ExpedientsPage')),
  Expedient: lazy(() => import('../pages/expedients/Expedient')),
  AssignedExpedients: lazy(() => import('../pages/expedients/AssignedExpedients')),
  CompanyUsers: lazy(() => import('../pages/Users/CompanyUsers')),
  AdminDashboard: lazy(() => import('../pages/Dashboard/AdminDashboard/AdminDashboard')),
  ImportExpedients: lazy(() => import('../pages/expedients/ImportExpedients')),
  DraftsPage: lazy(() => import('../pages/expedients/DraftsPage')),
  InvoicesPage: lazy(() => import('../pages/Invoices/InvoicesPage')),
  Invoice: lazy(() => import('../pages/Invoices/Invoice')),
  Community: lazy(() => import('../pages/community/Community')),
  PromotionsPage: lazy(() => import('../pages/Promotions/PromotionsPage')),
  Promotion: lazy(() => import('../pages/Promotions/Promotion')),
  JudicialBriefs: lazy(() => import('../pages/JudicialBriefs/JudicialBriefs')),
  JudicialBriefPage: lazy(() => import('../pages/JudicialBriefs/JudicialBriefPage')),
  LandingAssigned: lazy(() => import('../pages/LandingAssigned')),
  RemittancesPage: lazy(() => import('../pages/remittance/RemittancesPage')),
  ImportRemittances: lazy(() => import('../pages/remittance/ImportRemittances')),
  ProfileImages: lazy(() => import('../pages/Users/ProfileImages')),
  CreateNewHearing: lazy(() => import('../pages/NewHearing/index')),
  CreateProjectShortTerm: lazy(() => import('../pages/ProjectShortTerm/CreateShortTermPage')),
  ProjectsShortTerm: lazy(() => import('../pages/ProjectShortTerm/ProjectsShortTermPage')),
  ProjectShortTerm: lazy(() => import('../pages/ProjectShortTerm/ProjectShortTermPage')),
  LandingAssignedProjectShortTerm: lazy(() => import('../pages/ProjectShortTerm/LandingAssignedProjectShortTerm')),
  AssignedProjectsST: lazy(() => import('../pages/ProjectShortTerm/AssignedProjectsST')),
  LandingAssignedPigeonInHouse: lazy(() => import('../pages/ProjectLongTerm/LandingAssignedProjectLongTerm')),
  DetailPigeonInHouse: lazy(() => import('../pages/ProjectLongTerm/ProjectLongTermPage')),
  ListPigeonInHouse: lazy(() => import('../pages/ProjectLongTerm/ProjectsLongTermPage')),
  NewPigeonInHouse: lazy(() => import('../pages/ProjectLongTerm/CreateLongTermPage')),
  Calculators: lazy(() => import('../pages/Calculators')),
  Dataset: lazy(() => import('../pages/Dataset/DatasetPage')),
  CapabilitiesTest: lazy(()=> import('../pages/capabilitiesTest/capabilitiesTest')),
  NewProcura: lazy(() => import('../pages/procura/NewProcura')),
  ListProcura: lazy(() => import('../pages/procura/ListProcura')),
  DetailProcura: lazy(() => import('../pages/procura/DetailProcura')),
  AssignedProcuras: lazy(() => import('../pages/procura/AssignedProcuras')),
  LandingAssignedProcura: lazy(() => import('../pages/procura/LandingAssignedProcura')),
  DraftsMainPage: lazy(() => import('../pages/import/DraftsMainPage')),
  ImportDocuments: lazy(() => import('../pages/import/ImportDocuments')),
}

// Define route objects
//TODO: Add judicial-brief when required
// prettier-ignore
const routes = {
  common: [
    { path: '/', exact: true, redirect: true, to: '/panel' }, // Common redirect for all users
    { path: '/panel/hearing', component: 'CreateNewHearing', exact: true }, //TODO: delete
    //TODO: { path: '/panel/new-expedient', component: 'NewExpedient', exact: true },
    { path: '/panel/expedients', component: 'ExpedientsPage', exact: true },
    { path: '/panel/expedients/:expedient_id', component: 'Expedient', exact: true },
    { path: '/panel/users/:user_id', component: 'UserPage', exact: true },
    { path: '/panel/project-short-term', component: 'CreateProjectShortTerm', exact: true }, //TODO: delete
    //TODO: { path: '/panel/new-pigeon-oncall', component: 'NewPigeonOnCall', exact: true },
    { path: '/panel/projects-short-term', component: 'ProjectsShortTerm', exact: true }, //TODO: delete
    //TODO: { path: '/panel/list-pigeon-oncall', component: 'ListPigeonOnCall', exact: true },
    { path: '/panel/projects-short-term/:project_id', component: 'ProjectShortTerm', exact: true }, //TODO: delete
    //TODO: { path: '/panel/list-pigeon-oncall', component: 'PigeonOnCall', exact: true },
    { path: '/panel/new-pigeon-inhouse/', component: 'NewPigeonInHouse', exact: true, },
    { path: '/panel/pigeon-inhouse/', component: 'ListPigeonInHouse', exact: true, },
    { path: '/panel/pigeon-inhouse/:project_id', component: 'DetailPigeonInHouse', exact: true, },
    { path: '/panel/new-procura', component: 'NewProcura', exact: true },
    { path: '/panel/procura', component: 'ListProcura', exact: true },
    { path: '/panel/procura/:procura_id', component: 'DetailProcura', exact: true },
    { path: '/panel/calculators', component: 'Calculators', exact: true },
    { path: '/community', component: 'Community', exact: true },
  ],
  admin: [
    { path: '/panel', exact: true, redirect: true, to: '/panel/dashboard' },
    { path: '/panel/dashboard', component: 'AdminDashboard', exact: true, },
    { path: '/panel/users', component: 'Users', exact: true },
    { path: '/panel/users/:user_id', component: 'UserPage', exact: true },
    { path: '/panel/profile_images', component: 'ProfileImages', exact: true },
    // { path: '/panel/expedients/drafts', component: 'DraftsPage' },
    // { path: '/panel/expedients/import', component: 'ImportExpedients' },
    { path: '/panel/invoices', component: 'InvoicesPage', exact: true },
    { path: '/panel/invoices/:invoice_id', component: 'Invoice', exact: true },
    { path: '/panel/promotions', component: 'PromotionsPage', exact: true },
    { path: '/panel/promotions/:promotion_id', component: 'Promotion', exact: true},
    { path: '/panel/dataset', component: 'Dataset', exact: true},
    { path: '/panel/judicial-briefs', component: 'JudicialBriefs', exact: true },
    { path: '/panel/judicial-briefs/:id', component: 'JudicialBriefPage', exact: true },
    { path: '/panel/remittances', component: 'RemittancesPage', exact: true },
    { path: '/panel/remittances/import', component: 'ImportRemittances', exact: true },
    { path: '/panel/drafts/:product_type?', component: 'DraftsMainPage' },
    { path: '/panel/import/:product_type', component: 'ImportDocuments' },
  ],
  client: [
    { path: '/panel', exact: true, redirect: true, to: '/panel/expedients' },
    { path: '/panel/invoices', component: 'InvoicesPage', exact: true },
    { path: '/panel/invoices/:invoice_id', component: 'Invoice', exact: true },
  ],
  substitute: [
    { path: '/panel', exact: true, redirect: true, to: '/panel/assigned-expedients' },
    { path: '/panel/assigned-expedients', component: 'AssignedExpedients', exact: true },
    { path: '/panel/assigned-projects-short-term', component: 'AssignedProjectsST', exact: true },
    { path: '/panel/capabilities', component: 'CapabilitiesTest', exact: true, },
    { path: '/panel/invoices', component: 'InvoicesPage', exact: true },
    { path: '/panel/invoices/:invoice_id', component: 'Invoice', exact: true, },
    { path: '/assigned-expedients/:expedient_id/:substitute_id', component: 'LandingAssigned', exact: true },
    { path: '/assigned-projects-short-term/:project_short_term_id/:substitute_id', component: 'LandingAssignedProjectShortTerm', exact: true, }, //TODO: delete
    //TODO: { path: '/assigned-pigeon-oncall/:project_id/:substitute_id', component: 'LandingAssignedPigeonOnCall', exact: true, },
    { path: '/assigned-pigeon-inhouse/:project_id/:substitute_id', component: 'LandingAssignedPigeonInHouse', exact: true, },
  ],
  procurador:[
    { path: '/panel', exact: true, redirect: true, to: '/panel/assigned-procuras' },
    { path: '/panel/assigned-procuras', component: 'AssignedProcuras', exact: true },
    { path: '/panel/invoices', component: 'InvoicesPage', exact: true },
    { path: '/panel/invoices/:invoice_id', component: 'Invoice', exact: true, },
    { path: '/assigned-procura/:procura_id/:substitute_id', component: 'LandingAssignedProcura', exact: true },
  ],
  substituteJudicialBrief: [
    // { path: '/panel/judicial-briefs', component: 'JudicialBriefs', exact: true, },
    // { path: '/panel/judicial-briefs/:id', component: 'JudicialBriefPage', exact: true, },
  ],
  company: [
    { path: '/panel', exact: true, redirect: true, to: '/panel/expedients' },
    { path: '/panel/users', component: 'Users', exact: true },
    { path: '/panel/invoices', component: 'InvoicesPage', exact: true },
    { path: '/panel/invoices/:invoice_id', component: 'Invoice', exact: true },
  ],
  partner: [
    { path: '/panel', exact: true, redirect: true, to: '/panel/expedients' },
  ],
}

// Component to handle route rendering and redirection
const RouteWithSubRoutes = (route) => {
  if (route.redirect) {
    return <Redirect exact={route.exact} from={route.path} to={route.to} />
  }

  const Component = components[route.component]
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => <Component {...props} />}
    />
  )
}

const Banner = () => {
  const [showBanner, setShowBanner] = useState(true)

  const updateWindowDimensions = () => {
    setShowBanner(window.innerWidth > 791)
  }

  useEffect(() => {
    updateWindowDimensions()
    window.addEventListener('resize', updateWindowDimensions)

    return () => {
      window.removeEventListener('resize', updateWindowDimensions)
    }
  }, [])

  return (
    <>
      {showBanner && (
        <div
          className="row p-1 m-0 bg-secondary text-white d-none d-md-flex justify-content-center align-items-center"
          style={{
            zIndex: '9999',
            position: 'relative',
            width: '100%',
            opacity: '1',
          }}
        >
          <span>
            Descubre Pigeon on Call: Tu solución definitiva para la redacción de
            documentos legales. Haz{' '}
            <a
              href="https://legalpigeon.com/externalizacion-documentos-legales/"
              target="_blank"
              rel="noopener noreferrer"
            >
              click aquí
            </a>{' '}
            para más información
          </span>
        </div>
      )}
    </>
  )
}

// Main LoginRoutes component
const LoginRoutes = ({ auth: { activeUser } }) => {
  const { role, is_substitute, is_procurador } = activeUser

  const userRole = is_substitute
    ? 'substitute'
    : is_procurador
    ? 'procurador'
    : role

  // Combine common routes with role-specific routes
  const combinedRoutes = [...(routes[userRole] || []), ...routes.common]

  return (
    <>
      <Banner />
      <div className="wrapper">
        <Sidebar />
        <div className="main">
          <Navbar />
          <Suspense fallback={<Loading />}>
            <div className="content" style={{ minHeight: '81%' }}>
              <Switch>
                {combinedRoutes.map((route, index) => (
                  <RouteWithSubRoutes key={index} {...route} />
                ))}
                <Route path="*" component={Page404} />
              </Switch>
            </div>
          </Suspense>
          <Footer />
        </div>
      </div>
    </>
  )
}

export default LoginRoutes
