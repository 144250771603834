import React, { useEffect, useState } from 'react'
import { AvInput } from 'availity-reactstrap-validation'
import { Col } from 'reactstrap'
import UploadDocument from '../forms/UploadDocument'
import { baseAPI } from '../../config'
import { toast } from 'react-toastify'

const UploadDocsForm = ({
  field,
  formDisabled,
  data = {},
  judicialBriefId,
}) => {
  const { text, id, required = false } = field
  const [files, setFiles] = useState([])

  const updateDocs = async (files) => {
    setFiles(files)

    if (data && data.internal_reference) {
      const updatedData = {}
      updatedData[id] = files

      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          data: {
            ...data,
            ...updatedData,
          },
        }),
      }

      fetch(`${baseAPI}/judicial-briefs/${judicialBriefId}`, options)
        .then((r) => r.json())
        .then((result) => {
          toast.success('Archivos actualizados')
        })
        .catch((error) => {
          toast.error(
            'Hubo un problema sincronizando los archivos, porfavor intentelo más tarde'
          )
          // console.log("ERROR " + JSON.stringify(error))
        })
    }
  }

  useEffect(() => {
    const f = data[field.id]
    setFiles(f)
  }, [data, field])

  return (
    <Col md="12">
      <div className="form-group mt-2">
        <label className="mb-3">
          <h4 className="d-flex">{text}</h4>
        </label>

        <AvInput
          className="mb-2 d-none"
          name={id}
          required={required}
          value={files || []}
        />

        <UploadDocument
          multiple
          update={updateDocs}
          disabled={formDisabled}
          defaultFiles={files}
        />
      </div>
    </Col>
  )
}

export default UploadDocsForm
