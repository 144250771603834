import { RSAA } from 'redux-api-middleware'
import { baseAPI } from '../../config'
import * as types from '../constants'
import queryString from 'querystring'

export function createProjectShortTerm(data) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
      endpoint: `${baseAPI}/projectst`,
      method: 'POST',
      types: [
        types.CREATE_PROJECT_SHORT_TERM_REQUEST,
        types.CREATE_PROJECT_SHORT_TERM_SUCCESS,
        types.CREATE_PROJECT_SHORT_TERM_ERROR,
      ],
    },
  }
}

export function loadProjectsShortTerm({ query }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/projectst?${queryString.stringify(query)}`,
      method: 'GET',
      types: [
        types.LOAD_PROJECTS_SHORT_TERM_REQUEST,
        types.LOAD_PROJECTS_SHORT_TERM_SUCCESS,
        types.LOAD_PROJECTS_SHORT_TERM_ERROR,
      ],
    },
  }
}

export function loadAssignedProjectsShortTerm({ query }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/projectsst-assigned?${queryString.stringify(
        query
      )}`,
      method: 'GET',
      types: [
        types.LOAD_ASSIGNED_PROJECTS_SHORT_TERM_REQUEST,
        types.LOAD_ASSIGNED_PROJECTS_SHORT_TERM_SUCCESS,
        types.LOAD_ASSIGNED_PROJECTS_SHORT_TERM_ERROR,
      ],
    },
  }
}

export function loadProjectShortTerm({ id }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/projectst/${id}`,
      method: 'GET',
      types: [
        types.LOAD_PROJECT_SHORT_TERM_REQUEST,
        types.LOAD_PROJECT_SHORT_TERM_SUCCESS,
        types.LOAD_PROJECT_SHORT_TERM_ERROR,
      ],
    },
  }
}

export function updateProjectShortTerm({ id, update }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ update }),
      endpoint: `${baseAPI}/projectst/${id}`,
      method: 'PUT',
      types: [
        types.UPDATE_PROJECT_SHORT_TERM_REQUEST,
        types.UPDATE_PROJECT_SHORT_TERM_SUCCESS,
        types.UPDATE_PROJECT_SHORT_TERM_ERROR,
      ],
    },
  }
}

export function deleteProjectShortTerm({ id }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/projectst/${id}`,
      method: 'DELETE',
      types: [
        types.DELETE_PROJECT_SHORT_TERM_REQUEST,
        types.DELETE_PROJECT_SHORT_TERM_SUCCESS,
        types.DELETE_PROJECT_SHORT_TERM_ERROR,
      ],
    },
  }
}

export function docClientProjectShortTermReclamation({ id }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/projectst/${id}/docreminder`,
      method: 'POST',
      types: [
        types.PROJECT_SHORT_DOC_RECLAMATION_REQUEST,
        types.PROJECT_SHORT_DOC_RECLAMATION_SUCCESS,
        types.PROJECT_SHORT_DOC_RECLAMATION_ERROR,
      ],
    },
  }
}

export function reassingProjectST({ projectst_id, substitute_id }) {
  const token = localStorage.getItem('token')

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/projectst/${projectst_id}/reassign`,
      method: 'PUT',
      body: JSON.stringify({ substitute_id }),
      types: [
        types.REASSING_PROJECTST_REQUEST,
        types.REASSING_PROJECTST_SUCCESS,
        types.REASSING_PROJECTST_ERROR,
      ],
    },
  }
}

export function loadOnCallDrafts({ query }) {
  const token = localStorage.getItem('token')
  query.status = 'draft'

  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/projectst?${queryString.stringify(query)}`,
      method: 'GET',
      types: [
        types.LOAD_PROJECTST_DRAFTS_REQUEST,
        types.LOAD_PROJECTST_DRAFTS_SUCCESS,
        types.LOAD_PROJECTST_DRAFTS_ERROR,
      ],
    },
  }
}

export function deleteOnCallFile({ type, file, projectst_id }) {
  const token = localStorage.getItem('token')
  return {
    [RSAA]: {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      endpoint: `${baseAPI}/projectst/${projectst_id}/deleteFile`,
      method: 'PUT',
      body: JSON.stringify({ type, file }),
      types: [
        types.PROJECT_SHORT_TERM_DELETE_FILE_REQUEST,
        types.PROJECT_SHORT_TERM_DELETE_FILE_SUCCESS,
        types.PROJECT_SHORT_TERM_DELETE_FILE_ERROR,
      ],
    },
  }
}
