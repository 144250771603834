import { toast } from 'react-toastify'

/**
 * Return at the start of the form if there are missing inputs on
 * user form.
 * @param {any} history react-router history
 * @param {object} user object containing user form input values
 */
export const restartFormIfMissingValues = (history, user) => {
  if (
    !user.name ||
    !user.last_name ||
    !user.phone ||
    !user.email ||
    !user.password ||
    !user.role
  ) history.push('/create-user-form/name')
}

/**
 * Handler to update group of judicial parties from tag input form
 * of "react-select" with a limit of 5 values. (button disbled if > 5)
 * @returns {void} Updates 2 states: "user" and "inputTags".
 */
export const onChangeJudicialParty = async (e, setUser, setJudicialParties, user) => {
  const judicialParties = e ? e.map(tag => tag.value) : null
  setJudicialParties(judicialParties)
  setUser({ ...user, substitute_judicial_parties: judicialParties })
}

/**
 * @param {any} history react-router history https://reactrouter.com/web/api/history
 * @param {object} user object containing user form input values.
 * @returns 
 */
export const validateCheckout = async (history, user) => {
  if (!user.substitute_judicial_parties || user.substitute_judicial_parties === 'Seleccionar partido judicial') {
    return toast.error('Por favor selecciona el partido judicial')
  }

  const maxJudicialParties = 5

  if (user.substitute_judicial_parties.length > maxJudicialParties) {
    return toast.error('Ha sobrepasado el máximo de especialidades')
  }

  history.push('/create-user-form/court-number')
}
