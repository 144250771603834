import React, { useState, useEffect } from 'react'
import PageLayout from '../../layouts/Page'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Card, CardBody, Col, Row, Container, Tooltip } from 'reactstrap'
import LoginForm from '../../components/forms/LoginForm'
import GoogleButton from 'react-google-button'
import { AWS_REDIRECT_URI_LOGIN, ENABLE_SOCIAL_LOGIN } from '../../config'
import { toast } from 'react-toastify'
import { loginIDP } from '../../redux/actions/auth'
import { googleLogin } from '../../services/socialLogin'
import pigeonsLetters from '../../assets/images/37.png'
import queryString from 'querystring'

const LogIn = () => {
  const [query, setQuery] = useState({})
  const [showTooltip, setShowTooltip] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)

  useEffect(() => {
    if (auth.activeUser._id) {
      history.push('/panel')
    }
  }, [auth, history])

  useEffect(() => {
    const query = queryString.parse(location.search.replace('?', ''))
    setQuery(query)

    if (query.state && query.code && !query.error) {
      const code_verifier = sessionStorage.getItem(
        `code_verifier-${query.state}`
      )
      sessionStorage.removeItem(`code_verifier-${query.state}`)
      if (code_verifier === null) {
        throw new Error('Unexpected code')
      }
      dispatch(
        loginIDP({
          code: query.code,
          code_verifier,
          redirect_uri: AWS_REDIRECT_URI_LOGIN,
        })
      )
      history.push('/panel')
    }

    if (query.error) {
      if (query.error_description?.startsWith('Already found')) {
        history.push('/login?repeat=true')
      } else {
        toast.error('Ha ocurrido un error, contacte con el administrador')
        history.push('/login')
      }
    }
  }, [location.search, history, dispatch])

  useEffect(() => {
    const query = queryString.parse(location.search.replace('?', ''))
    if (query.repeat && !query.error) {
      googleLogin()
    }
  }, [location.search])

  const showAwsCognitoInfo =
    auth.activeUser &&
    auth.activeUser.aws_sub === 'PWDNotReset' &&
    query &&
    query.security !== 'PWDResetDone'

  const showGoogle = ENABLE_SOCIAL_LOGIN

  return (
    <PageLayout className="text-center">
      <Container fluid className="vh-100 d-flex">
        <Row className="w-100">
          <Col
            md={6}
            className="d-flex flex-column align-items-center justify-content-center p-4"
          >
            <div className="text-center mt-4">
              <h1 className="h2">
                Accede de nuevo a Legal Pigeon{' '}
                <span role="img" aria-label="globe">
                  🌏
                </span>
              </h1>
              <p className="lead">
                Inicia sesión y continúa mejorando la gestión de tus casos
                legales.
              </p>
            </div>
            <Card
              className="w-100"
              style={{ maxWidth: '500px', margin: 'auto' }}
            >
              <CardBody>
                {showAwsCognitoInfo && (
                  <div
                    className="m-sm-4 p-2 text-center my-2"
                    style={{ border: '1px solid #42A4FF', fontSize: '1.1em' }}
                  >
                    <p className="mb-2">
                      Estamos{' '}
                      <b className="blue">comprometidos con tu seguridad</b>,
                      por ello hemos hecho{' '}
                      <b className="blue">
                        mejoras en la seguridad de tu cuenta y la aplicación
                      </b>
                      :
                    </p>
                    <ul>
                      <p className="m-0">
                        - Nuevo sistema de gestión de usuarios más robusto y
                        descentralizado.
                      </p>
                      <p className="m-0">
                        - Mejora en la validación de contraseñas.
                      </p>
                      <p className="m-0">
                        <div className="p-0 pointer">
                          - Conformidad con <b>certificaciones ISO/IEC</b>
                          <span className="small blue2" id="TooltipISO">
                            {' '}
                            Saber más
                          </span>
                          <Tooltip
                            placement="top"
                            isOpen={showTooltip}
                            target="TooltipISO"
                            toggle={() => setShowTooltip(!showTooltip)}
                          >
                            27001:2013, 27017:2015, 27018:2019, 27701:2019,
                            22301:2019, 9001:2015 y CSA STAR CCM v3.0.1
                          </Tooltip>
                        </div>
                      </p>
                    </ul>
                    <p className="m-0">
                      Para poder activar las mejoras, necesitamos que resetes tu
                      contraseña para poder acceder.
                    </p>
                    <Link
                      to="/forgot-password?security=PWDNotReset"
                      className="btn btn-lg btn-outline-secondary my-3"
                    >
                      Resetear contraseña
                    </Link>
                    <p className="mb-2">
                      Muchas gracias por tu colaboración y disculpa las
                      molestias.
                    </p>
                  </div>
                )}
                {showGoogle && (
                  <>
                    <div className="d-flex justify-content-around mt-3">
                      <GoogleButton
                        type="light"
                        onClick={googleLogin}
                        label="Entrar con Google"
                      />
                    </div>
                    <div className="text-center bold mt-4 mb-4">
                      o rellena el formulario
                    </div>
                  </>
                )}
                <div className="m-sm-4">
                  <LoginForm showAwsCognitoInfo />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col
            md={6}
            className="d-none d-md-flex align-items-center justify-content-center p-0"
          >
            <img
              src={pigeonsLetters}
              alt="Legal Pigeon"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </Col>
        </Row>
      </Container>
    </PageLayout>
  )
}

export default LogIn
