import React, { Component } from 'react'
import AsyncSelect from 'react-select/async'
import { baseAPI } from '../../config'

export default class UserSearch extends Component {
  constructor() {
    super()
    this.state = { type: null }
  }

  static getDerivedStateFromProps(props, state) {
    state.userRole = props.type || null
    // state.company = props.company || null
    return state
  }

  loadUsers = async (inputValue) => {
    let url = `${baseAPI}/users/users-search?q=${inputValue}&userRole=${this.state.userRole}&company=${this.props.company}`

    const options = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }

    const promise = await fetch(url, options)
      .then((response) => {
        return response.json()
      })
      .then((res) => {
        return res.map((i) => ({
          label: `${i.name} ${i.last_name} - ${i.email}`,
          value: i._id,
          name: i.name,
          last_name: i.last_name,
          email: i.email,
          invoice_data: i.invoice_data,
          use_custom_fields: i.use_custom_fields,
          custom_fields: i.custom_fields,
          partner_parent: i.partner_parent,
          role: i.role,
          invoice_city: i.invoice_city,
        }))
      })

    return promise
  }

  render() {
    const {
      className,
      onSubmit,
      defaultValue = null,
      isDisabled = false,
      isMulti = false,
    } = this.props

    return (
      <div className={className}>
        <AsyncSelect
          key={defaultValue ? defaultValue.value : null}
          cacheOptions={true}
          defaultOptions
          onChange={onSubmit}
          defaultValue={defaultValue}
          loadOptions={this.loadUsers}
          isDisabled={isDisabled}
          isMulti={isMulti}
        />
      </div>
    )
  }
}
