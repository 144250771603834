import * as types from '../constants'
import { toast } from 'react-toastify'

const initialState = {
  userInvoiceAccounts: [],
}

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.LOAD_USER_INVOICE_ACCOUNT_SUCCESS:
      return {
        ...state,
        userInvoiceAccounts: actions.payload.user_invoice_accounts,
      }

    case types.LOAD_EXPEDIENT_CREATOR_INVOICE_ACCOUNT_SUCCESS:
      return {
        ...state,
        userInvoiceAccounts: actions.payload.user_invoice_accounts,
      }

    case types.LOAD_USER_INVOICE_ACCOUNT_ERROR:
    case types.LOAD_EXPEDIENT_CREATOR_INVOICE_ACCOUNT_ERROR:
      toast.error('Error al cargar la cuenta de facturación')
      return state

    case types.ADD_USER_INVOICE_ACCOUNT_SUCCESS:
      toast.success('Cuenta de facturación agregada')
      return {
        ...state,
        userInvoiceAccounts: [
          ...state.userInvoiceAccounts,
          actions.payload.user_invoice_account,
        ],
      }

    case types.ADD_USER_INVOICE_ACCOUNT_ERROR:
      toast.error('Error al agregar la cuenta de facturación')
      return state

    case types.UPDATE_USER_INVOICE_ACCOUNT_SUCCESS:
      toast.success('Cuenta de facturación actualizada')
      return {
        ...state,
        userInvoiceAccounts: state.userInvoiceAccounts.map(
          (userInvoiceAccount) => {
            if (
              userInvoiceAccount._id ===
              actions.payload.user_invoice_account._id
            ) {
              return actions.payload.user_invoice_account
            }
            return userInvoiceAccount
          }
        ),
      }

    case types.UPDATE_USER_INVOICE_ACCOUNT_ERROR:
      toast.error('Error al actualizar la cuenta de facturación')
      return state

    case types.DELETE_USER_INVOICE_ACCOUNT_SUCCESS:
      toast.success(
        'Cuenta desactivada, revise sus expedientes creados que no tengan asociada la cuenta desactivada',
        { autoClose: 15000 }
      )
      if (actions.payload.expedients) {
        toast.success(
          `Tiene ${actions.payload.expedients} expediente/s asociado/s a esta cuenta`,
          { autoClose: 15000 }
        )
      }
      return {
        ...state,
        userInvoiceAccounts: state.userInvoiceAccounts.filter(
          (userInvoiceAccount) =>
            userInvoiceAccount._id !== actions.payload.user_invoice_account._id
        ),
      }

    case types.DELETE_USER_INVOICE_ACCOUNT_ERROR:
      toast.error(
        'No se ha podido desactivar, cuando tengas más de una cuenta de facturación activa la podrás desactivar',
        { autoClose: 15000 }
      )
      return state

    case types.RESET_SEPA_AUTH_URL_SUCCESS:
      return {
        ...state,
        userInvoiceAccounts: state.userInvoiceAccounts.map(
          (userInvoiceAccount) => {
            if (
              userInvoiceAccount._id ===
              actions.payload.user_invoice_account._id
            ) {
              return actions.payload.user_invoice_account
            }
            return userInvoiceAccount
          }
        ),
      }

    case types.RESET_SEPA_AUTH_URL_ERROR:
      return { ...state }

    case types.LOAD_PROJECT_SHORT_TERM_CREATOR_INVOICE_ACCOUNT_SUCCESS:
      return {
        ...state,
        userInvoiceAccounts: actions.payload.user_invoice_accounts,
      }

    case types.LOAD_PROJECT_SHORT_TERM_CREATOR_INVOICE_ACCOUNT_ERROR:
      toast.error('Error al cargar la cuenta de facturación')
      return state

    case types.LOAD_PROJECT_LONG_TERM_CREATOR_INVOICE_ACCOUNT_SUCCESS:
      return {
        ...state,
        userInvoiceAccounts: actions.payload.user_invoice_accounts,
      }

    case types.LOAD_PROJECT_LONG_TERM_CREATOR_INVOICE_ACCOUNT_ERROR:
      toast.error('Error al cargar la cuenta de facturación')
      return state

    default:
      return state
  }
}
