import React, { useState } from 'react'
import { Card, CardBody, CardHeader, Col, Row, Progress} from "reactstrap"
import { Check } from 'react-feather'
import { withRouter } from 'react-router-dom'

const Charging = ({ expedient, history, user, location }) => {
  const [request, setRequest] = useState(0)

  if (!expedient.judicial_party ||
    expedient.hearing_type < 0 ||
    !expedient.hearing_date ||
    !expedient.dificulty ||
    !expedient.court ||
    !expedient.resume ||
    !user.name ||
    !user.last_name
  ) {
    history.push(`/create-expedient-form/name${location.search}`)
  }

  setTimeout(() => {
    if (request >= 500) return history.push(`/create-expedient-form/paying-info${location.search}`)
    setRequest(request + Math.random(10, 4))
  }, 10)

  return (
    <CardBody >
      <Row className='d-flex justify-content-center align-items-center'>
        <Col md="6" >
          <Card className="flex-fill pt-4">
            <CardHeader className="d-flex">
              <h5 className="card-title mb-0">Analizando solicitud</h5>
              {request > 150 && <Check className='ml-auto text-success'/>}
            </CardHeader>
            <CardBody className="my-2">
              <Progress
                color="primary"
                value={request}
                className="progress-sm shadow-sm mb-1"
              />
            </CardBody>
            <CardHeader className="d-flex">
              <h5 className="card-title mb-0">{`Abogados disponibles en ${expedient.judicialPartyValue}`}</h5>
              {request > 250 && <Check className='ml-auto text-success'/>}
            </CardHeader>
            <CardBody className="my-2">
              <Progress
                color="primary"
                value={request - 100}
                className="progress-sm shadow-sm mb-1"
              />
            </CardBody>
            <CardHeader className="d-flex">
              <h5 className="card-title mb-0">Creando solicitud</h5>
              {request > 350 && <Check className='ml-auto text-success'/>}
            </CardHeader>
            <CardBody className="my-2">
              <Progress
                color="primary"
                value={request - 200}
                className="progress-sm shadow-sm mb-1"
              />
            </CardBody>
            <CardHeader className="d-flex">
              <h5 className="card-title mb-0">Reservando abogado</h5>
              {request > 450 && <Check className='ml-auto text-success'/>}
            </CardHeader>
            <CardBody className="my-2">
              <Progress
                color="primary"
                value={request - 300}
                className="progress-sm shadow-sm mb-1"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </CardBody>
  )
}

export default withRouter(Charging)