import React from 'react'
import { Button, CardBody } from 'reactstrap'

const ThanksClient = ({ history }) => {
  setTimeout(() => {
    if (!localStorage.getItem('token')) {
      history.push('/create-user-form/name')
    }
  }, 2000)

  const linkTo = '/panel/expedients'

  return (
    <CardBody>
      <div className="mt-5 mb-3 d-flex justify-content-center align-items-center">
        <h2 style={{ lineHeight: '1.5' }} className="col-md-6 text-center">
          Gracias por registrarte. Ahora ya puedes solicitar sustituciones en
          los 431 partidos judiciales.
        </h2>
      </div>
      <div className="d-flex flex-md-row flex-column justify-content-md-center align-items-md-center">
        <Button onClick={() => history.push(linkTo)} color="primary" size="lg">
          Ir a la aplicación
        </Button>
      </div>
    </CardBody>
  )
}

export default ThanksClient
