import * as types from '../constants'

const initialState = {}

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.LOAD_DASHBOARD_SUCCESS:
      return {
        ...state,
        ...actions.payload,
      }
    default:
      return state
  }
}
