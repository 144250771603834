import React from 'react';
import {
  Button,
  CardBody,
  FormGroup,
  Row,
} from "reactstrap"
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'


const CollegiateNumber = ({ inputStyle, setUser, history, user, location }) => {
  if (
    !user.name ||
    !user.last_name ||
    !user.phone ||
    !user.email ||
    !user.password ||
    !user.role ||
    !user.substitute_judicial_parties
  ) {
    history.push(`/create-user-form/name${location.search}`)
  }

  const validate = async (e, errors, values) => {
    if (errors.length > 0) {
      return toast.error('Por favor rellena los campos correctamente')
    }
    if (!values.colegiado) {
      return toast.error('Por favor introduce una referencia interna')
    }
    setUser({ ...user, ...values })

    return history.push(`/create-user-form/skills${location.search}`)
  }

  return (
    <CardBody>
      <div className='d-flex justify-content-center align-items-center'>
        <h2 style={{ lineHeight: '1.5' }} className='col-md-6 text-center'>{`${user.name}, ¿cúal es tu número de colegiado?`}</h2>
      </div>

      <AvForm onSubmit={validate}>
        <Row className='d-flex justify-content-center align-items-center'>
          <FormGroup className='col-md-3 d-md-flex justify-content-center m-md-4 mt-n4'>
            <AvField
              style={inputStyle}
              name='colegiado'
              placeholder='Colegiado'
              value={user.colegiado}
            />
          </FormGroup>
        </Row>
        <div className='d-flex flex-md-row flex-column justify-content-md-center align-items-md-center'>
          <Button color="primary" size='lg'>Siguiente</Button>
        </div>
      </AvForm>
    </CardBody>
  )
}

export default withRouter(CollegiateNumber)