import React from 'react'
import { Button, CardBody } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

const ThanksSubstitute = ({ history, auth }) => {
  if (!localStorage.getItem('token')) {
    history.push('/create-user-form/name')
  }

  return (
    <CardBody>
      <div className="mt-5 mb-3 d-flex justify-content-center align-items-center flex-column">
        <h2 style={{ lineHeight: '1.5' }} className="col-md-6 text-center">
          Gracias por registrarte. Nuestro equipo validará tu solicitud.
        </h2>
        <h2 style={{ lineHeight: '1.5' }} className="col-md-6 text-center">
          Con tu usuario también podrás solicitar una sustitución cuando lo
          necesites.
        </h2>
      </div>
      <div className="d-flex flex-md-row flex-column justify-content-md-center align-items-md-center">
        <Button
          onClick={() => {
            history.push(
              `${
                auth.activeUser.is_procurador
                  ? '/panel/assigned-procuras'
                  : '/panel/assigned-expedients'
              }`
            )
          }}
          color="primary"
          size="lg"
        >
          Ir a la aplicación
        </Button>
      </div>
    </CardBody>
  )
}
const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default withRouter(connect(mapStateToProps)(ThanksSubstitute))
