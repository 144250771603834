import * as types from '../constants'
import { toast } from 'react-toastify'

const defaultState = {
  promotions: {},
  promotion: {},
  created: {}
}

export default function (state = defaultState, action) {
  switch (action.type) {

    case types.GET_PROMOTIONS_SUCCESS:
      return {
        ...state,
        promotions: action.payload
      }
    
    case types.GET_PROMOTIONS_ERROR:
      toast.error('Ha ocurrido un error, cargando las promociones si persiste contacte con el administrador')
      return state

    case types.GET_PROMOTION_SUCCESS:
      return {
        ...state,
        promotion: action.payload
      }    
    
    case types.GET_PROMOTION_ERROR:
      toast.error('Ha ocurrido un error, cargando la promocion si persiste contacte con el administrador')
      return state

    case types.CREATE_PROMOTION_SUCCESS:
      toast.success('Promoción creada 😀')
  
      return {
        ...state,
        created: action.payload
      }

    case types.CREATE_PROMOTION_ERROR:
      toast.error(action.payload.response.message)
      return state
    
    case types.UPDATE_PROMOTION_SUCCESS:
      toast.success('Promoción actualizada 😀')
      return {
        ...state,
        promotion: action.payload.promotion
      }

    case types.UPDATE_PROMOTION_ERROR:
      toast.error('Ha ocurrido un error, actualizando la promocion si persiste contacte con el administrador')
      return {
        ...state,
        promotion: action.payload.promotion
      }

    case types.VALIDATE_PROMOTION_SUCCESS:
      action.payload.err
        ? toast.error('Promo invalida') 
        : toast.success('Promoción validada')
      return state

    case types.VALIDATE_PROMOTION_ERROR:
      toast.error('Ha ocurrido un error, validando la promoción si persiste contacte con el administrador')
      return state

    default:
      return state
  }
}