import React, { useState, useEffect, Fragment } from 'react'
import { baseAPI, STRIPE_KEY } from '../../config'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { createExpedient } from '../../redux/actions/expedient'
import { StripeProvider, Elements } from 'react-stripe-elements'
import CardForm from '../../components/CardForm'
import PaymentSummary from '../../components/PaymentSummary'
import Loading from '../../components/common/Loading'
import { Row, Col, CardBody } from 'reactstrap'
import action from '../../mixPanelsActions'
import moment from 'moment'
import { gtmEvent } from '../../services/gtm-handler'
import { isHearingUrgent } from '../../services'

const PayingProcess = ({ user, createExpedient, expedient, history }) => {
  const [loading, setLoading] = useState(false)
  const [checkoutIntent, setCheckoutIntent] = useState({})
  const defaultPromoState = {
    showSubmitPromotionButton: false,
    name: '',
    promotionValidation: null,
    active: false,
    percentatge: false,
    value: false,
  }
  const [promotion, setPromotion] = useState(defaultPromoState)

  if (
    !expedient.judicial_party ||
    expedient.hearing_type < 0 ||
    !expedient.hearing_date ||
    !expedient.dificulty ||
    !expedient.court ||
    !expedient.resume ||
    !user.name ||
    !user.last_name
  ) {
    history.push('/create-expedient-form/name')
  }

  useEffect(() => {
    const getToken = async () => {
      await checkout()
    }
    getToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkout = async () => {
    const res = await fetch(`${baseAPI}/checkout-token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        user_id: user._id,
        isUrgentHearing: expedient.is_urgent_hearing,
      }),
    })
      .then(async (res) => {
        if (res.status === 200) {
          setLoading(false)
          action.identify(user._id)
          action.track('HearingShoppingCart', {
            first_name: expedient.name,
            last_name: expedient.last_name,
            email: expedient.email,
            ...expedient,
            hearing_date: moment(expedient.hearing_date).format(
              '[Dia:] DD/MM/YYYY [Hora:] hh:mm A'
            ),
            hearing_type: expedient.hearingTypeValue,
            judicial_party: expedient.judicialPartyValue,
          })
          return await res.json()
        }
      })
      .catch((err) => {
        // console.log('error', err)
        setLoading(false)
        return false
      })

    setCheckoutIntent(res.token)
  }

  return (
    <Fragment>
      <Loading loading={loading} />
      <CardBody>
        <div className="d-flex justify-content-center align-items-center">
          <h2 style={{ lineHeight: '1.5' }} className="col-12 text-center mb-4">
            Gracias por tu confianza
          </h2>
        </div>
        <Row className="d-flex justify-content-center align-items-center">
          <Col md="4">
            <PaymentSummary
              basePrice={95 + expedient.extra_urgent_price}
              promotion={promotion}
              setPromotion={setPromotion}
              defaultPromoState={defaultPromoState}
              expedient={expedient}
            />
          </Col>
        </Row>

        <Loading loading={!checkoutIntent} />
        <div className="d-flex justify-content-center align-items-center m-0">
          <Row className="d-flex justify-content-center align-items-center m-0">
            <StripeProvider className="border" apiKey={STRIPE_KEY}>
              <Elements className="border" locale="es">
                <CardForm
                  className="border"
                  activeUser={user}
                  basePrice={95 + expedient.extra_urgent_price}
                  cards={[]}
                  checkoutIntent={checkoutIntent}
                  expedient={expedient}
                  setShowModal={() => {
                    // console.log('setShowModal')
                  }}
                  setStep={() => {
                    // console.log('setStep')
                  }}
                  createExpedient={async (exp) => {
                    exp.user_invoice_account_id = 'account_not_set'
                    exp.is_urgent_hearing = isHearingUrgent(exp.hearing_date)
                    await createExpedient({ expedient: exp }).then((res) => {
                      // action.identify(user._id);
                      // action.track('CreateNewHearing', {
                      //   email: expedient.email,
                      //   ...expedient,
                      //   hearing_date: moment(expedient.hearing_date).format('[Dia:] DD/MM/YYYY [Hora:] hh:mm A'),
                      //   hearing_type: expedient.hearingTypeValue,
                      //   judicial_party: expedient.judicialPartyValue
                      // })

                      const newExpedient = res.payload.expedient

                      gtmEvent({
                        event: 'purchase',
                        ecommerce: {
                          currencyCode: 'EUR',
                          purchase: {
                            actionField: {
                              id: newExpedient._id, // id de transacción
                              revenue: newExpedient.price, // Total
                              tax: newExpedient.price * 0.21,
                              creatorId: newExpedient.creator_id, // id del creador
                            },
                            products: newExpedient.is_urgent_hearing
                              ? [
                                  {
                                    id: 'VP-L',
                                    name: 'Vista prepagada form landing',
                                    price:
                                      newExpedient.price -
                                      newExpedient.extra_urgent_price,
                                    quantity: 1,
                                  },
                                  {
                                    id: 'VU',
                                    name: 'Vista urgente',
                                    price: newExpedient.extra_urgent_price,
                                    quantity: 1,
                                  },
                                ]
                              : [
                                  {
                                    id: 'VP-L',
                                    name: 'Vista prepagada form landing',
                                    price: newExpedient.price,
                                    quantity: 1,
                                  },
                                ],
                          },
                        },
                      })

                      gtmEvent({
                        event: 'purchase',
                        ecommerce: {
                          transaction_id: newExpedient._id,
                          value: newExpedient.price,
                          tax: newExpedient.price * 0.21,
                          currency: 'EUR',
                          creatorId: newExpedient.creator_id, // id del creador
                          items: newExpedient.is_urgent_hearing
                            ? [
                                {
                                  item_name: 'Vista prepagada form landing', // Name o ID requerido.
                                  item_id: 'VP-L',
                                  price:
                                    newExpedient.price -
                                    newExpedient.extra_urgent_price,
                                  currency: 'EUR',
                                  quantity: 1,
                                },
                                {
                                  item_name: 'Vista urgente', // Name o ID requerido.
                                  item_id: 'VU',
                                  price: newExpedient.extra_urgent_price,
                                  currency: 'EUR',
                                  quantity: 1,
                                },
                              ]
                            : [
                                {
                                  item_name: 'Vista prepagada form landing', // Name o ID requerido.
                                  item_id: 'VP-L',
                                  price: newExpedient.price,
                                  currency: 'EUR',
                                  quantity: 1,
                                },
                              ],
                        },
                      })
                    })
                    await fetch(
                      `${baseAPI}/reset/${user.email}?setPassword=true`
                    )
                    history.push('/create-expedient-form/last-step')
                  }}
                  closeModal={() => history.push('/panel/expedients')}
                  process={'payingProcess'}
                />
              </Elements>
            </StripeProvider>
          </Row>
        </div>
      </CardBody>
    </Fragment>
  )
}

export default withRouter(connect(null, { createExpedient })(PayingProcess))
