import * as types from '../constants'
import { toast } from 'react-toastify'

const initialState = {
  substituteReview: {},
  substituteReviews: [],
}

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.CREATE_SUBSTITUTE_REVIEW_SUCCESS:
    case types.UPDATE_SUBSTITUTE_REVIEW_SUCCESS:
      toast.success('Valoración guardada correctamente')
      return {
        ...state,
        substituteReview: {
          ...state.substituteReview,
          ...actions.payload.substituteReview,
        },
      }

    case types.CREATE_SUBSTITUTE_REVIEW_ERROR:
    case types.UPDATE_SUBSTITUTE_REVIEW_ERROR:
      toast.error('No se ha podido guardar la valoración')
      return state

    case types.GET_EXPEDIENT_REVIEW_SUCCESS:
      return {
        ...state,
        substituteReview: actions.payload.substituteReview,
      }

    case types.GET_EXPEDIENT_REVIEW_ERROR:
      return state

    case types.GET_SUBSTITUTE_REVIEWS_SUCCESS:
      return {
        ...state,
        substituteReviews: actions.payload,
      }

    case types.GET_SUBSTITUTE_REVIEWS_ERROR:
      toast.error('No se han podido descargar las valoraciones')
      return state

    default:
      return state
  }
}
