import React, { useState, useEffect } from 'react'
import judicialBriefForms from '../../services/judicialBriefForms'
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation'
import { Col, Label, Row} from 'reactstrap'
import { toast } from 'react-toastify'
import MultiString from './MultiString'
import UploadDocsForm from './UploadDocsForm'
import Loading from '../common/Loading'
import { connect } from 'react-redux'
import { createJudicialBrief, updateJudicialBrief } from '../../redux/actions/judicialBrief'
import UserSearch from '../search/UserSearch'
import { withRouter } from 'react-router-dom'
// import mixpanelaction from '../../mixPanelsActions'
import { gtmEvent } from '../../services/gtm-handler'

const FormBuilder = props => {
  const { 
    selectedTemplate, defaultText, history, createJudicialBrief, closeModal, 
    formDisabled = false, showHeader = true, brief = {}, auth, action, updateJudicialBrief
  } = props
  const { data = {}} = brief
  const { activeUser } = auth
  const [form, setForm] = useState({})
  const [disabledForm, setDisabledForm] = useState(false)
  const [loading, setLoading] = useState(false)
  const [creatorId, setCreatorId] = useState(null)

  useEffect(() => {
    const form = judicialBriefForms.find(f => f.id === selectedTemplate) || {}
    setForm(form)
  }, [selectedTemplate])

  useEffect(() => {
    setCreatorId(brief.creator_id)
  },[brief.creator_id])

  const submitForm = async (e, errors, data) => {
    if (errors.length > 0) {
      return toast.error(
        <div>Por favor rellena los campos correctamente: 
          { 
            errors.map(err => {
              const field = form.data.find(d => err === d.id)
              
              if(field && field.type === 'upload') {
                return <div key={`err-${field.id}`}>- {field.text}</div>
              }
              return null
            })
          }
        </div>
      )
    }

    setDisabledForm(true)
    setLoading(true)

    const newJudicialBrief = {
      template: selectedTemplate,
      creator_id: activeUser.role !== 'admin' ? activeUser._id : creatorId,
      data
    }

    if (action !== 'update') {
      await createJudicialBrief({ judicialBrief: newJudicialBrief })
      .then(res => {
        const judicialBrief = res.payload.judicialBrief
        // mixpanelaction.identify(judicialBrief._id);
        // mixpanelaction.track('CreateNewJudicialBrief', {
        //   ...judicialBrief,
        // })

        gtmEvent({
          'event': 'purchase',
          'ecommerce': {
            'currencyCode': 'EUR',
            'purchase': {
              'actionField': {
                'id': judicialBrief._id, // id de transacción
                'revenue': judicialBrief.price, // Total
                'tax': judicialBrief.price * 0.21, // Impuestos, si aplica
                'creatorId': judicialBrief.creator_id
              },
              'products': 
                  [{
                    'id': 'JB',
                    'name': 'Compra escrito judicial',
                    'price': judicialBrief.price,
                    'quantity': 1
                  }]
            }
          }
        })

        gtmEvent({
          'event': "purchase",
          'ecommerce': {
            'transaction_id': judicialBrief._id,
            'value': judicialBrief.price,
            'tax': judicialBrief.price * 0.21,
            'currency': "EUR",
            'creatorId': judicialBrief.creator_id, // id del creador
            'items':
              [{
                'item_name': "Compra escrito judicial", // Name o ID requerido.
                'item_id': "JB",
                'price': judicialBrief.price,
                'currency': 'EUR',
                'quantity': 1
              }]
          }
        })


        closeModal()
        setDisabledForm(false)
        setLoading(false)
        history.push('/panel/judicial-briefs')
      })
      .catch()
    }
    else{
      await updateJudicialBrief({ judicialBrief: newJudicialBrief, id: brief._id })
      .then(res => {
        // const judicialBrief = res.payload.judicialBrief
        // mixpanelaction.identify(judicialBrief._id);
        // mixpanelaction.track('UpdateJudicialBrief', {
        //   ...judicialBrief,
        // })

        setDisabledForm(false)
        setLoading(false)
      })
      .catch()
    }
  }

  if(selectedTemplate === defaultText) {
    return <div/>
    // return <div className='mt-4'>
    //   <p>Escritos judiciales revisados por abogados listos para presentar en el Juzgado en menos de 24 horas</p>
    //   <h4>Selecciona el tipo de escrito que quieres contratar</h4>
    //   </div>
  }

  return (
    <div className={showHeader ? 'mt-2' : ''}>
      <Loading loading={loading} />
      {
        showHeader &&
          <div>
            <h2>{form.text}</h2>
            <div>Precio: {form.price} €</div>
          </div>
      }
      <AvForm key={'judicialBriefForm'} onSubmit={submitForm} className={showHeader ? 'mt-4' : ''}>
        {
          activeUser && activeUser.role === 'admin' && !Object.keys(brief).length && !brief.creator_id &&
          <Row className='mb-3'>
            <Col xs='12' sm='6' md='4'>
              <Label>Creador del encargo</Label>
              <div className='mb-3'>
                <UserSearch onSubmit={(user) => setCreatorId(user ? user.value : null)} />
              </div>
            </Col>
            {
              creatorId &&
                <Col xs='12' sm='6' md='4' className='mt-2'>User ID: {creatorId}</Col>
            }
          </Row>
        }

        <Row>
          <Col xs='12' sm='6' md='4'>
            <div>
              <AvField
                name='internal_reference'
                required={true}
                label='Referencia interna'
                disabled={formDisabled}
                value={data.internal_reference}
              />
            </div>
          </Col>
          
          {
            form.data && form.data.map(d => (
              <InputBuilder 
                field={d}
                key={d.id}
                formDisabled={formDisabled}
                data={data}
                submitForm={submitForm}
                judicialBriefId={brief._id}
              /> 
            ))
          }
        </Row>

        {
          !activeUser.is_judicial_brief &&
            <div className='d-flex mt-3'>
              <button id='judicialBriefForm' className='btn btn-success btn-lg' disabled={disabledForm}>
                {
                  data && data.internal_reference 
                    ? 'Guardar encargo'
                    : 'Crear encargo'
                }
              </button>
            </div>
        }
      </AvForm>
    </div>
  )
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default withRouter(connect(mapStateToProps, { createJudicialBrief,updateJudicialBrief })(FormBuilder))


const InputBuilder = props => {
  const { field, formDisabled, data, submitForm, judicialBriefId } = props
  const { type, text, required = false, id, options } = field

  if(type === 'text'){
    return (
      <Col xs='12' sm='6' md='4'>
        <AvField
          name={id}
          required={required}
          label={text}
          disabled={formDisabled}
          value={data[id]}
          // errorMessage="Introduce tu nombre/denominación social"
        />
      </Col>
    )
  }

  if(type === 'number'){
    return (
      <Col xs='12' sm='6' md='4'>
        <AvField
          name={id}
          required={required}
          label={text}
          type='number'
          disabled={formDisabled}
          value={data[id]}
        />
      </Col>
    )
  }

  if(type === 'date'){
    return (
      <Col xs='12' sm='6' md='4'>
        <AvField
          name={id}
          required={required}
          label={text}
          type='date'
          disabled={formDisabled}
          value={data[id]}
        />
      </Col>
    )
  }

  if(type === 'textarea'){
    return (
      <Col md='12' className="form-group">
        <label>{text}</label>
        <AvInput 
          type="textarea" 
          name={id}
          required={required || false}
          rows='5'
          disabled={formDisabled}
          value={data[id]}
          //placeholder={text}
        />
      </Col>
    )
  }

  if(type === 'checkbox'){
    return (
      <Col xs='12' sm='6' md='4'>
        <AvInput 
          type="checkbox" 
          name={id}
          disabled={formDisabled}
          className='ml-1'
          checked={!!data[id]}
        />
        <label className='ml-4'>{text}</label>
      </Col>
    )
  }

  if(type === 'select'){
    return (
      <Col xs='12' sm='6' md='4'>
        <label>{text}</label>
        <AvInput 
          type="select"
          name={id}
          disabled={formDisabled}
          value={data[id]}
        >
          {
            !!options.length && options.map(option =>(
              <option value={option} key={option}>{option}</option>
            ))
          }
        </AvInput>
      </Col>
    )
  }

  if(type === 'upload'){
    return (
      <UploadDocsForm
        field={field}
        formDisabled={formDisabled}
        data={data}
        submitForm={submitForm}
        judicialBriefId={judicialBriefId}
      />
    )
  }

  if(type === 'array'){
    return (
      <MultiString
        field={field}
        formDisabled={formDisabled}
        data={data}
      />
    )
  }
  
  /*return (
    <Col md='6'>{field.type}</Col>
  )*/
}