import React, { useState } from 'react';
import { Collapse, Navbar, Nav, UncontrolledDropdown } from "reactstrap";
import { RotateCcw } from 'react-feather'
import logo from '../../assets/images/logo.png'
import { useParams } from "react-router-dom";
import JudicialParty from './JudicialParty'
import Name from './Name'
import Contact from './Contact'
import Password from './Password';
import Choose from './Choose';
import CollegiateNumber from './CollegiateNumber';
import Skills from './Skills'
import { withRouter } from 'react-router-dom'

const inputStyle = { height: '35px', marginTop: '30px' }

const CreateUserForm = () => {
  const { stepParam } = useParams();
  const [user, setUser] = useState({})

  const reset = () => {
    setUser({})
  }

  return (
    <div>
      <Navbar color="white" light expand>
        <Collapse className='mr-5' navbar>
          <Nav navbar>
            <UncontrolledDropdown nav inNavbar>
              <a href='https://legalpigeon.com/'>
                <img
                  src={logo}
                  alt="legaltechies logo"
                  className="img-fluid"
                  width="132"
                  height="132"
                />
              </a>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
        <div className="ml-auto d-flex align-items-end" >
          <Collapse className='mr-5' navbar>
            <Nav navbar>
              <UncontrolledDropdown
                style={{ cursor: 'pointer' }}
                onClick={reset}
                nav inNavbar
              >
                <RotateCcw size={18} />
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </div>
      </Navbar>

      <div className='d-flex justify-content-center align-items-center' style={{ minHeight: '60vh' }}>
        {stepParam === 'name' &&
          <Name
            inputStyle={inputStyle}
            setUser={setUser}
            user={user}
          />
        }

        {stepParam === 'contact' &&
          <Contact
            inputStyle={inputStyle}
            setUser={setUser}
            user={user}
          />
        }

        {stepParam === 'password' &&
          <Password
            inputStyle={inputStyle}
            setUser={setUser}
            user={user}
          />
        }

        {stepParam === 'choose' &&
          <Choose
            inputStyle={inputStyle}
            setUser={setUser}
            user={user}
          />
        }

        {stepParam === 'judicial-party' &&
          <JudicialParty
            inputStyle={inputStyle}
            setUser={setUser}
            user={user}
          />
        }

        {stepParam === 'court-number' &&
          <CollegiateNumber
            inputStyle={inputStyle}
            setUser={setUser}
            user={user}
          />
        }

        {stepParam === 'skills' &&
          <Skills
            inputStyle={inputStyle}
            setUser={setUser}
            user={user}
          />
        }
      </div>
    </div>
  )
}

export default withRouter(CreateUserForm)