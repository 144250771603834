import React from "react";
import { withRouter, Route, Switch, Redirect } from "react-router-dom";
import { connect } from 'react-redux'
import { getSession } from '../redux/actions/auth'
import ability from '../ability'
import { GTM, MAINTENANCE } from '../config'

import Loading from '../components/common/Loading'
import LogIn from "../pages/auth/LogIn";
import LoginRoutes from '../routes/loginRoutes'
import ScrollToTop from "../components/common/ScrollToTop"
import ClientSignUpPage from "../pages/ClientSignUpPage"
import SubstituteSignUpPage from "../pages/SubstituteSignUpPage"
import ForgotPassword from "../pages/auth/ForgotPassword"
import ResetPassword from '../pages/auth/ResetPassword'
import Landing from "../pages/Landing"
import CreateClientExpedient from '../pages/CreateClientExpedient'
import CreateExpedient from "../pages/CreateExpedient"
import CreateExpedientForm from "../pages/createExpedientForm/CreateExpedientForm";
import CreateUserForm from "../pages/createUserForm/CreateUserForm";
import ThanksClient  from "../pages/createUserForm/ThanksClient";
import ThanksSubstitute  from "../pages/createUserForm/ThanksSubstitute"
import FreshdeskChat from "../components/common/FreshdeskChat";
import Maintenance from "../pages/Maintenance"

class Routes extends React.Component {
  state = {
    token: localStorage.getItem('token'),
    isAuth: false,
    loading: true
  }

  componentDidMount() {
    const { auth, getSession } = this.props
    const { token } = this.state

    if(auth.activeUser._id){
      this.setState({isAuth: true, loading: false})
    } else if(token){
      getSession({token})
    } else {
      this.setState({loading: false})
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { activeUser } = props.auth
    if(activeUser._id) {
      if(GTM) {
        window.dataLayer.push({
          event: 'session',
          userId: activeUser._id,
          role: activeUser.role
        })
      }
      
      ability.update(activeUser.rules)

      // setup freshdesk
      FreshdeskChat({ activeUser })

      if(props.location.pathname === '/login'){
        props.history.push('/')
      }
      return { isAuth: true, loading: false }
    } else {
      ability.update([])
      return { ...state, isAuth: false }
    }
  }

  render() {
    const { isAuth, loading } = this.state

    return (
      <ScrollToTop>
        <Switch>
          <Route exact path='/substitute-list' component={Landing} />
          <Route path='/create-client-expedient' component={CreateClientExpedient} />
          <Route path='/create-expedient' component={CreateExpedient} />
          <Route exact path='/client-signup' component={ClientSignUpPage} />
          <Route exact path='/substitute-signup' component={SubstituteSignUpPage} />
          <Route exact path='/forgot-password/:pwdReset?' component={ForgotPassword} />
          <Route path='/reset-password/:token/:pwdReset?' component={ResetPassword} />
          <Route path='/thanks-client' component={ThanksClient} />
          <Route path='/thanks-substitute' component={ThanksSubstitute} />

          <Route path='/create-expedient-form/:stepParam/' component={CreateExpedientForm} />
          <Route path='/create-user-form/:stepParam/' component={CreateUserForm} />

          <Redirect exact path='/create-user-form' to={`/create-expedient-form/name${this.props.location.search}`} />
          <Redirect exact path='/create-expedient-form' to={`/create-expedient-form/judicial-party${this.props.location.search}`} />
          
          {
            MAINTENANCE
              ? <Maintenance />
              :
                loading
                  ? <Loading loading={true} />
                  : isAuth
                    ? <LoginRoutes {...this.props} />
                    : <LogIn />
          }
        </Switch>
      </ScrollToTop>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default withRouter(connect(mapStateToProps, { getSession })(Routes))
